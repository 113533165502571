.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: var(--color-nightshade) !important;
  }

  .mat-slide-toggle-thumb-container {
    transform: translate3d(34px, 0, 0) !important;

    .mat-slide-toggle-thumb {
      background-color: var(--color-pure-white) !important;
    }

    .mat-slide-toggle-ripple {
      .mat-slide-toggle-persistent-ripple {
        background-color: var(--color-nightshade) !important;
      }
    }
  }
}

.mat-slide-toggle {
  .mat-slide-toggle-bar {
    background-color: var(--color-silver) !important;
    width: 50px !important;
    height: 20px !important;
    border-radius: 10px !important;
  }

  .mat-slide-toggle-thumb-container {
    top: 4px !important;
    left: 2px !important;
    width: 12px !important;
    height: 12px !important;

    .mat-slide-toggle-thumb {
      background-color: var(--color-pure-white) !important;
      height: 12px !important;
      width: 12px !important;
    }

    .mat-slide-toggle-ripple {
      .mat-slide-toggle-persistent-ripple {
        background-color: var(--color-london-gray) !important;
      }
    }
  }
}

.mat-slide-toggle.mat-disabled.mat-checked {
  opacity: 1 !important;

  .mat-slide-toggle-bar {
    background-color: var(--color-nightshade) !important;
  }
}

.mat-slide-toggle.mat-disabled {
  opacity: 1 !important;

  .mat-slide-toggle-bar {
    background-color: var(--color-silver) !important;
  }
}

.user-budget-rollover.mat-slide-toggle .mat-slide-toggle-bar {
  height: 12px !important;
  width: 23px !important;
  background-color: var(--color-silver) !important;

  .mat-slide-toggle-thumb-container {

    .mat-slide-toggle-thumb {
      height: 10px !important;
      width: 10px !important;
      margin-top: -3px;
    }

    .mat-slide-toggle-ripple {
      .mat-slide-toggle-persistent-ripple {
        opacity: 0 !important;
      }
    }
  }
}

.toggle-container,
.notification-component {
  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var(--color-nightshade) !important;
    }

    .mat-slide-toggle-thumb-container {
      transform: translate3d(16px, 0, 0) !important;

      .mat-slide-toggle-thumb {
        background-color: var(--color-pure-white) !important;
      }

      .mat-slide-toggle-ripple {
        .mat-slide-toggle-persistent-ripple {
          background-color: var(--color-nightshade) !important;
        }
      }
    }
  }

  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      background-color: var(--color-silver) !important;
      width: 28px !important;
      height: 14px !important;
      border-radius: 10px !important;
    }

    .mat-slide-toggle-thumb-container {
      top: 2.8px !important;
      left: 2px !important;
      width: 8px !important;
      height: 8px !important;

      .mat-slide-toggle-thumb {
        background-color: var(--color-pure-white) !important;
        height: 8px !important;
        width: 8px !important;
      }

      .mat-slide-toggle-ripple {
        width: 0 !important;
        height: 0 !important;

        .mat-slide-toggle-persistent-ripple {
          background-color: var(--color-london-gray) !important;
        }
      }
    }
  }
}

.reverse-label-toggle-container {
  .mat-slide-toggle {
    .mat-slide-toggle-label {
      display: flex !important;
      flex-direction: row-reverse !important;
      gap: 5px;
    }
  }
}


.blue-slider-toggle {
  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      background-color: var(--color-disabled) !important;
      width: 33px !important;
      height: 12px !important;
      border-radius: 10px !important;
    }

    .mat-slide-toggle-thumb-container {
      top: 1.5px !important;
      left: 2px !important;
      width: 12px !important;
      height: 12px !important;

      .mat-slide-toggle-thumb {
        background-color: var(--color-pure-white) !important;
        height: 9px !important;
        width: 9px !important;
      }

      .mat-slide-toggle-ripple {
        .mat-slide-toggle-persistent-ripple {
          background-color: var(--color-london-gray) !important;
        }
      }
    }
  }

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var(--color-mountain-purple) !important;
    }

    .mat-slide-toggle-thumb-container {
      transform: translate3d(20px, 0, 0) !important;

      .mat-slide-toggle-thumb {
        background-color: var(--color-pure-white) !important;
      }

      .mat-slide-toggle-ripple {
        .mat-slide-toggle-persistent-ripple {
          background-color: #aeb4e6 !important;
        }
      }
    }
  }
}