//MEDAI QUERIES MANAGER
/*0 - 600     Phone
600 - 900px Table Portrait
900 - 1200  Table Landscape
1200 - 1800 Normal styles apply
1800px +    Big Descktop
*/

/* breakpoint argument choices
phone
tab-port
tab-land
desk
big-desk
*/

@mixin responds($breakpoint) {

  /* Extra small devices (phones, 600px and down) */
  @if $breakpoint==phone {
    @media only screen and (max-width: 600px) {
      @content
    }
  }

  ;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @if $breakpoint==tab-port {
    @media only screen and (min-width: 600px) and (max-width:767px) {
      @content
    }
  }

  ;

  @if $breakpoint==tab-land {

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 767px) and (max-width:1023px) {
      @content
    }
  }

  ;

  @if $breakpoint==desk {

    /* Large devices (laptops/desktops, 1024px and up) */
    @media only screen and (min-width: 1024px) and (max-width:1400px) {
      @content
    }
  }

  ;

  @if $breakpoint==big-desk {

    /* Extra large devices (large laptops and desktops, 1400px and up) */
    @media only screen and (min-width: 1400px) {
      @content
    }
  }

  @if $breakpoint==xl-big-desk {

    /* Extra large devices (large laptops and desktops, 1800px and up) */
    @media only screen and (min-width: 1800px) {
      @content
    }
  }
}

;


@mixin respond-for($breakpoint) {

  @if $breakpoint==desktop {
    @media screen and (min-width : 768px) {
      @content
    }
  };

  @if $breakpoint==mobile {
    @media screen and (max-width : 767px) {
      @content
    }
  };
}

@mixin for($breakpoint) {

  @if $breakpoint==desktop {
    @media screen and (min-width : 431px) {
      @content
    }
  };

  @if $breakpoint==mobile {
    @media screen and (max-width : 430px) {
      @content
    }
  };
}


@mixin touch {

  @media (-moz-touch-enabled: 1),
  (pointer:coarse) {
    @content
  }
}

$breakpoints: (
  sm: (min: 576px,
    max: 575.98px,
  ),
  md: (min: 768px,
    max: 767.98px),
  lg: (min: 992px,
    max: 991.98px),
  xl: (min: 1200px,
    max: 1199.98px),
  xxl: (min: 1400px,
    max: 1399.98px,
  )
);


@mixin breakpoint($breakpoint, $direction: min) {

  //check if the supplied breakpoint exists in our breakpoits map
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-values: map-get($breakpoints, $breakpoint);
    $breakpoint-min: map-get($breakpoint-values, min);
    $breakpoint-max: map-get($breakpoint-values, max);

    //check if we are writing styles for larger or smaller screens
    @if $direction ==min {
      @media (min-width: $breakpoint-min) {
        @content;
      }
    }

    @else {
      @media (max-width: $breakpoint-max) {
        @content;
      }
    }

    // use the custom value if the breakpoint is not part of the pre-defined list
  }

  @else {

    @if $direction ==min {
      @media (min-width: $breakpoint) {
        @content;
      }
    }

    @else {
      @media (max-width: $breakpoint) {
        @content;
      }
    }
  }
}