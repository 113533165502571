@import '../../assets/style/mixins';

input:not(.country-search) {
  box-sizing: border-box;
}

.mat-form-field {
  width: 100%;
  letter-spacing: normal !important;

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      padding: 0 !important;

      .mat-form-field-outline {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
      }
    }
  }

  .input-label {
    font-family: "Brandon Text";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: block;
    color: var(--color-london-gray);
    position: absolute;
    top: -30px;
    left: 0px;
    display: block;
    white-space: nowrap;

    .required-hint {
      color: var(--color-notification) !important;
    }
  }

  .mat-form-field-infix {
    input {
      color: var(--color-nightshade) !important;
    }

    input[ng-reflect-readonly="true"] {
      cursor: pointer !important;
    }

    input::placeholder {
      color: var(--color-silver);
      opacity: 1;
      /* Firefox */
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-silver);
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--color-silver);
    }

    .mat-form-field-suffix,
    mat-form-field-prefix {
      color: var(--color-silver) !important;
    }
  }

  .mat-form-field-subscript-wrapper {
    padding: 0 2px !important;
    margin-top: 8px !important;

    .mat-form-field-hint-wrapper {
      color: rgba(91, 114, 130, 0.9) !important;
      font-family: "Brandon Text";
      font-size: 12px;
      line-height: 17px;
    }
  }

  .material-icons {
    color: var(--color-london-gray);
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-flex {
    padding: 5px 0 0 0 !important;
    height: 51px !important;

    .mat-form-field-infix {
      padding: 0 !important;
      height: 50px !important;
      border-top: none !important;

      input {
        color: var(--color-nightshade) !important;
        padding: 16px 12px;
        width: calc(100% - 24px);

        @supports (-moz-appearance: none) {
          padding: 15px 12px;
        }
      }

      input:disabled {
        color: rgba(0, 0, 0, 0.38) !important;
      }

      .mat-select {
        color: var(--color-nightshade) !important;
        padding: 16px 14px;
        width: calc(100% - 28px);

        @supports (-moz-appearance: none) {
          padding: 16px 12px;
        }

        &.mat-select-disabled {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              opacity: 0;
            }
          }
        }

        .mat-select-trigger {
          .mat-select-value {
            .mat-select-placeholder {
              color: var(--color-silver) !important;
            }
          }

          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              width: 14px;
              height: 14px;
              margin-top: -3px !important;
              border-left: unset !important;
              border-left: unset !important;
              border-left: unset !important;
              border-right: unset !important;
              border-top: unset !important;
              background: url(/assets/images/arrow_drop_down-rounded.svg);
              background-position: center center;
              //background-size: 12px 12px;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  @supports (-moz-appearance: none) {
    div.mat-form-field-prefix {
      top: -8px !important;

      .mat-icon {
        margin: 0 10px !important;
      }
    }

    div.mat-form-field-suffix {
      top: -8px !important;

      .mat-icon {
        margin: 0 10px !important;
      }
    }
  }

  .mat-form-field-prefix {
    top: -11px !important;

    .mat-icon {
      margin: 0 10px !important;
    }
  }

  .mat-form-field-suffix {
    top: -12px !important;

    .mat-icon {
      margin: 0 10px !important;
    }
  }
}

.approver-search-input {
  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      padding: 0 0 0 0 !important;
      height: 43px !important;

      .mat-form-field-prefix {
        top: -7px !important;
        margin-left: 6px;
      }

      .mat-form-field-infix {
        padding: 0 !important;
        height: 43px !important;
        border-top: none !important;

        input {
          color: var(--color-nightshade) !important;
          padding: 12px 6px;
          width: calc(100% - 24px);

          @supports (-moz-appearance: none) {
            padding: 12px 6px;
          }
        }
      }
    }

    @supports (-moz-appearance: none) {
      div.mat-form-field-prefix {
        top: -15px !important;

        .mat-icon {
          margin: 0 10px !important;
        }
      }

      div.mat-form-field-suffix {
        top: -15px !important;

        .mat-icon {
          margin: 0 10px !important;
        }
      }
    }

    .mat-form-field-prefix {
      top: -11px !important;

      .mat-icon {
        margin: 0 10px !important;
      }
    }

    .mat-form-field-suffix {
      top: -12px !important;

      .mat-icon {
        margin: 0 10px !important;
      }
    }
  }
}

.input-field {
  padding-bottom: 25px;
  padding-top: 10px;
  border: none !important;
  overflow: unset !important;
  font-family: "Brandon Text";
}

.input-field.bold-label {
  .input-label {
    font-weight: bold;
  }
}

.input-field.text-area {
  height: 175px;

  .mat-form-field {
    .input-label {
      font-family: "Brandon Text" !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      display: block !important;
      color: var(--color-london-gray);
      position: absolute !important;
      top: -31px !important;
      left: 0px !important;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;

      .mat-form-field-flex {
        height: 175px !important;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          height: 150px !important;
        }

        .cdk-textarea-autosize::-webkit-scrollbar-track {
          background-color: transparent;
          border-top: 1px solid rgba(0, 0, 0, 0.12);
        }

        .cdk-textarea-autosize::-webkit-scrollbar {
          width: 7px;
          background-color: transparent;
        }

        .cdk-textarea-autosize::-webkit-scrollbar-thumb {
          background-color: var(--color-silver);
        }

        .mat-form-field-infix {
          height: 140px !important;
          padding: 5px !important;

          .cdk-textarea-autosize {
            min-height: 125px !important;
            max-height: 150px !important;
            width: calc(100% - 22px) !important;
            padding: 11px !important;
          }
        }
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 12px !important;
      }
    }
  }
}

.input-field.text-area.fixed-height {
  height: 175px;

  .mat-form-field {
    .input-label {
      font-family: "Brandon Text" !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      display: block !important;
      color: var(--color-london-gray);
      position: absolute !important;
      top: -31px !important;
      left: 0px !important;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;

      .mat-form-field-flex {
        height: 175px !important;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          height: 150px !important;
        }

        .cdk-textarea-autosize::-webkit-scrollbar-track {
          background-color: transparent;
          border-top: 1px solid rgba(0, 0, 0, 0.12);
        }

        .cdk-textarea-autosize::-webkit-scrollbar {
          width: 7px;
          background-color: transparent;
        }

        .cdk-textarea-autosize::-webkit-scrollbar-thumb {
          background-color: var(--color-silver);
        }

        .mat-form-field-infix {
          height: 140px !important;
          padding: 5px !important;

          .cdk-textarea-autosize {
            min-height: 125px !important;
            height: 125px !important;
            max-height: 150px !important;
            width: calc(100% - 22px) !important;
            padding: 11px !important;
          }
        }
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 8px !important;
      }
    }
  }
}

.mat-form-field.input-with-prefix-icon {
  .input-label {
    left: -28px !important;
  }
}

.login-component {
  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        height: 56px !important;

        .mat-form-field-infix {
          height: 51px !important;

          input {
            color: var(--color-nightshade) !important;
            padding: 18px 12px !important;
            width: calc(100% - 24px);

            @supports (-moz-appearance: none) {
              padding: 16px 12px !important;
            }
          }

          .mat-select {
            padding: 18px 14px !important;
          }
        }

        .material-icons {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 0px !important;
      }
    }
  }
}

.mat-form-field.mat-form-field-invalid {
  .input-label {
    color: var(--color-notification) !important;
  }

  .material-icons {
    color: var(--color-notification) !important;
  }
}

.mat-form-field.mat-form-field-disabled,
.mat-form-field.mat-form-field-disabled:hover {
  .input-label {
    color: var(--color-silver) !important;
  }

  input::placeholder {
    color: var(--color-silver) !important;
    opacity: 1;
    /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-silver) !important;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-silver) !important;
  }

  .material-icons {
    color: var(--color-silver) !important;
  }

  .mat-form-field-subscript-wrapper {
    .mat-form-field-hint-wrapper {
      color: var(--color-silver) !important;

      .mat-hint {
        color: var(--color-silver) !important;
      }
    }
  }
}

.autocomplete-search {
  .mat-select-search-inside-mat-option {
    .mat-select-search-inner {
      background-color: var(--color-smoke-white);
      top: 1px;

      .mat-select-search-input {
        border: 2px solid var(--color-london-gray);
        border-radius: 5px;
        padding-left: 45px;
      }
    }
  }

  .material-icons {
    position: absolute;
    top: 16px;
    left: 10px;
    color: var(--color-london-gray);
    z-index: 101;
    font-size: 18px;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 5px 0 15px 0;
  }

  .mat-form-field-outline {
    color: var(--color-london-gray);
  }

  .mat-select-arrow-wrapper {
    transform: translateY(7%) !important;
  }

  .mat-form-field-outline-end,
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap {
    border-width: 2px !important;
  }
}

.mp-input-field {
  .mat-form-field-infix {
    padding: 5px 0 15px 0;
  }

  .mat-form-field-outline {
    color: var(--color-london-gray);
  }

  .mat-select-arrow-wrapper {
    transform: translateY(7%) !important;
  }

  .mat-form-field-outline-end,
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap {
    border-width: 1px !important;
  }
}

.mp-input-field-region{
  .mat-form-field-infix {
    padding: 5px 0 15px 0;
  }

  .mat-form-field-outline {
    color: #B9BABA;
  }

  .mat-select-arrow-wrapper {
    transform: translateY(7%) !important;
    display: none;
  }

  .mat-form-field-outline-end,
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap {
    border-width: 1px !important;
  }

  .mat-form-field-wrapper{
    padding-bottom: 0;
  }

  .mat-form-field-suffix{
    top: .32em !important;

    .mat-icon{
      width: fit-content !important;
      height: fit-content !important;
      font-size: 20px !important;
    }
  }
}

.mat-form-field.thick-border {

  .mat-form-field-outline-end,
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap {
    border-width: 1px !important;
  }
}

.mat-form-field:hover {
  .mat-form-field-outline-thick {
    color: var(--color-nightshade) !important;
  }

  .input-label {
    color: var(--color-nightshade) !important;
  }

  .mat-hint {
    color: var(--color-nightshade) !important;
  }

  .mat-hint.error {
    color: var(--color-notification) !important;
  }

  .material-icons {
    color: var(--color-nightshade) !important;
  }

  input::placeholder {
    color: var(--color-silver) !important;
    opacity: 1;
    /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-silver) !important;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-silver) !important;
  }
}

.mat-form-field.mat-focused {
  .input-label {
    color: var(--color-mountain-purple) !important;
  }

  .mat-hint {
    color: var(--color-mountain-purple) !important;
  }

  .material-icons {
    color: var(--color-mountain-purple) !important;
  }
}

.mat-form-field-appearance-outline.mat-focused {
  .mat-form-field-outline-thick {
    color: var(--color-mountain-purple) !important;
  }
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid {
  .mat-form-field-outline-thick {
    color: var(--color-notification) !important;
  }
}

.login-valid {
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid {
    .mat-form-field-outline-thick {
      color: var(--color-london-gray) !important;
    }
  }

  .mat-form-field {
    .input-label {
      color: var(--color-nightshade) !important;
      font-family: "Brandon Text Medium";
    }
  }
}

.mat-hint {
  color: rgba(91, 114, 130, 0.9) !important;
}

.mat-hint.error {
  color: var(--color-notification) !important;
}

.mat-error {
  font-size: 12px !important;
  line-height: 17px !important;
  color: var(--color-notification) !important;
}

.mat-autocomplete-panel {
  background: var(--color-pure-white);
}

.mat-autocomplete-panel::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.mat-autocomplete-panel::-webkit-scrollbar-thumb {
  background-color: var(--color-silver);
}

.input-field.autocomplete-input {
  .input-label {
    left: -35px;
  }
}

.input-field.date-picker-input {
  .mat-form-field-appearance-outline {
    .mat-form-field-suffix {
      top: -7px !important;
    }
  }
}

.ngx-mat-drp-date-display {
  .mat-form-field-flex {
    border: 2px solid var(--color-london-gray);
    border-radius: 5px;

    .mat-form-field-infix {
      padding: 13px;
      border: none;
      border-top: none;
      margin-top: 0;

      input {
        cursor: pointer;
      }

      .mat-form-field-label {
        display: none;
      }
    }
  }

  .mat-form-field-underline {
    border-radius: 5px;
    border-color: var(--color-london-gray);
    background-color: var(--color-london-gray);
    width: unset;
  }

  .mat-form-field-flex:focus {
    border: 2px solid var(--color-mountain-purple);
  }
}

.mat-select-panel:not(.limited-width) {
  max-width: unset !important;
}

.mat-select-panel-wrap {
  .mat-select-panel:not(.limited-width) {
    max-width: unset !important;

    .mat-option {
      color: var(--color-london-gray);

      @include responds(phone) {
        width: 350px !important;
      }
    }

    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
      color: var(--color-nightshade);
    }

    .mat-option:hover:not(.mat-option-disabled) {
      color: var(--color-nightshade);
    }
  }
}

.mat-error {
  color: var(--color-notification);
}

.mat-option-multiple {

  .mat-option-pseudo-checkbox:hover,
  .mat-pseudo-checkbox-checked {
    background: var(--color-mountain-purple) !important;
  }
}

.mat-form-field-appearance-outline.thin-input {
  .mat-form-field-flex {
    height: 28px !important;
    margin: 0 !important;

    .mat-form-field-prefix {
      top: -3px !important;

      .mat-icon {
        margin-right: 0 !important;
        font-size: 14px;
      }
    }

    .mat-form-field-infix {
      height: 28px !important;

      input {
        color: var(--color-nightshade) !important;
        padding: 7.5px 5px 7.5px 10px !important;
        width: calc(100% - 5px);
        font-size: 12px !important;
        line-height: 14px !important;
        font-family: "Brandon Text" !important;
      }

      .mat-select {
        padding: 7.5px 5px 7.5px 10px !important;
        width: calc(100% - 20px);
        font-size: 12px !important;
        line-height: 14px !important;
        font-family: "Brandon Text" !important;
      }
    }

    .mat-form-field-suffix {
      top: 0px !important;

      .mat-icon {}
    }
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 8px !important;
  }
}

.mat-form-field-appearance-outline.thin-input.input-with-prefix-icon {
  .mat-form-field-flex {
    height: 30px !important;

    .mat-form-field-prefix {
      top: -3px !important;

      .mat-icon {
        margin-right: 0 !important;
        font-size: 14px;
      }
    }

    .mat-form-field-infix {
      height: 26px !important;

      input {
        color: var(--color-nightshade) !important;
        padding: 7px 5px 7px 0px !important;
        width: calc(100% - 28px);
        font-size: 12px !important;
        line-height: 14px !important;
        font-family: "Brandon Text" !important;
      }

      .mat-select {
        padding: 7px 5px 7px 0px !important;
        width: calc(100% - 28px);
        font-size: 12px !important;
        line-height: 14px !important;
        font-family: "Brandon Text" !important;
      }
    }
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 8px !important;
  }
}

.mat-form-field.no-border {

  .mat-form-field-outline-end,
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap {
    border-width: 0px !important;
  }
}

.mat-form-field {
  .mat-select.mat-select-empty {
    .mat-select-placeholder {
      color: var(--color-silver) !important;
    }
  }
}

.mat-form-field:hover {
  .mat-select.mat-select-empty {
    .mat-select-placeholder {
      color: var(--color-silver) !important;
    }
  }
}

.mat-form-field {
  .mat-select.mat-select-invalid {
    .mat-select-arrow {
      color: var(--color-notification) !important;
    }
  }
}

.mat-select-search-clear {
  top: -7px !important;
}

.edit-budget-component {
  .mat-form-field.input-with-prefix-icon {
    .input-label {
      left: -29px !important;
    }
  }

  .mat-form-field.budget-field {
    .mat-form-field-prefix {
      .mat-icon {
        margin-right: 0 !important;
      }
    }

    .mat-input-element {
      padding-left: 0 !important;
    }
  }
}

.thin-input.date-picker.mat-form-field {
  .mat-form-field-suffix {
    padding: 4px 2px 4px 0;

    .mat-datepicker-toggle {
      width: 20px !important;
      height: 20px !important;
      font-size: 18px !important;

      .mat-icon-button {
        width: 20px !important;
        height: 20px !important;
        font-size: 18px !important;
        padding: 0 !important;

        .mat-datepicker-toggle-default-icon {
          width: 20px;
          height: 20px;
          fill: var(--color-nightshade) !important;
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.input-field.prefix-icon {
  .mat-form-field {
    .input-label {
      left: -36px !important;
    }
  }
}

.input-field.align-center {
  .mat-form-field {
    .mat-form-field-flex {
      .mat-form-field-infix {
        input {
          padding-left: 0% !important;
          padding-right: 0 !important;
          width: 94% !important;
          text-align: center;
        }

        input[ng-reflect-readonly] {
          cursor: pointer !important;
        }
      }
    }
  }
}

.input-field.prefix-icon.align-center {
  .mat-form-field {
    .input-label {
      left: 0px !important;
    }

    .mat-form-field-flex {
      .mat-form-field-prefix {
        left: 30% !important;
      }

      .mat-form-field-infix {
        margin-left: -36px !important;

        input {
          padding-left: 0% !important;
          width: 97% !important;
          text-align: center;
        }

        input[ng-reflect-readonly] {
          cursor: pointer !important;
        }
      }
    }
  }
}

.input-field.suffix-icon.align-center {
  .mat-form-field {
    .mat-form-field-flex {
      .mat-form-field-suffix {
        right: calc(50% - 30px) !important;
        top: -18% !important;
        margin-right: -36px;

        .mat-icon {
          font-size: 22px !important;
        }
      }

      .mat-form-field-infix {
        input {
          padding-left: 0% !important;
          width: 100% !important;
          text-align: right !important;
          padding-right: calc(50% - 30px) !important;
        }

        input[ng-reflect-readonly] {
          cursor: pointer !important;
        }
      }
    }
  }
}

.input-field.prefix-icon.align-center.text-variation.width-456 {
  .mat-form-field {
    .mat-form-field-prefix {
      left: calc(50% - 76px) !important;
      top: -11px !important;
    }

    .mat-form-field-infix {
      input {
        text-align: left !important;
        padding-left: calc(50% - 40px) !important;
        width: 100% !important;
      }
    }
  }
}

.input-field.prefix-icon.align-center.text-variation {
  .mat-form-field {
    .mat-form-field-prefix {
      left: calc(50% - 76px) !important;
      top: 13px !important;
      position: absolute;

      @supports (-moz-appearance: none) {
        top: 15px !important;
      }
    }

    .mat-form-field-infix {
      margin-left: 0 !important;

      input {
        text-align: left !important;
        padding-left: calc(50% - 40px) !important;
        width: 100% !important;
      }
    }
  }
}

.mat-form-field-inherit {
  position: inherit;
  right: 70% !important;
}

.menu-category {
  .radio-button {
    .category-name {
      color: var(--color-london-gray);
      font-family: "Brandon Text";
      font-size: 24px;
      font-weight: bold;
      line-height: 34px;

      .mat-radio-label {
        user-select: none;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        vertical-align: middle;
        width: 100%;
        margin-top: -4px !important;
      }
    }
  }
}

.text-area-mat-field {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      .input-label {
        top: -32px !important;
      }
    }
  }
}

.mat-select-search-panel {
  min-width: 280px !important;
  width: 280px !important;
}

.mat-select-search-panel::-webkit-scrollbar {
  width: 0px !important;
  background-color: transparent;
}

.input-field.select-no-arrow {
  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-select {
            .mat-select-trigger {
              .mat-select-arrow-wrapper {
                .mat-select-arrow {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.input-field.date-range-picker.design-system {
  .ngx-mat-drp-date-display {
    .mat-form-field-flex {
      position: relative;

      .mat-form-field-infix {
        input {
          padding-left: 0 !important;
        }
      }

      .mat-form-field-suffix {
        position: absolute;
        top: 0 !important;
        right: 10px !important;
        cursor: pointer !important;

        .ngx-mat-drp-calendar {
          background-position: center !important;
          background-repeat: no-repeat !important;
          height: 48px !important;
          background-size: contain;
        }
      }
    }
  }
}

.mat-select-search-panel-desktop-booking {
  max-width: 380px !important;
  min-width: 380px !important;
  width: 380px !important;
  color: var(--color-pure-white) !important;
  margin-bottom: -20px;
  margin-top: -20px;

  .mat-select-search-inner {
    width: 380px !important;
  }
}

.mat-select-search-panel-mobile-booking {
  max-width: calc(100vw - 55px) !important;
  min-width: calc(100vw - 55px) !important;
  width: calc(100vw - 55px) !important;
  color: var(--color-pure-white) !important;
  margin-bottom: -20px;
  margin-top: -20px;

  .mat-select-search-inner {
    width: calc(100vw - 60px) !important;
  }
}


.two-factor-auth-otp-dialog-mat-form {
  .mat-form-field-wrapper {
    padding-bottom: 1.1em;
  }
}

.user-budget-rollover.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  height: 12px !important;
  width: 23px !important;
  background-color: var(--color-nightshade) !important;

  .mat-slide-toggle-thumb-container {
    transform: translate3d(10px, 0, 0) !important;

    .mat-slide-toggle-thumb {
      height: 10px !important;
      width: 10px !important;
      margin-top: -3px;
    }

    .mat-slide-toggle-ripple {
      .mat-slide-toggle-persistent-ripple {
        opacity: 0 !important;
      }
    }
  }
}

.input-field.location-input-align {
  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      .mat-form-field-infix {
        margin-left: -26px !important;

        input {
          padding-left: 35px !important;
          width: calc(100% - 1px) !important;
          margin-left: -5px;
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-field.date-range-picker.design-system {
  .ngx-mat-drp-date-display {
    .mat-form-field-flex {
      position: relative;

      .mat-form-field-infix {
        input {
          padding-left: 0 !important;
        }
      }

      .mat-form-field-suffix {
        position: absolute;
        top: 0 !important;
        right: 10px !important;
        cursor: pointer !important;

        .ngx-mat-drp-calendar {
          background-position: center !important;
          background-repeat: no-repeat !important;
          height: 48px !important;
          background-size: contain;
        }
      }
    }
  }
}

.input-field-rounded {
  .mat-form-field {
    .input-label {
      font-family: "Brandon Text" !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      display: block !important;
      color: var(--color-london-gray);
      position: absolute !important;
      top: -24px !important;
      left: 0px !important;
    }

    .mat-form-field-flex {
      padding: 0 !important;
      height: 42px !important;
      border-top: none !important;

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-width: 1px !important;
      }

      .mat-form-field-outline-start {
        border-radius: 19px 0 0 19px !important;
        width: 22px;
      }

      .mat-form-field-outline-gap {
        border-radius: 0 !important;
      }

      .mat-form-field-outline-end {
        border-radius: 0 19px 19px 0 !important;
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 2px;
      }

      .mat-form-field-infix {
        height: 37px !important;
        padding: 5px !important;

        input::placeholder {
          color: var(--color-silver) !important;
        }

        input {
          padding: 10px 12px !important;
          color: var(--color-london-gray) !important;
          font-family: "Brandon Text" !important;
          font-size: 12px !important;
          line-height: 17px !important;
          width: 100% !important;
        }
      }
    }
  }
}

.input-field-rounded.text-area {
  .mat-form-field {
    .mat-form-field-flex {
      height: unset !important;
      min-height: 42px !important;

      .mat-form-field-infix {
        height: unset !important;
        padding: 12px 10px 6px 10px !important;

        textarea::-webkit-scrollbar-track {
          background-color: transparent;
        }

        textarea::-webkit-scrollbar {
          width: 0px;
          background-color: transparent;
        }

        textarea::-webkit-scrollbar-thumb {
          background-color: var(--color-silver);
        }

        textarea,
        .textarea {
          min-height: 14px !important;
          max-height: unset !important;
          border-radius: 0px;
          color: var(--color-london-gray) !important;
          font-family: "Brandon Text" !important;
          font-size: 8.5px !important;
          line-height: 12px !important;
          width: calc(100% - 8px);
          padding: 4px !important;
          outline: none;
          cursor: text;
        }

        .text-area-invisible {
          height: 21px;
          position: absolute;
          top: 15px;
          color: transparent !important;
          width: 96% !important;
        }
      }
    }
  }
}


.input-field-rounded.autocomplete.multiselect {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;

  .mat-form-field {
    .mat-form-field-flex {
      height: fit-content !important;
      min-height: 42px !important;

      .mat-form-field-infix {
        height: unset !important;
        padding: 8px 8px 4px 8px !important;

        .mat-chip-list {
          .mat-chip {
            min-width: 80px;
            padding: 0px 12px !important;
            min-height: 30px !important;
            height: 30px !important;
            border-color: var(--color-london-gray) !important;
            color: var(--color-london-gray) !important;

            .mat-icon {
              color: var(--color-london-gray) !important;
              cursor: pointer !important;
              opacity: 1 !important;
              font-size: 22px !important;
              margin: 0 !important;
              position: absolute !important;
              top: 1.5px !important;
              right: 5px !important;
            }
          }
        }

        input::placeholder {
          color: var(--color-silver) !important;
        }

        input {
          padding: 10px 12px !important;
          color: var(--color-london-gray) !important;
          font-family: "Brandon Text" !important;
          font-size: 12px !important;
          line-height: 17px !important;
          width: 100% !important;
        }
      }
    }
  }
}

.product-text-area {
  .mat-form-field-flex {
    height: 170px !important;
  }
}

.input-field.product-text-area {
  .mat-form-field {
    .input-label {
      font-family: "Brandon Text" !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      display: block !important;
      color: var(--color-london-gray);
      position: absolute !important;
      top: -31px !important;
      left: 0px !important;
    }

    .mat-form-field-flex {

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        height: 150px !important;
      }

      .cdk-textarea-autosize::-webkit-scrollbar-track {
        background-color: transparent;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }

      .cdk-textarea-autosize::-webkit-scrollbar {
        width: 7px;
        background-color: transparent;
      }

      .cdk-textarea-autosize::-webkit-scrollbar-thumb {
        background-color: var(--color-silver);
      }

      .mat-form-field-infix {
        height: 140px !important;
        padding: 5px !important;

        .cdk-textarea-autosize {
          height: 20px !important;
          min-height: 135px !important;
          max-height: 150px !important;
          width: 95% !important;
          padding: 11px !important;
        }
      }
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 12px !important;
    }
  }
}

ckeditor:not(.default) {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    box-sizing: border-box;
    height: 208px;
    border: 2px solid var(--color-london-gray) !important;
    border-radius: 3px;
    background-color: var(--color-pure-white);

    .ck-editor__top {
      border-bottom: 2px solid var(--color-london-gray);
    }

    .ck-editor__main {
      .ck-content {
        height: 160px !important;
        border-bottom: thin;
      }
    }
  }
}

.cdk-field-invalid {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    border: 2px solid var(--color-notification) !important;
  }
}


.input-field.bold-border.autocomplete.multiselect {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;
      min-height: 51px;

      .mat-form-field-flex {
        height: unset !important;
        min-height: 47px !important;
        padding: 7px 4px 2px 4px !important;

        .mat-form-field-infix {
          height: unset !important;
          padding: 0px 0px 1px 0px !important;
          min-height: 40px !important;

          .mat-chip-list {
            .mat-chip-list-wrapper {
              margin: 0 !important;

              .mat-chip {
                padding: 5px 48px 5px 12px !important;
                min-height: 36px !important;
                height: 36px !important;
                border-radius: 3px !important;
                color: var(--color-london-gray) !important;
                margin: 2px !important;

                .mat-icon {
                  top: 4px !important;
                  right: 5px !important;
                  color: var(--color-london-gray) !important;
                }
              }

              .mat-chip.disabled-chip {
                border-color: var(--color-silver) !important;
                background-color: var(--color-silver) !important;
              }

              .mat-chip.secondary-chip {
                border-color: var(--color-pond-blue) !important;
                background-color: var(--color-pond-blue) !important;
              }
            }
          }

          input::placeholder {
            color: var(--color-silver) !important;
          }

          input {
            padding: 10px 12px !important;
            color: var(--color-london-gray) !important;
            font-family: "Brandon Text" !important;
            font-size: 12px !important;
            line-height: 17px !important;
            width: 100% !important;
            margin-left: -4px;
          }
        }

        .mat-form-field-prefix {
          background-color: var(--color-pond-blue);
          min-height: 36px !important;
          border-radius: 4px 0 0 4px;
          position: absolute;
          top: 5px !important;
          left: 1px !important;
          bottom: 1px;
          height: inherit !important;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;

          .flex {
            padding: 12px 16px 12px 10px !important;

            .icon {
              margin-left: 2px;

              .mat-icon {
                width: 16px !important;
                height: 16px !important;
                font-size: 16px !important;
                margin: 0 !important;
              }

              span {
                width: 16px !important;
                height: 16px !important;
              }
            }

            .text {
              color: var(--color-london-gray);
              font-family: "Brandon Text";
              font-size: 12px;
              line-height: 17px;
              padding-left: 5px;
            }
          }
        }
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 14px !important;
      }
    }
  }
}

.input-field-dotted.autocomplete.multiselect-2 {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;

  .mat-form-field {

    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;

      .mat-form-field-flex {
        height: unset !important;
        min-height: 40px !important;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: 2px !important;
          border-color: var(--color-pond-blue) !important;
        }

        .mat-form-field-outline-start {
          border-radius: 4px 0 0 4px !important;
          border-top-style: dashed !important;
          border-bottom-style: dashed !important;
          border-left-style: dashed !important;
          border-right-style: none !important;
        }

        .mat-form-field-outline-gap {
          border-radius: 0 !important;
          border-top-style: dashed !important;
          border-bottom-style: dashed !important;
          border-left-style: none !important;
          border-right-style: none !important;
        }

        .mat-form-field-outline-end {
          border-radius: 0 4px 4px 0 !important;
          border-top-style: dashed !important;
          border-bottom-style: dashed !important;
          border-left-style: none !important;
          border-right-style: dashed !important;
        }

        .mat-form-field-infix {
          height: unset !important;
          padding: 0px 0px 1px 85px !important;

          .mat-chip-list {
            .mat-chip-list-wrapper {
              margin: 0 !important;

              .mat-chip {
                border-color: var(--color-pure-white) !important;
                background-color: var(--color-pure-white) !important;
                color: var(--color-nightshade) !important;
                font-size: 12px !important;
                letter-spacing: 0 !important;
                line-height: 17px !important;
                font-weight: 500 !important;
                padding: 0px 11px !important;

                .mat-icon {
                  color: inherit !important;
                  opacity: 1 !important;
                  font-size: 14px !important;
                  width: 14px !important;
                  height: 14px !important;
                  top: 4.5px !important;
                  right: 5px !important;
                  display: grid !important;
                  place-content: center !important;
                  position: unset !important;
                  margin: 0 0 0 8px !important;
                }
              }

              .primary-chip.mat-chip {
                color: var(--color-nightshade) !important;
              }

              .disabled-chip.mat-chip {
                color: rgb(118, 120, 127) !important;
              }
            }
          }

          input::placeholder {
            color: var(--color-silver) !important;
          }

          input {
            padding: 10px 12px !important;
            color: var(--color-london-gray) !important;
            font-family: "Brandon Text" !important;
            font-size: 12px !important;
            line-height: 17px !important;
            width: 100% !important;
            margin-left: -4px;
          }
        }

        .mat-form-field-prefix {
          background-color: var(--color-pond-blue);
          min-height: 36px !important;
          border-radius: 4px 0 0 4px;
          position: absolute;
          top: 1px !important;
          left: 1px !important;
          bottom: 1px;
          height: inherit !important;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          margin-right: 10px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          .flex {
            padding: 12px 16px 12px 10px !important;

            .icon {
              margin-left: 2px;

              .mat-icon {
                width: 16px !important;
                height: 16px !important;
                font-size: 16px !important;
                margin: 0 !important;
              }

              span {
                width: 16px !important;
                height: 16px !important;
              }
            }

            .text {
              color: var(--color-london-gray);
              font-family: "Brandon Text";
              font-size: 12px;
              line-height: 17px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.input-field-dotted.autocomplete.multiselect-3 {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;

  .mat-form-field {

    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;

      .mat-form-field-flex {
        height: unset !important;
        min-height: 40px !important;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: 2px !important;
          border-color: var(--color-nightshade)
        }

        .mat-form-field-infix {
          height: unset !important;
          padding: 0px 0px 1px 0px !important;

          .mat-chip-list {
            .mat-chip-list-wrapper {
              margin: 0 !important;

              .mat-chip {
                border-color: var(--color-pure-white) !important;
                background-color: var(--color-pure-white) !important;
                color: var(--color-nightshade) !important;
                font-size: 12px !important;
                letter-spacing: 0 !important;
                line-height: 17px !important;
                font-weight: 500 !important;
                padding: 0px 11px !important;

                .mat-icon {
                  color: inherit !important;
                  opacity: 1 !important;
                  font-size: 14px !important;
                  width: 14px !important;
                  height: 14px !important;
                  top: 4.5px !important;
                  right: 5px !important;
                  display: grid !important;
                  place-content: center !important;
                  position: unset !important;
                  margin: 0 0 0 8px !important;
                }
              }

              .primary-chip.mat-chip {
                color: var(--color-nightshade) !important;
              }

              .disabled-chip.mat-chip {
                color: rgb(118, 120, 127) !important;
              }
            }
          }

          input {
            padding: 10px 12px !important;
            color: var(--color-london-gray) !important;
            font-family: "Brandon Text" !important;
            font-size: 12px !important;
            line-height: 17px !important;
            width: 100% !important;
            margin-left: -4px;

            &.minimize-input {
              width: 0px !important;
              padding: 5px 0 !important;
              flex: unset;

              &:focus {
                flex: 1 0 20px;
              }

              &:focus-within {
                flex: 1 0 20px;
              }

              &:focus-visible {
                flex: 1 0 20px;
              }

              &:-moz-focusring {
                flex: 1 0 20px;
              }

              &::-moz-focus-inner {
                flex: 1 0 20px;
              }
            }

            &[ng-reflect-readonly="true"] {
              cursor: pointer !important;
            }

            &::placeholder {
              color: var(--color-silver) !important;
              opacity: 1;
              /* Firefox */
            }

            &::-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: var(--color-silver) !important;
            }
          }
        }

        .mat-form-field-prefix {
          min-height: 36px !important;
          border-radius: 4px 0 0 4px;
          position: absolute;
          top: 1px !important;
          left: 1px !important;
          bottom: 1px;
          height: inherit !important;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          margin-right: 10px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          .flex {
            padding: 12px 16px 12px 10px !important;

            .icon {
              margin-left: 2px;

              .mat-icon {
                width: 16px !important;
                height: 16px !important;
                font-size: 16px !important;
                margin: 0 !important;
              }

              span {
                width: 16px !important;
                height: 16px !important;
              }
            }

            .text {
              color: var(--color-london-gray);
              font-family: "Brandon Text";
              font-size: 12px;
              line-height: 17px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.input-field-dotted.autocomplete.multiselect {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;

      .mat-form-field-flex {
        height: unset !important;
        min-height: 42px !important;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: 2px !important;
          border-color: var(--color-pond-blue) !important;
        }

        .mat-form-field-outline-start {
          border-radius: 4px 0 0 4px !important;
          border-top-style: dashed !important;
          border-bottom-style: dashed !important;
          border-left-style: dashed !important;
          border-right-style: none !important;
        }

        .mat-form-field-outline-gap {
          border-radius: 0 !important;
          border-top-style: dashed !important;
          border-bottom-style: dashed !important;
          border-left-style: none !important;
          border-right-style: none !important;
        }

        .mat-form-field-outline-end {
          border-radius: 0 4px 4px 0 !important;
          border-top-style: dashed !important;
          border-bottom-style: dashed !important;
          border-left-style: none !important;
          border-right-style: dashed !important;
        }

        .mat-form-field-infix {
          height: unset !important;
          padding: 0px 0px 1px 85px !important;

          .mat-chip-list {
            .mat-chip-list-wrapper {
              margin: 0 !important;

              .mat-chip {
                padding: 5px 48px 5px 12px !important;
                min-height: 36px !important;
                height: 36px !important;
                border-radius: 3px !important;
                color: var(--color-pure-white) !important;
                margin: 2px !important;

                .mat-icon {
                  top: 4px !important;
                  right: 5px !important;
                  color: var(--color-pure-white) !important;
                }
              }

              .mat-chip.disabled-chip {
                border-color: var(--color-silver) !important;
                background-color: var(--color-silver) !important;
              }

              .mat-chip.secondary-chip {
                border-color: var(--color-denver-sky) !important;
                background-color: var(--color-denver-sky) !important;
              }
            }
          }

          input::placeholder {
            color: var(--color-silver) !important;
          }

          input {
            padding: 10px 12px !important;
            color: var(--color-london-gray) !important;
            font-family: "Brandon Text" !important;
            font-size: 12px !important;
            line-height: 17px !important;
            width: 100% !important;
            margin-left: -4px;
          }
        }

        .mat-form-field-prefix {
          background-color: var(--color-pond-blue);
          min-height: 36px !important;
          border-radius: 4px 0 0 4px;
          position: absolute;
          top: 1px !important;
          left: 1px !important;
          bottom: 1px;
          height: inherit !important;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          margin-right: 10px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          .flex {
            padding: 12px 16px 12px 10px !important;

            .icon {
              margin-left: 2px;

              .mat-icon {
                width: 16px !important;
                height: 16px !important;
                font-size: 16px !important;
                margin: 0 !important;
              }

              span {
                width: 16px !important;
                height: 16px !important;
              }
            }

            .text {
              color: var(--color-london-gray);
              font-family: "Brandon Text";
              font-size: 12px;
              line-height: 17px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.input-field.thick-border {
  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        padding: 4px 0 0 0 !important;
        height: 33px !important;

        .mat-form-field-outline-end,
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap {
          border-width: 1px !important;
        }

        .mat-form-field-prefix {
          top: -2px !important;
        }

        .mat-form-field-infix {
          padding: 0 !important;
          height: 100% !important;
          border-top: none !important;

          .mat-date-range-input .mat-select,
          select,
          input {
            color: var(--color-nightshade) !important;
            padding: 5px 12px !important;
            font-size: 12px !important;
            line-height: 17px !important;
            width: 100%;
            box-sizing: border-box;
          }
        }
      }

      .mat-form-field-subscript-wrapper {
        padding: 0 2px !important;
        margin-top: 28px !important;
      }
    }
  }
}

.input-field.text-area.thick-border {
  height: 95px !important;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        padding: 5px 0 0 0 !important;
        height: 115px !important;

        .mat-form-field-outline-end,
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap {
          border-width: 1px !important;
          height: 90px !important;
        }

        .mat-form-field-prefix {
          top: -1px !important;
        }

        .mat-form-field-infix {
          padding: 0 !important;
          height: 90px !important;
          border-top: none !important;

          textarea {
            color: var(--color-nightshade) !important;
            padding: 5px 15px !important;
            font-size: 12px !important;
            line-height: 17px !important;
            min-height: 60px !important;
            max-height: 60px !important;
            width: 93% !important;
            margin-top: 5px !important;
          }
        }
      }
    }
  }
}

.input-field.text-area.bold-border {
  height: 95px !important;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        padding: 5px 0 0 0 !important;
        height: 115px !important;

        .mat-form-field-outline-end,
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap {
          border-width: 2px !important;
          height: 90px !important;
        }

        .mat-form-field-prefix {
          top: -1px !important;
        }

        .mat-form-field-infix {
          padding: 0 !important;
          height: 90px !important;
          border-top: none !important;

          textarea {
            color: var(--color-nightshade) !important;
            padding: 5px 15px !important;
            font-size: 12px !important;
            line-height: 17px !important;
            min-height: 60px !important;
            max-height: 60px !important;
            width: 93% !important;
            margin-top: 5px !important;
          }
        }
      }
    }
  }
}

.input-textarea.autocomplete.no-border {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;

  .input {
    border: none !important;
  }
}

.input-field.full-width-with-suffix {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        margin-right: -37px !important;

        input {
          padding: 14px 12px !important;
          width: 100% !important;
        }
      }

      div.mat-form-field-suffix {
        top: -12px !important;

        @supports (-moz-appearance: none) {
          top: -12px !important;
        }
      }
    }
  }
}

.input-field.text-area.bold-border.send-reward-mobile-input {
  max-height: calc(100vh - 340px) !important;
  height: unset !important;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        padding: 5px 0 0 0 !important;
        min-height: 95px !important;
        height: unset !important;

        .mat-form-field-outline-end,
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap {
          border-width: 2px !important;
          height: unset !important;
          min-height: 80px !important;
        }

        .mat-form-field-prefix {
          top: -1px !important;
        }

        .mat-form-field-infix {
          padding: 0 !important;
          height: unset !important;

          textarea {
            color: var(--color-nightshade) !important;
            padding: 5px 15px !important;
            font-size: 12px !important;
            line-height: 17px !important;
            min-height: 60px !important;
            max-height: calc(100vh - 390px) !important;
            margin-top: 5px !important;
          }
        }
      }

      .mat-form-field-subscript-wrapper {
        padding: 0 2px !important;
        margin-top: 23px !important;
      }
    }
  }
}

.input-field.text-area.thick-border.add-kinection {
  max-height: calc(100vh - 340px) !important;
  height: unset !important;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        padding: 5px 0 0 0 !important;
        min-height: 95px !important;
        height: unset !important;

        .mat-form-field-outline-end,
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap {
          border-width: 1px !important;
          height: unset !important;
          min-height: 80px !important;
        }

        .mat-form-field-prefix {
          top: -1px !important;
        }

        .mat-form-field-infix {
          padding: 0 !important;
          height: unset !important;

          textarea {
            color: var(--color-nightshade) !important;
            padding: 5px 15px !important;
            font-size: 12px !important;
            line-height: 17px !important;
            min-height: 60px !important;
            max-height: calc(100vh - 390px) !important;
            margin-top: 5px !important;
          }
        }
      }

      .mat-form-field-subscript-wrapper {
        padding: 0 2px !important;
        margin-top: 23px !important;
      }
    }
  }
}

.input-field.date-picker-input-blue.prefix.center-text {
  padding-bottom: 0 !important;
  padding-top: 18px !important;

  .mat-form-field {
    display: contents !important;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: var(--color-mountain-purple);
    }

    .mat-form-field-flex {
      .mat-form-field-infix {
        input {
          color: var(--color-mountain-purple) !important;
        }
      }
    }

    @media screen and (min-width: 1100px) {
      .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-flex {
          height: 55px !important;
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: 61px !important;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1099px) {
      .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-flex {
          height: 55px !important;
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: 61px !important;
      }
    }

    @media screen and (max-width: 767px) {
      display: contents !important;

      .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-flex {
          height: 55px !important;
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: 61px !important;
      }
    }
  }

  .mat-form-field-appearance-outline:hover {
    .mat-form-field-outline {
      background-color: var(--color-smoke-platinum);
    }

    .mat-form-field-outline-thick {
      color: var(--color-mountain-purple) !important;
    }
  }
}

.product-guest-upgrade-booking-menu {
  .mat-radio-checked {}
}

.upgrade-menu-option {
  .mat-radio-outer-circle {
    background-color: var(--color-pure-white) !important;
  }
}


.adjusted-panel.mat-select-panel {
  min-width: calc(100% + 32px) !important;
  max-width: calc(100% + 32px) !important;
  transform: translateX(25px) !important;
}

.day-fields {
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
    display: none;
  }
}

.simulated-password {
  -webkit-text-security: disc !important;
  font-family: 'password' !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
}

.thin-input-parent {

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 5px 0 15px 0;
    }

    .mat-form-field-outline {
      color: var(--color-dark-nightshade);
    }

    .mat-select-arrow-wrapper {
      transform: translateY(7%) !important;
    }

    .mat-form-field-outline-end,
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap {
      border-width: 1px !important;
    }
  }

  .mat-form-field.mat-form-field-disabled,
  .mat-form-field.mat-form-field-disabled:hover {
    .input-label {
      color: var(--color-silver) !important;
    }

    .material-icons {
      color: var(--color-silver) !important;
    }

    .mat-form-field-subscript-wrapper {
      .mat-form-field-hint-wrapper {
        color: var(--color-silver) !important;

        .mat-hint {
          color: var(--color-silver) !important;
        }
      }
    }

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-outline {

          .mat-form-field-outline-end,
          .mat-form-field-outline-start,
          .mat-form-field-outline-gap {
            border-color: var(--color-disabled);
          }
        }
      }
    }
  }

  .mat-form-field-appearance-outline.thin-input {
    .mat-form-field-flex {
      height: 36px !important;
      margin: 0 !important;

      .mat-form-field-prefix {
        top: -3px !important;

        .mat-icon {
          margin-right: 0 !important;
          font-size: 14px;
        }
      }

      .mat-form-field-infix {
        height: 36px !important;

        .input-label {
          color: var(--color-nightshade);
          font-family: "Brandon Text Medium";
          font-size: 16px !important;
        }

        input {
          color: var(--color-nightshade) !important;
          width: calc(100% - 5px);
          font-size: 16px !important;
          line-height: 22px !important;
          font-family: "Brandon Text" !important;
          padding: 0 12px !important;
          height: 36px !important;
        }

        .mat-select {
          color: var(--color-nightshade) !important;
          width: calc(100% - 20px);
          font-size: 16px !important;
          line-height: 22px !important;
          font-family: "Brandon Text" !important;
          padding: 7px 0px 7px 12px !important;

          .mat-select-trigger {

            .mat-select-arrow-wrapper {
              height: 35px;
              border-left: 1px solid var(--color-nightshade) !important;
              transform: translateY(0%) !important;
              padding-left: 7px;

              .mat-select-arrow {
                margin-top: 0px !important;
              }
            }
          }
        }

        .mat-select-disabled {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              border-left: 1px solid var(--color-disabled) !important;
            }
          }
        }
      }

      .mat-form-field-suffix {
        top: -1px !important;

        .mat-icon {
          width: 24px !important;
          height: 24px !important;
          font-size: 24px !important;
        }
      }
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 8px !important;
    }
  }

  .mat-form-field-appearance-outline.thin-input.input-with-prefix-icon {
    .mat-form-field-flex {
      height: 30px !important;

      .mat-form-field-prefix {
        top: -3px !important;

        .mat-icon {
          margin-right: 0 !important;
          font-size: 14px;
        }
      }

      .mat-form-field-infix {
        height: 34px !important;

        input {
          color: var(--color-nightshade) !important;
          padding: 7px 5px 7px 0px !important;
          width: calc(100% - 28px);
          font-size: 12px !important;
          line-height: 14px !important;
          font-family: "Brandon Text" !important;
        }

        .mat-select {
          padding: 7px 5px 7px 0px !important;
          width: calc(100% - 28px);
          font-size: 12px !important;
          line-height: 14px !important;
          font-family: "Brandon Text" !important;
        }
      }
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 8px !important;
    }
  }

  .mat-select-panel:not(.limited-width) {
    max-width: unset !important;
  }

  .mat-select-panel-wrap {
    .mat-select-panel:not(.limited-width) {
      max-width: unset !important;

      .mat-option {
        color: var(--color-london-gray);
      }

      .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: var(--color-nightshade);
      }

      .mat-option:hover:not(.mat-option-disabled) {
        color: var(--color-nightshade);
      }
    }
  }
}

.date-range-picker {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  font-family: Arial, sans-serif;

  &__input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ccc;

    &--active {
      background-color: #f9f9f9;
    }
  }

  &__input {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    padding: 4px;
    flex: 1;
  }

  &__calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    padding: 8px;
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #f9f9f9;
    }

    &--selected {
      background-color: #007bff;
      color: #fff;
      border-color: transparent;
    }

    &--disabled {
      color: #ccc;
      pointer-events: none;
    }
  }
}

.date-range-picker-outline {
  .mat-form-field-appearance-outline {
    .mat-form-field {
      height: 35px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        padding: 0px 0 0 8px !important;
        height: 35px !important;

        .mat-form-field-infix {
          padding: 0 !important;
          height: auto !important;
          border-top: none !important;

          input {
            color: var(--color-nightshade) !important;
            padding: 0 !important;
            width: calc(100% - 0px) !important;

            @supports (-moz-appearance: none) {
              padding: 0 !important
            }

          }

          .mat-date-range-input {
            transform: translateY(-3px);
          }

          input:disabled {
            color: rgba(0, 0, 0, 0.38) !important;
          }
        }

        .mat-form-field-suffix {
          top: 0 !important;
        }
      }
    }
  }
}