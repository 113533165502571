.preview-header-title {
  height: 34px;
  width: 219px;
  color: var(--color-nightshade);
  font-family: "Brandon Text Medium";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 34px;
}

.booking-label {
  color: var(--color-nightshade);
  font-family: "Brandon Text Bold";
  letter-spacing: 0;
  line-height: 34px;
  padding-bottom: 2rem;
  line-height: 34px;

  @media screen and (min-width : 768px) {
    font-size: 24px;
  }

  @media screen and (max-width : 767px) and (min-width : 450px) {
    font-size: 20px;
  }

  @media screen and (max-width : 449px) and (min-width : 350px) {
    font-size: 18px;
  }

  @media screen and (max-width : 350px) and (min-width : 325px) {
    font-size: 16px;
  }

  @media screen and (max-width : 324px) {
    font-size: 14px;
  }
}

.booking-sub-label {
  color: var(--color-nightshade);
  font-family: "Brandon Text Bold";
  letter-spacing: 0;
  line-height: 34px;
  padding-bottom: 1rem;
  line-height: 34px;


  @media screen and (min-width : 768px) {
    font-size: 18px;
  }

  @media screen and (max-width : 767px) and (min-width : 450px) {
    font-size: 14px;
  }

  @media screen and (max-width : 449px) and (min-width : 350px) {
    font-size: 12px;
  }

  @media screen and (max-width : 350px) and (min-width : 325px) {
    font-size: 12px;
  }
}

.booking-button {
  font-family: "Brandon Text";
  font-size: 14px;
  font-weight: bold !important;
  line-height: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  border-radius: 3px !important;
  margin: 0 5px !important;
  box-shadow: none !important;
  cursor: pointer;
  transform: none !important;

  button[disabled] {
    padding: 15px 0 !important;
  }
}

.booking-info {
  color: var(--color-warning);
  font-family: "Brandon Text Light";
  font-size: 32px;
  font-weight: 300;
  line-height: 46px;
}

.note-line {
  font-size: 16pt;
  font-family: "Brandon Text";
  color: var(--color-nightshade);
  line-height: 26px;
}

.note-text {
  color: var(--color-nightshade);
  font-family: "Brandon Text";
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  text-align: justify;
}

.note-text-small {
  font-family: "Brandon Text";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: block;
  color: var(--color-london-gray);
  transform: translateY(-12px);
}

.form-builder-panel>.card-header {
  padding: 0 !important;
}

.formbuilder-custom_css,
.formio-dialog {
  .form-check {
    padding: 0;
  }
}

.forio-container {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

mat-formio-html,
mat-formio-date {
  margin-bottom: 0 !important;
}

mat-formio-day {
  margin-bottom: 0px !important;
}

mat-formio-button {
  .cta-btn {
    text-transform: none !important;
    letter-spacing: 1px !important;
  }

}

mat-formio-textfield,
mat-formio-number,
mat-formio-email,
app-location,
mat-formio-url,
mat-formio-phonenumber,
mat-formio-currency,
mat-formio-radio,
mat-formio-selectboxes,
mat-formio-signature,
mat-formio-password,
customtime-component {
  margin-top: 25px !important;
  margin-bottom: 5px !important;
}

mat-formio-select {
  margin-top: 8px !important;
  margin-bottom: 5px !important;
}

mat-country {
  padding-top: 21px !important;
  padding-bottom: 0px !important;
  margin-bottom: -10px !important;

  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      .mat-form-field-infix input {
        padding-left: 0 !important;
        margin-left: -3px;
      }
    }
  }
}

mat-formio-radio,
mat-formio-selectboxes {
  min-height: 76px !important;
  margin-top: -5px !important;

  .mat-checkbox {
    padding-left: 0 !important;
  }
}

mat-formio-signature {
  margin-top: -5px !important;
}

mat-radio-group {
  grid-row-gap: 5px !important;
  margin-bottom: 32px;

  mat-radio-button {
    margin: 0 10px 0 0 !important;
  }
}

.child-header {
  color: var(--color-nightshade);
  font-family: "Brandon Text";
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  padding: 0px 0 5px 0 !important;
}

app-booking-renderer {
  p {
    margin: 0 !important;
  }

}

.day-fields {
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
    display: none;
  }


}

.forio-container,
app-booking-renderer {
  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      .mat-form-field-infix .mat-select {
        padding: 16px 14px !important;
      }
    }
  }
}

.formio-label {
  color: var(--color-london-gray);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Brandon Text'
}

.formbuilder-custom_css {
  [type=search] {
    display: none;
  }
}

.custom-reward-description {
  p {
    margin: 0 !important;
  }
}

.custom-mat-error {
  margin-top: -3px !important;
}

app-booking-renderer {

  a,
  a:hover,
  a:visited,
  a:focus {
    text-transform: none !important;
  }
}
