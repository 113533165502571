.mat-slider.mat-slider-min-value, .mat-slider.mat-slider-min-value.cdk-focused {
  min-width: 100%;
  max-width: 100%;


  .mat-slider-track-background {
    background-color: var(--color-silver) !important;
  }

  .mat-slider-track-fill {
    background-color: var(--color-see-green) !important;
  }

  .mat-slider-thumb-label {
    background-color: transparent !important;
    color: var(--color-silver) !important;
    top: -32px !important;
    border-radius: 50% 50% 0 !important;
    transform: rotate(45deg) !important;

    .mat-slider-thumb-label-text {
      color: var(--color-silver) !important;
      opacity: 1 !important;
    }
  }

  .mat-slider-thumb {
    background-color: var(--color-silver) !important;
    border-color: var(--color-silver) !important;
    transform: scale(0.7) !important;
  }

  .mat-slider-focus-ring {
    background-color: var(--color-silver) !important;
  }
}
