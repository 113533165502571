.newsfeed-background {

  @media screen and (min-width : 1024px) {
    background-size: 60vh 60vh, 60vh 60vh, 100% 100%;
    background-position: -10vh 55vh, right 5vh, left top;
    background-repeat: no-repeat;
    background-origin: content-box;
  }

  @media screen and (min-width : 1024px) and (max-width : 1279px) {
    background-size: 20vh 75vh, 20vh 20vh, 100% 100%;
    background-position: -4vh 55vh, right 5vh, left top;
    background-repeat: no-repeat;
    background-origin: content-box;
  }

  @media screen and (min-width : 768px) and (max-width : 1023px) {
    background-size: 20vh 75vh, 20vh 20vh, 100% 100%;
    background-position: -4vh 55vh, right 10vh, left top;
    background-repeat: no-repeat;
    background-origin: content-box;
  }

  @media screen and (max-width : 767px) {
    background-size: 20vh 20vh, 20vh 20vh, 100% 100%;
    background-position: -4vh 75vh, right 15vh, left top;
    background-repeat: no-repeat;
    background-origin: content-box;
  }
}

.grey-background {
  background-image: linear-gradient(to right, var(--color-background) 0%, var(--color-background) 100%) !important;
  background-position: left top;
  background-repeat: no-repeat;
  background-origin: content-box;
}

.hamburger-icon {
  background-image: url('../../../projects/assets/images/icon/hamburger icon/hamburger_highlight_icon.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.hamburger-icon-menu {
  background-image: url('../../../projects/assets/images/icon/hamburger icon/hamburger_icon.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.kinect-icon {
  background-image: url('../../../projects/assets/images/icon/kinect/kinect.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.emoji-icon {
  background-image: url('../../../projects/assets/images/icon/commentactions/emoji_1.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.gif-icon {
  background-image: url('../../../projects/assets/images/icon/commentactions/GIF_comment.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.camera-icon {
  background-image: url('../../../projects/assets/images/icon/commentactions/photo_1.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.reward-icon {
  background-image: url('../../../projects/assets/images/icon/reward/reward.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.reward-icon.primary {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
  background-image: url('../../../projects/assets/images/icon/reward/reward_primary.svg');
}


.reward-icon.disabled {
  background-image: url('../../../projects/assets/images/icon/reward/reward_disabled.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.all-star-icon {
  background-image: url('../../../projects/assets/images/icon/reactions/allstar.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.inspired-icon {
  background-image: url('../../../projects/assets/images/icon/reactions/risingup.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.like-icon {
  background-image: url('../../../projects/assets/images/icon/reactions/greatjob.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.birthday-icon {
  background-image: url(https://static.enjoywishlist.com/assets/static/birthday_white_24_24.svg), linear-gradient(to right, var(--color-mountain-purple), var(--color-mountain-purple));
  background-size: 60% 60%, 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
}

.anniversary-icon {
  background-image: url(https://uuishlist.blob.core.windows.net/cdn/assets/static/workiversary_white_24_24.svg), linear-gradient(to right, var(--color-mountain-purple), var(--color-mountain-purple));
  background-size: 60% 60%, 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
}

.performance-icon {
  background-image: url(https://static.enjoywishlist.com/assets/static/performance_white_24_24.svg), linear-gradient(to right, var(--color-mountain-purple), var(--color-mountain-purple));
  background-size: 60% 60%, 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
  display: inline-block;
}

.gift-icon {
  background-image: url('../../../projects/assets/images/icon/reward/reward-white.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
  display: inline-block;
}

.syncavatar-icon {
  background-image: url('../../../projects/assets/images/icon/other icon/syncavatar.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.school-icon {
  background-image: url('../../../projects/assets/images/icon/other icon/school.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.corevalues-icon {
  background-image: url('../../../projects/assets/images/icon/other icon/corevalues.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.rewardtag-icon {
  background-image: url('../../../projects/assets/images/icon/other icon/sell_secondary.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.mat-icon.business-icon,
.mat-icon.reward-tag-icon {
  color: var(--color-mountain-purple) !important;
}

.google-icon {
  background-image: url('../../../projects/assets/images/icon/other icon/google.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 36px;
  width: 36px;
  display: inline-block;
}

.microsoft-btn__icon {
  background-image: url('../../../projects/assets/images/icon/other icon/microsoft.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 36px;
  display: inline-block;
}

.notification-icon {

  @media screen and (min-width : 768px) {
    margin: 0 !important;

    .mat-badge-content {
      right: -5px !important;
      top: -5px !important;
      width: 18px !important;
      height: 18px !important;
      line-height: 18px !important;
      font-size: 9px !important;
    }
  }

  @media screen and (max-width : 767px) {
    margin: 0 !important;

    .mat-badge-content {
      right: -5px !important;
      top: -5px !important;
      width: 14px !important;
      height: 14px !important;
      line-height: 14px !important;
      font-size: 7px !important;
    }
  }
}

.powered-by-giphy-icon {
  background-image: url('https://uuishlist.blob.core.windows.net/cdn/assets/static/img_powered_by_tenor.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 8px;
  width: 66px;
  display: inline-block;
}

.powered-by-giphy-icon-posted {
  background-image: url('https://uuishlist.blob.core.windows.net/cdn/assets/static/img_powered_by_tenor.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 8px;
  width: 73px;
  display: inline-block;
  top: 2px;
  position: relative;
}

.unselectable-icon {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mat-icon.person-icon {
  color: var(--color-pure-white) !important;
  background: var(--color-nightshade);
  border-radius: 50%;
}

.wishlist-lab-icon {
  display: inline-block;
  height: 17px;
  width: 40px;
  color: var(--color-pure-white);
  font-family: "Brandon Text";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 17px;
  background: var(--color-see-green);
  border-radius: 3px;
  text-transform: lowercase;
  padding: 1px 2px;
  position: relative;
}


.wishlist-lab-icon::before {
  content: '\01F999';
  position: absolute;
  left: 2px;
  font-weight: bold;
}

.wishlist-lab-icon::after {
  content: "labs";
  position: absolute;
  right: 2px;
}

.error_outline_flip {
  transform: rotate(180deg);
}

.error_outline_flip_adjusted {
  font-size: 16px;
  height: fit-content !important;
  width: fit-content !important;
  transform: translate(3px, 2px) rotate(180deg);
}