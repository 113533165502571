@import './mixins';

:root{
  --max-container-width: 2560px;
}

.frosted-background {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  overflow: hidden;
  background: rgba(50, 60, 77, 0.30);
}

.frosted-background-5 {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  overflow: hidden;
}

.stop-event-propagation {
  pointer-events: none !important;
}

.block {
  display: block;
}

.center {
  margin: 0 auto;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.pointer {
  cursor: pointer;

  @include touch {
    cursor: default;
  }
}

.default {
  cursor: default !important;
}

.cursor-unset {
  cursor: unset;
}

.flex {
  display: -webkit-box;
  display: flex;
}

.flex-wrapper {
  flex-wrap: wrap;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;

  div,
  span,
  p,
  img,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    visibility: hidden !important;
  }
}

.visibility-visible {
  visibility: visible !important;

  div,
  span,
  p,
  img,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    visibility: visible !important;
  }
}

a,
a:hover,
a:visited,
a:focus {
  color: var(--color-mountain-purple);
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

.box-shadow-none {
  box-shadow: none !important;
}

.scroll-line {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(91, 114, 130, 0.5);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
}

.scroll-line-new {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(91, 114, 130, 0.3);
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.5);
}

.box-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}


.box-shadow-menu {
  box-shadow: -1px 2px 6px 3px rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
}

.primary-border-radius {
  border-radius: 13px;
}

.width-10 {
  width: 10%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.width-80 {
  width: 80%;
}

.width-90 {
  width: 90%;
}

.width-100 {
  width: 100%;
}


.width-pixel-10 {
  width: 10px;
}

.width-pixel-20 {
  width: 20px;
}

.width-pixel-30 {
  width: 30px;
}

.width-pixel-40 {
  width: 40px;
}

.width-pixel-50 {
  width: 50px;
}

.width-pixel-60 {
  width: 60px;
}

.width-pixel-70 {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}

.width-pixel-80 {
  width: 80px;
}


.width-pixel-90 {
  width: 90px;
}

.width-pixel-100 {
  width: 100px;
}

.width-pixel-300 {
  width: 310px;
}

.margin-left--15 {
  margin-left: -15px !important;
}

.mr-0 {
  margin-right: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mr-1 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.pl-0 {
  padding-left: 00px;
}

.pr-0 {
  padding-right: 00px;
}

.pt-0 {
  padding-top: 00px;
}

.pb-0 {
  padding-bottom: 00px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.mr-1 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pl-1 {
  padding-left: 10px;
}

.pr-1 {
  padding-right: 10px;
}

.pt-1 {
  padding-top: 10px;
}

.pb-1 {
  padding-bottom: 10px;
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-1 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mx-2 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.pl-2 {
  padding-left: 20px;
}

.pr-2 {
  padding-right: 20px;
}

.pt-2 {
  padding-top: 20px;
}

.pb-2 {
  padding-bottom: 20px;
}

.px-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.py-2 {
  padding-bottom: 20px;
  padding-top: 20px;
}


.mr-3 {
  margin-right: 30px;
}

.ml-3 {
  margin-left: 30px;
}

.mt-3 {
  margin-top: 30px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mx-3 {
  margin-left: 30px;
  margin-right: 30px;
}

.my-3 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pl-3 {
  padding-left: 30px;
}

.pr-3 {
  padding-right: 30px;
}

.pt-3 {
  padding-top: 30px;
}

.pb-3 {
  padding-bottom: 30px;
}

.px-3 {
  padding-left: 30px;
  padding-right: 30px;
}

.py-3 {
  padding-bottom: 30px;
  padding-top: 30px;
}


.mr-4 {
  margin-right: 40px;
}

.ml-4 {
  margin-left: 40px;
}

.mt-4 {
  margin-top: 40px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mx-4 {
  margin-left: 40px;
  margin-right: 40px;
}

.my-4 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pl-4 {
  padding-left: 40px;
}

.pr-4 {
  padding-right: 40px;
}

.pt-4 {
  padding-top: 40px;
}

.pb-4 {
  padding-bottom: 40px;
}

.px-4 {
  padding-left: 40px;
  padding-right: 40px;
}

.py-4 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.mr-5 {
  margin-right: 50px;
}

.ml-5 {
  margin-left: 50px;
}

.mt-5 {
  margin-top: 50px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mx-5 {
  margin-left: 50px;
  margin-right: 50px;
}

.my-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pl-5 {
  padding-left: 50px;
}

.pr-5 {
  padding-right: 50px;
}

.pt-5 {
  padding-top: 50px;
}

.pb-5 {
  padding-bottom: 50px;
}

.px-5 {
  padding-left: 50px;
  padding-right: 50px;
}

.px-high {
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 992px) {
  .px-high {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .px-high {
    padding-left: 165px;
    padding-right: 165px;
  }
}

.py-5 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.mr-6 {
  margin-right: 60px;
}

.ml-6 {
  margin-left: 60px;
}

.mt-6 {
  margin-top: 60px;
}

.mb-6 {
  margin-bottom: 60px;
}

.mx-6 {
  margin-left: 60px;
  margin-right: 60px;
}

.my-6 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.pl-6 {
  padding-left: 60px;
}

.pr-6 {
  padding-right: 60px;
}

.pt-6 {
  padding-top: 60px;
}

.pb-6 {
  padding-bottom: 60px;
}

.px-6 {
  padding-left: 60px;
  padding-right: 60px;
}

.py-6 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.mr-7 {
  margin-right: 70px;
}

.ml-7 {
  margin-left: 70px;
}

.mt-7 {
  margin-top: 70px;
}

.mb-7 {
  margin-bottom: 70px;
}

.mx-7 {
  margin-left: 70px;
  margin-right: 70px;
}

.my-7 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.pl-7 {
  padding-left: 70px;
}

.pr-7 {
  padding-right: 70px;
}

.pt-7 {
  padding-top: 70px;
}

.pb-7 {
  padding-bottom: 70px;
}

.px-7 {
  padding-left: 70px;
  padding-right: 70px;
}

.py-7 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.mr-8 {
  margin-right: 80px;
}

.ml-8 {
  margin-left: 80px;
}

.mt-8 {
  margin-top: 80px;
}

.mb-8 {
  margin-bottom: 80px;
}

.mx-8 {
  margin-left: 80px;
  margin-right: 80px;
}

.my-8 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.pl-8 {
  padding-left: 80px;
}

.pr-8 {
  padding-right: 80px;
}

.pt-8 {
  padding-top: 80px;
}

.pb-8 {
  padding-bottom: 80px;
}

.px-8 {
  padding-left: 80px;
  padding-right: 80px;
}

.py-8 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.mr-9 {
  margin-right: 90px;
}

.ml-9 {
  margin-left: 90px;
}

.mt-9 {
  margin-top: 90px;
}

.mb-9 {
  margin-bottom: 90px;
}

.mx-9 {
  margin-left: 90px;
  margin-right: 90px;
}

.my-9 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.pl-9 {
  padding-left: 90px;
}

.pr-9 {
  padding-right: 90px;
}

.pt-9 {
  padding-top: 90px;
}

.pb-9 {
  padding-bottom: 90px;
}

.px-9 {
  padding-left: 90px;
  padding-right: 90px;
}

.py-9 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.mr-10 {
  margin-right: 100px;
}

.ml-10 {
  margin-left: 100px;
}

.mt-10 {
  margin-top: 100px;
}

.mb-10 {
  margin-bottom: 100px;
}

.mx-10 {
  margin-left: 100px;
  margin-right: 100px;
}

.my-10 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.pl-10 {
  padding-left: 100px;
}

.pr-10 {
  padding-right: 100px;
}

.pt-10 {
  padding-top: 100px;
}

.pb-10 {
  padding-bottom: 100px;
}

.px-10 {
  padding-left: 100px;
  padding-right: 100px;
}

.py-10 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.mt-px-5 {
  margin-top: 5px;
}

.lazy-loader {

  .loader-div {
    width: 54px !important;
    height: 63px !important;
    max-height: 60px !important;

    .loader-dimen {
      width: 55px !important;
    }

    .loader-animator-bg {
      width: 55px !important;
      height: 63px !important;
    }

    .logo-loader {
      height: 63px !important;
      width: 55px !important;
    }
  }
}

.border-none {
  border: none !important;
  border-radius: unset !important;
}

.flip-value {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.disable-copy {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.grid {
  display: grid;

  &.place-content-center {
    place-content: center;
  }

  &.gap-20 {
    grid-gap: 20px;
  }

  &.gap-30 {
    grid-gap: 30px;
  }

  &.gap-40 {
    grid-gap: 40px;
  }

  &.gap-50 {
    grid-gap: 50px;
  }

  &.gap-60 {
    grid-gap: 60px;
  }

  &.gap-70 {
    grid-gap: 70px;
  }

  &.gap-80 {
    grid-gap: 80px;
  }

  &.gap-90 {
    grid-gap: 90px;
  }

  &.gap-100 {
    grid-gap: 100px;
  }
}

.flex {

  &.justify-content-left {
    justify-content: flex-start;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-right {
    justify-content: flex-end;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-flex-end {
    align-items: flex-end;
  }

  &.align-items-flex-start {
    align-items: flex-start;
  }
}

.required-asterisk {
  color: var(--color-notification);
}

.ellipsis-single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vertical-scrollbar {
  overflow-x: auto;
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-silver);
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }
}

.mp-page-header {
  padding: 25px 0px 24px;
  height: 129px;
  display: flex;
  box-sizing: border-box;

  .relative-header {
    position: relative;
    border-radius: 10px;
    width: 100%;
  }

  .fixed-header {
    position: fixed;
    top: 75px;
    width: 100%;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px, rgba(0, 0, 0, 0.08) 0px 1px 0px 0px;
  }
}

@media (max-width : 1024px) {
  .mp-page-header {
    display: none;
  }
}

.mp-horizontal-divider {
  width: 100%;
  border-bottom: 1px solid rgba(185, 185, 185, 1);
}

// To Avoid overlapping of walkthrough and preference screen
.backdrop-container{
  z-index: 500 !important;
}

.joyride-step__holder{
  z-index: 501 !important;
}

#joyride-step-Experience,
#joyride-step-Location,
#joyride-step-Search,
#joyride-step-Category,
#joyride-step-Details,
#joyride-step-SendReward,
#joyride-step-Marketplace {
  z-index: 1001 !important;
}


// Best-Buy global styling
.best-buy-additional-information {
  font-family: "Brandon Text";
  font-size: 12px;
  line-height: 21px;
}

.best-buy-features{
  margin: 0;
}

.people-details__contacts {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;

  &-email,
  &-phone {
    display: flex;
    column-gap: 2px;
    align-items: center;
    justify-content: flex-start;

    .mat-icon {
      font-size: 12px;
      width: fit-content;
      height: fit-content;
      margin-right: 0;
      transform: translateY(1px);
      color: var(--color-london-gray);
      line-height: 16px;
      margin-right: 2px !important;
    }

    .limit-email {
      max-width: 135px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      font-family: "Brandon Text";
      color: var(--color-nightshade);
    }
  }
}
