ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  background-color: var(--color-pure-white);
  color: var(--color-nightshade);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  padding: 6px 12px;

  .tooltip-caret.position-top,
  .tooltip-caret.position-left,
  .tooltip-caret.position-right,
  .tooltip-caret.position-bottom {
    display: none;
  }

  .line-chart-tooltip {
    text-align: left;

    .title {
      color: var(--color-nightshade);
      font-family: "Brandon Text Bold";
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .value {
      color: var(--color-nightshade);
      font-family: "Brandon Text";
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  .pie-chart-tooltip {
    text-align: left;

    .title {
      color: var(--color-nightshade);
      font-family: "Brandon Text Bold";
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      font-weight: 900;
    }

    .tooltip-content {
      .sub-title {
        color: var(--color-nightshade);
        font-family: "Brandon Text Bold";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: 12px;
      }

      .value {
        color: var(--color-nightshade);
        font-family: "Brandon Text";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
}
