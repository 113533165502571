.mat-datepicker-popup {
  .mat-calendar-header {
    .mat-calendar-controls {
      .mat-calendar-period-button {
        .mat-button-wrapper {
          .mat-calendar-arrow {
            width: 14px;
            height: 14px;
            margin-top: -3px !important;
            border-left: unset !important;
            border-left: unset !important;
            border-left: unset !important;
            border-right: unset !important;
            border-top: unset !important;
            background: url(/assets/images/arrow-down.svg);
            background-position: center center;
            background-size: 12px 12px;
            background-repeat: no-repeat;
          }
        }
      }

      .mat-calendar-next-button::after,
      .mat-calendar-previous-button::after {
        display: none;
      }

      .mat-calendar-previous-button,
      .mat-calendar-previous-button:focus,
      .mat-calendar-previous-button:hover,
      .mat-calendar-previous-button:active {
        background: url(/assets/images/arrow-left.svg);
        background-position: center center;
        background-size: 12px 12px;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;

        &[disabled],
        &[disabled="true"] {
          background-color: transparent !important;
        }
      }

      .mat-calendar-next-button,
      .mat-calendar-next-button:focus,
      .mat-calendar-next-button:hover,
      .mat-calendar-next-button:active {
        background: url(/assets/images/arrow-right.svg);
        background-position: center center;
        background-size: 12px 12px;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
      }
    }
  }

  .mat-datepicker-content {
    .mat-calendar {
      .mat-calendar-content {
        .mat-calendar-body {
          .mat-calendar-body-cell-content.mat-calendar-body-selected {
            background-color: var(--color-mountain-purple) !important;
          }
        }
      }
    }
  }
}

.input-field.date-time-picker {
  input {
    cursor: pointer;
  }
}

.input-field.date-picker-input.prefix.center-text {
  .mat-form-field-appearance-outline {
    @media screen and (min-width: 1100px) {

      .mat-form-field-prefix,
      .mat-form-field-prefix:focus,
      .mat-form-field-prefix:hover,
      .mat-form-field-prefix:active {
        left: calc(50% - 36px) !important;
        top: 16px !important;
        position: absolute !important;
        margin-left: -18px;
        background: transparent !important;

        @supports(-moz-appearance:none) {
          top: 17px !important;
        }

        .mat-datepicker-toggle {
          color: var(--color-london-gray);
          width: 18px;
          height: 18px;
          font-size: 18px;

          .mat-icon-button {
            color: var(--color-london-gray);
            width: 18px;
            height: 18px;
            font-size: 18px;
            padding: 0 !important;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                color: var(--color-london-gray);
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: calc(50% - 25px) !important;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1099px) {

      .mat-form-field-prefix,
      .mat-form-field-prefix:focus,
      .mat-form-field-prefix:hover,
      .mat-form-field-prefix:active {
        left: calc(50% - 36px) !important;
        top: 16px !important;
        position: absolute !important;
        margin-left: -18px;
        background: transparent !important;

        @supports(-moz-appearance:none) {
          top: 17px !important;
        }

        .mat-datepicker-toggle {
          color: var(--color-london-gray);
          width: 18px;
          height: 18px;
          font-size: 18px;

          .mat-icon-button {
            color: var(--color-london-gray);
            width: 18px;
            height: 18px;
            font-size: 18px;
            padding: 0 !important;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                color: var(--color-london-gray);
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: calc(50% - 25px) !important;
      }
    }

    @media screen and (max-width: 767px) {

      .mat-form-field-prefix,
      .mat-form-field-prefix:focus,
      .mat-form-field-prefix:hover,
      .mat-form-field-prefix:active {
        left: calc(50% - 36px) !important;
        top: 16px !important;
        position: absolute;
        margin-left: -18px;
        background: transparent !important;

        @supports(-moz-appearance:none) {
          top: 17px !important;
        }

        .mat-datepicker-toggle {
          color: var(--color-london-gray);
          width: 18px;
          height: 18px;
          font-size: 18px;

          .mat-icon-button {
            color: var(--color-london-gray);
            width: 18px;
            height: 18px;
            font-size: 18px;
            padding: 0 !important;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                color: var(--color-london-gray);
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: calc(50% - 25px) !important;
      }
    }
  }
}

.input-field.date-time-picker-input.prefix.center-text {
  .mat-form-field-appearance-outline {
    @media screen and (min-width: 1100px) {
      .mat-form-field-prefix {
        left: calc(50% - 60px) !important;
        top: -11px !important;
        margin-left: -26px;

        .mat-datepicker-toggle {
          color: var(--color-london-gray);
          width: 18px;
          height: 18px;
          font-size: 18px;

          .mat-icon-button {
            color: var(--color-london-gray);
            width: 18px;
            height: 18px;
            font-size: 18px;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                color: var(--color-london-gray);
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: calc(50% - 58px) !important;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1099px) {
      .mat-form-field-prefix {
        left: calc(50% - 60px) !important;
        top: -17px !important;
        margin-left: -26px;

        .mat-datepicker-toggle {
          color: var(--color-london-gray);
          width: 18px;
          height: 18px;
          font-size: 18px;

          .mat-icon-button {
            color: var(--color-london-gray);
            width: 18px;
            height: 18px;
            font-size: 18px;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                color: var(--color-london-gray);
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: calc(50% - 58px) !important;
      }
    }

    @media screen and (max-width: 767px) {
      .mat-form-field-prefix {
        left: calc(50% - 60px) !important;
        top: -11px !important;
        margin-left: -26px;

        .mat-datepicker-toggle {
          color: var(--color-london-gray);
          width: 18px;
          height: 18px;
          font-size: 18px;

          .mat-icon-button {
            color: var(--color-london-gray);
            width: 18px;
            height: 18px;
            font-size: 18px;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                color: var(--color-london-gray);
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: calc(50% - 58px) !important;
      }
    }
  }
}


.edit-box {
  .input-field.date-picker-input.prefix.center-text {
    .mat-form-field-appearance-outline {

      .mat-form-field-prefix,
      .mat-form-field-prefix:focus,
      .mat-form-field-prefix:hover,
      .mat-form-field-prefix:active {
        left: calc(50% - 49px) !important;
        top: 16px !important;
        margin-left: 0px;
        position: absolute;

        @supports(-moz-appearance:none) {
          top: 17px !important;
        }
      }
    }
  }
}
