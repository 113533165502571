.right-nav-drawer {
  @media screen and (min-width : 768px) {
    height: 100vh;
    width: 0px;
    position: fixed;
    z-index: 102;
    top: 0;
    right: 0;
    border-radius: 13px 0 0 13px;
    background-color: var(--color-pure-white);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;

    .side-nav {
      .menu-heading {
        padding: 10px;
        height: 50px;
        color: var(--color-pure-white);
        font-family: "Brandon Text";
        font-size: 36px;
        line-height: 50px;
        background-color: var(--color-nightshade);
        border-radius: 13px 0 0 0px;
        position: relative;

        .icon {
          width: 58px;
          height: 70px;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;

          .mat-icon {
            color: var(--color-pure-white);
            cursor: pointer;
            margin: 0 !important;
            font-size: 34px;
            height: 34px;
            width: 34px;
          }
        }

        .heading {
          padding-left: 48px;
        }
      }

      .menu-item {
        color: var(--color-london-gray);
        font-weight: bold;
        padding-top: 15px;

        .item {
          .item-detail {
            height: 34px;
            font-family: "Brandon Text";
            font-size: 24px;
            line-height: 34px;
            padding: 10px 0 10px 60px;
            display: block;
            white-space: nowrap;

            span.menu-selected {
              color: var(--color-nightshade);
              font-weight: bold;
              cursor: unset;
            }

            span:not(.menu-selected):hover {
              color: var(--color-nightshade);
              font-weight: bold;
              cursor: pointer;
            }

            span:not(.menu-selected) {
              cursor: pointer;
              color: var(--color-london-gray);
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width : 767px) {
    height: 100vh;
    width: 0px;
    position: fixed;
    z-index: 102;
    top: 0;
    right: 0;
    border-radius: 13px 0 0 13px;
    background-color: var(--color-pure-white);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    transition: 0.5s;
    -webkit-transition: 0.5s;

    .side-nav {
      .menu-heading {
        padding: 10px;
        height: 50px;
        color: var(--color-pure-white);
        font-family: "Brandon Text";
        font-size: 36px;
        line-height: 50px;
        background-color: var(--color-nightshade);
        border-radius: 13px 0 0 0px;
        position: relative;

        .icon {
          width: 58px;
          height: 70px;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;

          .mat-icon {
            color: var(--color-pure-white);
            cursor: pointer;
            margin: 0 !important;
            font-size: 34px;
            height: 34px;
            width: 34px;
          }
        }

        .heading {
          padding-left: 48px;
        }
      }

      .menu-item {
        color: var(--color-london-gray);
        font-weight: bold;
        padding-top: 10px;

        .item {
          .item-detail {
            height: 34px;
            font-family: "Brandon Text";
            font-size: 24px;
            font-weight: bold;
            line-height: 34px;
            padding: 10px 0 10px 60px;
            display: block;
            white-space: nowrap;

            span.menu-selected {
              color: var(--color-nightshade);
              font-weight: bold;
              cursor: unset;
            }

            span:not(.menu-selected) {
              cursor: pointer;
              color: var(--color-london-gray);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.right-nav-drawer.open-right-side-menu {
  @media screen and (min-width : 768px) {
    width: 375px;
  }

  @media screen and (max-width : 767px) {
    width: 300px;
    max-width: 300px;
  }
}
