.cdk-overlay-dark-backdrop {
  background-color: transparent !important;
  background: transparent !important;
}

.complete-transparent-dialog {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-silver);
    border-radius: 2.5px;
  }

  @media (min-width : 768px) {
    min-height: calc(100vh) !important;
    max-height: calc(100vh) !important;
    height: calc(100vh) !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;
  }

  @media (max-width : 767px) {
    //TODO: remove this commented code after test it on beta.
    // min-height: calc(100vh - 80px) !important;
    // max-height: calc(100vh - 80px) !important;
    // height: calc(100vh - 80px) !important;
    height: 100vh !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;
  }

  .mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 !important;

    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-silver);
      border-radius: 2.5px;
    }
  }
}

.recognition-dialog {
  background: rgba(50, 60, 77, 0.30);
  margin-top: 150px;
}

.people-roster-quick-add-model {
  @media screen and (min-width: 1280px) {
    border-radius: 13px !important;
    min-width: 1225px !important;
    max-width: 1225px !important;
    max-height: 650px !important;
    min-height: 650px !important;

    .mat-dialog-container {
      border-radius: 13px !important;
    }
  }

  @media screen and (max-width: 1279.99px) {
    border-radius: 13px !important;
    min-width: 900px !important;
    max-width: 900px !important;
    max-height: 400px !important;
    min-height: 400px !important;

    .mat-dialog-container {
      border-radius: 13px !important;
    }

    .mat-dialog-container::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 13px;
    }

    .mat-dialog-container::-webkit-scrollbar {
      width: 19px;
      background-color: var(--color-snow);
      border-radius: 13px;
    }

    .mat-dialog-container::-webkit-scrollbar-thumb {
      background-color: var(--color-silver);
    }
  }
}


.wl-backdrop {
  background-color: rgba(0, 0, 0, 0.288);
  background: rgba(0, 0, 0, 0.288);
}

.abandon-chkout-dialog {
  min-width: 39.12vw;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    min-width: 100vw;
  }
}

.my-wl-popups {
  min-width: 78.3vw;
  overflow: auto;

  @media (max-width: 768px) {
    min-width: 100vw !important;
    max-height: 80vh;
  }
}