@font-face {
  font-family: "Brandon Text";
  src: local("Brandon Text"), url("../../assets/fonts/Brandon-Text-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Grotesque";
  src: local("Brandon Grotesque"), url("../../assets/fonts/Brandon-Text-Grotesque.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Text Light";
  src: local("Brandon Text Light"), url("../../assets/fonts/Brandon-Text-Light.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Text Bold";
  src: local("Brandon Text Bold"), url("../../assets/fonts/Brandon-Text-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Text Black";
  src: local("Brandon Text Black"), url("../../assets/fonts/Brandon-Text-Black.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Text Medium";
  src: local("Brandon Text Medium"), url("../../assets/fonts/Brandon-Text-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Text Thin";
  src: local("Brandon Text Thin"), url("../../assets/fonts/Brandon-Text-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Brandon Text Thin Italic";
  src: local("Brandon Text Thin Italic"), url("../../assets/fonts/Brandon-Text-Thin-Italic.otf") format("opentype");
}

@font-face {
  font-family: "Password";
  src: local("Password"), url("../../assets/fonts/Password.ttf") format("opentype");
}

body {
  font-family: "Brandon Text";
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-nightshade);
}

.h1 {
  font-family: "Brandon Text";
  font-size: 70pt;
  font-weight: 500;
  line-height: 98px;
}

h1 {
  line-height: 35px;
}

h2 {
  line-height: 28px;
}

h3,
h4,
h5 {
  line-height: 22px;
}

.h2 {
  font-family: "Brandon Text";
  font-size: 45pt;
  line-height: 63pt;
  font-weight: normal;
}

.h3 {
  font-family: "Brandon Text";
  font-size: 36pt;
  line-height: 50px;
  font-weight: normal;
}

.h4 {
  font-family: "Brandon Text";
  font-size: 32pt;
  font-weight: 300;
  line-height: 46px;
}

.h5 {
  font-family: "Brandon Text";
  font-size: 24pt;
  font-weight: 500;
  line-height: 34px;
}

a {
  font-family: "Brandon Text";
  cursor: pointer;
}

.caption-font {
  font-family: "Brandon Text";
  font-size: 14pt;
  font-weight: 500;
  line-height: 20px;
}

.button-label-font {
  font-family: "Brandon Text";
  font-size: 14pt;
  line-height: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.bold-font,
.bold {
  font-family: "Brandon Text Bold" !important;
}

.italic-font {
  font-style: italic !important;
}

.normal-font {
  font-family: "Brandon Text";
  font-size: 16pt;
  line-height: 22px;
}

.medium-font {
  font-family: "Brandon Text Medium"
}

.small-font {
  font-family: "Brandon Text";
  font-size: 14pt;
  line-height: 20px;
}

.tiny-font {
  font-family: "Brandon Text";
  font-size: 12pt;
  line-height: 17px;
}

div {
  font-family: "Brandon Text";
}

span,
p,
a,
label,
li,
ul,
ol {
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.transform-none {
  text-transform: none !important;
}

.amount {
  font-family:"Brandon Text Bold";
  color: var(--color-success-green);
}