@import '@angular/material/theming';

$fontConfig: (display-4: mat-typography-level(112px, 112px, 400, 'Brandon Text', -0.0134em), display-3: mat-typography-level(56px, 56px, 400, 'Brandon Text', -0.0089em), display-2: mat-typography-level(45px, 48px, 400, 'Brandon Text', 0.0000em), display-1: mat-typography-level(34px, 40px, 400, 'Brandon Text', 0.0074em), headline: mat-typography-level(24px, 32px, 400, 'Brandon Text', 0.0000em), title: mat-typography-level(20px, 32px, 500, 'Brandon Text', 0.0075em), subheading-2: mat-typography-level(16px, 28px, 400, 'Brandon Text', 0.0094em), subheading-1: mat-typography-level(15px, 24px, 500, 'Brandon Text', 0.0067em), body-2: mat-typography-level(14px, 24px, 500, 'Brandon Text', 0.0179em), body-1: mat-typography-level(14px, 20px, 400, 'Brandon Text', 0.0179em), button: mat-typography-level(14px, 14px, 500, 'Brandon Text', 0.0893em), caption: mat-typography-level(12px, 20px, 400, 'Brandon Text', 0.0333em), input: mat-typography-level(inherit, 1.125, 400, 'Brandon Text', 1.5px));

$dark-text: #303c4f;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (base: black, divider: $dark-dividers, dividers: $dark-dividers, disabled: $dark-disabled-text, disabled-button: rgba($dark-text, 0.26), disabled-text: $dark-disabled-text, elevation: black, secondary-text: $dark-accent-text, hint-text: $dark-disabled-text, accent-text: $dark-accent-text, icon: $dark-accent-text, icons: $dark-accent-text, text: $dark-primary-text, slider-min: $dark-primary-text, slider-off: rgba($dark-text, 0.26), slider-off-active: $dark-disabled-text, );

$light-text: #FFFFFF;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (base: $light-text, divider: $light-dividers, dividers: $light-dividers, disabled: $light-disabled-text, disabled-button: rgba($light-text, 0.3), disabled-text: $light-disabled-text, elevation: black, hint-text: $light-disabled-text, secondary-text: $light-accent-text, accent-text: $light-accent-text, icon: $light-text, icons: $light-text, text: $light-text, slider-min: $light-text, slider-off: rgba($light-text, 0.3), slider-off-active: rgba($light-text, 0.3), );

$light-background: #FFFFFF;
$light-bg-darker-5: darken($light-background, 0.05);
$light-bg-darker-10: darken($light-background, 0.1);
$light-bg-darker-20: darken($light-background, 0.2);
$light-bg-darker-30: darken($light-background, 0.3);
$light-bg-lighter-5: lighten($light-background, 0.5);
$dark-bg-tooltip: lighten(#303c4f, 0.2);
$dark-bg-alpha-4: rgba(#303c4f, 0.04);
$dark-bg-alpha-12: rgba(#303c4f, 0.12);

$mat-light-theme-background: (background: $light-background, status-bar: $light-bg-darker-20, app-bar: $light-bg-darker-5, hover: $dark-bg-alpha-4, card: $light-bg-lighter-5, dialog: $light-bg-lighter-5, tooltip: $dark-bg-tooltip, disabled-button: $dark-bg-alpha-12, raised-button: $light-bg-lighter-5, focused-button: $dark-focused, selected-button: $light-bg-darker-20, selected-disabled-button: $light-bg-darker-30, disabled-button-toggle: $light-bg-darker-10, unselected-chip: $light-bg-darker-10, disabled-list-option: $light-bg-darker-10, );

$dark-background: #303c4f;
$dark-bg-lighter-5: lighten($dark-background, 0.05);
$dark-bg-lighter-10: lighten($dark-background, 0.1);
$dark-bg-lighter-20: lighten($dark-background, 0.2);
$dark-bg-lighter-30: lighten($dark-background, 0.3);
$light-bg-alpha-4: rgba(#FFFFFF, 0.04);
$light-bg-alpha-12: rgba(#FFFFFF, 0.12);

$mat-dark-theme-background: (background: $dark-background, status-bar: $dark-bg-lighter-20, app-bar: $dark-bg-lighter-5, hover: $light-bg-alpha-4, card: $dark-bg-lighter-5, dialog: $dark-bg-lighter-5, tooltip: $dark-bg-lighter-20, disabled-button: $light-bg-alpha-12, raised-button: $dark-bg-lighter-5, focused-button: $light-focused, selected-button: $dark-bg-lighter-20, selected-disabled-button: $dark-bg-lighter-30, disabled-button-toggle: $dark-bg-lighter-10, unselected-chip: $dark-bg-lighter-20, disabled-list-option: $dark-bg-lighter-10, );

@include mat-core($fontConfig);

body {
  --primary-color: #314a71;
  --primary-lighter-color: #c1c9d4;
  --primary-darker-color: #1f3254;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (main: #314a71, lighter: #c1c9d4, darker: #1f3254, 200: #314a71, contrast : (main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ));
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #5F7280;
  --accent-lighter-color: #ced5da;
  --accent-darker-color: #405565;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (main: #5F7280, lighter: #ced5da, darker: #405565, 200: #5b7282, contrast : (main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ));
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #EB2138;
  --warn-lighter-color: #e3b3b3;
  --warn-darker-color: #EB2138;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (main: #EB2138, lighter: #e3b3b3, darker: #880000, 200: #EB2138, contrast : (main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ));
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Round', 'Material Icons';
}
