.wl-input-field {
  padding: 10px 0 15px 0;

  .label,
  .input-label {
    font-family: "Brandon Text Medium";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-london-gray);
    margin: 0px 0 10px 0;
    display: block;
  }

  .mat-form-field {
    margin-bottom: 1px;

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: var(--color-mountain-purple) !important
      }
    }

    .mat-form-field-wrapper {
      padding: 0;
      height: inherit;

      .mat-form-field-flex {
        height: inherit !important;
        margin: 0;

        .mat-form-field-outline-end,
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap {
          border-width: 1px !important;
        }

        .mat-form-field-infix {
          padding: 0px 0 0 4px !important;
          box-sizing: border-box;
          height: inherit !important;
          border-top: none !important;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .mat-chip-list:not(.default) {
            .mat-chip-list-wrapper {
              margin: 0 !important;
              cursor: text;

              .mat-chip:not(.default) {
                border-color: var(--color-pure-white) !important;
                background-color: var(--color-pure-white) !important;
                color: var(--color-nightshade) !important;
                font-size: 14px !important;
                letter-spacing: 0 !important;
                line-height: 17px !important;
                font-weight: 500 !important;
                padding: 0px 3px !important;
                min-height: 20px !important;
                height: 36px !important;
                margin: 2px !important;

                .mat-icon {
                  color: inherit !important;
                  opacity: 1 !important;
                  font-size: 14px !important;
                  width: 14px !important;
                  height: 14px !important;
                  top: 4.5px !important;
                  right: 5px !important;
                  display: grid !important;
                  place-content: center !important;
                  position: unset !important;
                  margin: 0 0 0 8px !important;
                  color: var(--color-mountain-purple);

                  &:hover {
                    color: var(--color-mountain-purple-hover);
                  }
                }
              }

              .primary-chip.mat-chip {
                color: var(--color-nightshade) !important;
              }

              .disabled-chip.mat-chip {
                color: rgb(118, 120, 127) !important;
              }
            }
          }

          .mat-select,
          .select,
          select {
            color: var(--color-nightshade);
            padding: 8px 12px !important;
            width: 100% !important;
            box-sizing: border-box;

            &::placeholder {
              color: var(--color-disabled);
              opacity: 1;
              /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: var(--color-disabled);
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: var(--color-disabled);
            }

            .mat-select-trigger {
              color: var(--color-nightshade);

              .mat-select-value {
                color: var(--color-nightshade);
              }

              .mat-select-arrow {
                width: 14px;
                height: 14px;
                margin-top: -3px !important;
                border-left: unset !important;
                border-left: unset !important;
                border-left: unset !important;
                border-right: unset !important;
                border-top: unset !important;
                background-repeat: no-repeat !important;
                background-image: url(../images/arrow-down-primary.png);
                background-position: center center;
                background-repeat: no-repeat;
              }
            }
          }

          input {
            color: var(--color-nightshade);
            padding: 8px 12px !important;
            width: 100% !important;
            box-sizing: border-box;

            &::placeholder {
              color: var(--color-disabled);
              opacity: 1;
              /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: var(--color-disabled);
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: var(--color-disabled);
            }
          }
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          top: 3px !important;
          max-width: fit-content;
        }
      }

      .mat-form-field-subscript-wrapper {
        position: relative;
        margin-top: 8px !important;
        top: 0 !important;
      }
    }
  }

  &.text-area {
    height: 175px;

    .mat-form-field {
      .input-label {
        font-family: "Brandon Text" !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        display: block !important;
        color: var(--color-london-gray);
        position: absolute !important;
        top: -31px !important;
        left: 0px !important;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0.34375em !important;

        .mat-form-field-flex {
          height: 150px !important;

          .mat-form-field-outline-start,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-end {
            height: 125px !important;
          }

          .mat-form-field-infix {
            height: 125px !important;
            padding: 5px !important;
            display: block;

            .cdk-textarea-autosize,
            textarea {
              min-height: 125px !important;
              max-height: 125px !important;
              width: calc(100%) !important;
              padding: 11px !important;
              box-sizing: border-box;
              color: var(--color-nightshade);

              &::-webkit-scrollbar-track {
                background-color: transparent;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
              }

              &::-webkit-scrollbar {
                width: 7px;
                background-color: transparent;
              }

              &::-webkit-scrollbar-thumb {
                background-color: var(--color-silver);
              }
            }
          }
        }

        .mat-form-field-subscript-wrapper {
          margin-top: 12px !important;
        }
      }
    }
  }
}

.mat-select-panel-wrap {
  .mat-select-panel {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: var(--color-silver) transparent;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-silver);
      border-radius: 3px;
    }

    &:not(.limited-width) {
      max-width: unset !important;

      .mat-option {
        color: var(--color-london-gray);

        @include responds(phone) {
          width: 100% !important;
        }
      }

      .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: var(--color-nightshade);
      }

      .mat-option:hover:not(.mat-option-disabled) {
        color: var(--color-nightshade);
      }
    }
  }
}

.custom-input {

  .ng-touched.ng-pristine:not([formGroupName]):not([FormArrayName]):not([ngModelGroup]):not(form):not(.input-control),
  .ng-untouched.ng-pristine:not([formGroupName]):not([FormArrayName]):not([ngModelGroup]):not(form):not(.input-control) {
    border: 1px solid #B9B9B9;
    border-radius: 4px;

    &:disabled {
      border: 1px solid #B9B9B9;
    }
  }

  .ng-touched.ng-dirty:not([formGroupName]):not([FormArrayName]):not([ngModelGroup]):not(form):not(.input-control) {
    border: 1px solid #B9B9B9;
    border-radius: 4px;

    &:disabled {
      border: 1px solid #B9B9B9;
    }
  }

  .ng-valid.ng-dirty:not([formGroupName]):not([FormArrayName]):not([ngModelGroup]):not(form):not(.input-control) {
    border: 1px solid #B9B9B9;
    border-radius: 4px;

    &:disabled {
      border: 1px solid #B9B9B9;
    }
  }

  .ng-invalid.ng-dirty:not([ngModelGroup]):not([formGroupName]):not([formArrayName]):not(form):not(.input-control) {
    border: 1px solid var(--color-notification);
    border-radius: 4px;

    .custom-input-container {
      .prefix-icon {
        border-right: 1px solid var(--color-notification);
      }
    }
  }
}