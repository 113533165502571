.mat-menu-panel {
  overflow: unset !important;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: var(--color-silver) transparent;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-silver);
    border-radius: 2.5px;
  }
}

.what-is-this-message-menu {
  max-width: 410px !important;
  border-radius: 13px !important;
  background-color: var(--color-pure-white) !important;
}

.booking-location-menu {
  max-width: unset !important;
  background-color: var(--color-pure-white) !important;
  margin-top: -50px;
}

.experiences-location-menu,
.live-event-location-menu,
.hotel-location-menu,
.travel-location-menu,
.time-menu {
  max-width: unset !important;
  background-color: var(--color-pure-white) !important;

  .experiences-location-menu-item,
  .live-event-location-menu-item {
    .input-field {
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;
      }
    }
  }
}

.live-event-location-menu {
  margin-top: -50px;
}

.travel-location-menu {
  margin-top: -50px;
  margin-left: -1px;
}

.hotel-location-menu {
  margin-top: -50px;
  margin-left: -3px;
}

.experiences-location-menu {
  border-radius: 13px !important;
}

.live-event-category-menu {
  max-width: unset !important;
}

.time-menu,
.people-roster.mat-menu-item,
.wishlist-contact-support.mat-menu-item,
.what-is-this-message.mat-menu-item,
.experiences-category-menu-item,
.experiences-location-menu-item,
.experiences-upgrade-menu-item,
.live-event-category-menu-item,
.live-event-location-menu-item,
.live-event-upgrade-menu-item,
.gift-card-brand-menu-item,
.product-upgradation-applied-menu-item,
.product-guest-upgrade-booking-menu-item,
.product-total-upgrade-menu-item,
.hotel-location-menu-item,
.travel-location-menu-item,
.tags-list-menu-item,
.county-selection-phone-number-mat-menu-item,
.location-menu-item,
.travel-location-menu-item-1,
.travel-location-menu-item-2,
.travel-location-menu-item-3,
.wishlist-partner-badge-menu-item,
.reward-dropdown-item,
.add-gif-item,
.people-roster-menu-item,
.reward-site-menu-item,
.send-kinection-menu-item,
.address-menu-item,
.share-location-menu-item,
.disabled-ripple-menu-item {
  .mat-menu-ripple {
    display: none;
  }
}

.time-menu {
  margin-top: -98px;
}

.menu-ripple-effect-disabled,
.menu-ripple-effect-disabled:hover {
  .mat-menu-content {
    padding: 0 !important;
    height: fit-content !important;
    width: fit-content !important;
    background-color: inherit;

    .mat-menu-item {
      background-color: var(--color-pure-white);

      .mat-ripple {
        display: none;
      }
    }
  }
}

.product-upgradation-applied-menu,
.product-total-upgrade-menu {
  max-width: 420px !important;
  border-radius: 13px !important;
  background-color: var(--color-pure-white) !important;
}

.product-upgradation-applied-menu {
  position: relative !important;
  bottom: -8px !important;
  left: 1px !important;
  top: 2px;
  height: 241px !important;
  max-width: unset !important;
  border-radius: 13px !important;
  padding: 0px !important;
  margin-top: -1px;
  background: var(--color-pure-white);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
}

.product-guest-upgrade-booking-menu {
  max-width: unset !important;
  min-width: unset !important;
  width: unset !important;
  border-radius: 13px !important;
  background-color: var(--color-pure-white) !important;
  margin-top: -51px;
  margin-bottom: -51px;
}

.number-of-people-mat-menu {
  background-color: var(--color-pure-white) !important;

  .mat-menu-item {
    padding-left: 25px !important;
    padding-right: 135px !important;
  }
}

.mat-menu-max-width-unset {
  max-width: unset !important;
}

.mat-menu-transparent {
  background-color: transparent !important;
}

.mat-menu-min-width-unset {
  min-width: unset !important;
}

.reward-site-mat-menu {
  .mat-menu-content {
    margin-left: -110px;
  }
}

.category-menu-panel {
  border-radius: 13px !important;
}

.experience-upgrade-menu-panel {
  width: 463px;
  height: 297px;
  border-radius: 13px !important;
}

.mat-menu-max-height-unset {
  max-height: unset !important;
}

.upgrade-menu-panel {
  height: 297px;
  position: relative;
  top: -50px;
}

.location-menu-panel {
  height: 315px;
  min-height: 315px;
  max-height: 372px;
  position: relative;
  top: -50px;
}

.share-location-menu-item {
  box-sizing: border-box;
  height: 302px;
  min-height: 302px;
  width: 364px;
  border-radius: 3px;
  background-color: var(--color-pure-white);
  box-shadow: 0 7px 4px 0 rgba(0, 0, 0, 0.11);
}

.address-menu-panel {
  height: 234px;
  position: relative;
  min-width: 376px !important;
  max-width: 462px !important;
  width: 100% !important;
}

.address-menu-panel-zero-opacity {
  opacity: 0 !important;
}

.categories-menu-panel {
  height: 312px;
  position: relative;
  top: -50px;
}

.group-menu-panel {
  box-sizing: border-box;
  box-shadow: none !important;
  height: 312px;
  width: 364px !important;
  max-width: 364px !important;
  border: 1px solid var(--color-nightshade);
  border-radius: 3px !important;
  left: 45px;
  top: -20px;
  background-color: var(--color-pure-white);
  position: relative;

  .content,
  .content:hover {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }
}

.upgrade-menu-live-event {
  position: relative;
  top: -48px;
  left: -1px;
}

.dollar-icon-panel {
  min-width: 351px !important;
  max-width: 351px !important;
  max-height: 208px !important;
  border-radius: 13px !important;
  outline: 0 !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
  min-height: 188px !important;
  position: relative;
  top: -7px;
}

.dollar-icon-panel-inner {
  top: -15px !important;
}

.dollar-icon-panel-view-page {
  position: relative;
  right: 10px;
  top: 20px;
}

.dollar-icon-panel-view {
  right: 0px !important;
  box-shadow: none !important;
}

.dollar-icon-panel-live-event {
  top: -8px !important;
  left: 1px;
}

.send-kinection-menu {
  margin: -20px 7px 0px -40px !important;
}

.wishlist-header-profile-menu-desktop {
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5) !important;
  margin-top: -20px !important;
  margin-right: 8px !important;

  .mat-menu-content:not(:empty) {
    padding-top: 16px;
    padding-bottom: 16px;

    .mat-menu-item:hover:not([disabled]) {
      color: var(--color-nightshade) !important;
    }

    .mat-menu-item {
      padding-left: 25px !important;
      line-height: 36px !important;
      height: 36px !important;
      width: 230px !important;
      color: var(--color-london-gray) !important;

      .mat-icon {
        margin-top: -4px !important;
      }

      .margin-left--15 {
        margin-left: -15px !important;
      }
    }
  }
}

.login-select-panel {
  @media screen and (min-width: 768px) {
    width: 390px !important;
    max-width: 422px !important;

    .mat-option {
      color: var(--color-nightshade) !important;
    }

    .mat-active {
      background-color: #E6EAF3 !important;
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100vw - 120px) !important;
    max-width: 352px !important;

    .mat-option {
      color: var(--color-nightshade) !important;
    }

    .mat-active {
      background-color: #E6EAF3 !important;
    }
  }
}
.experience-product-filter-mat-menu,
.product-filter-mat-menu {
  width: 436px !important;

  .mat-menu-content {
    padding-top: 25px !important;

    .mat-menu-item {
      padding: 5px 0 4px 25px !important;
      height: 34px;
      line-height: 34px;
      color: var(--color-london-gray);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 34px;
    }
  }
}

.experience-product-filter-mat-menu {
  width: fit-content !important;
  min-width: 175px !important;

  .mat-menu-content {
    padding-top: 8px !important;

    .mat-menu-item-heading{
      font-family: "Brandon Text Bold";
    }

    .mat-menu-item {
      padding: 0px 0 0px 25px !important;
      height: 34px;
      line-height: 34px;
      color: var(--color-london-gray);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 34px;
    }
  }
}

.reward-budget-mat-menu {
  box-sizing: border-box;
  margin-top: -25px;
  max-width: unset !important;
  width: 373px;
  height: 262px;
  border: 1px solid var(--color-pond-blue);
  border-radius: 13px !important;
  background-color: var(--color-pure-white);

  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .mat-menu-item {
      box-sizing: border-box;
      height: 262px;
      width: 373px;
      border: 1px solid var(--color-pond-blue);
      border-radius: 13px;
      background-color: var(--color-pure-white);
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.group-help-mat-menu {
  width: unset !important;
  max-width: unset !important;
  box-sizing: unset !important;
  border-radius: 13px !important;
  min-height: 50px !important;

  .mat-menu-content {
    padding: 8px 12px !important;
    margin: 0 !important;

    .mat-menu-item {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.group-budget-mat-menu {
  background-color: transparent;

  .mat-menu-content {
    margin-bottom: -25px;
  }
}

.group-menu-panel {
  .content {
    .container {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 14px;

      .header {
        height: 20px;
        color: var(--color-nightshade);
        font-family: "Brandon Text Bold";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .input-box {
        display: flex;
        align-items: center;

        mat-icon {
          position: absolute;
          z-index: 1;
          font-size: 12px;
          height: 12px;
          width: 12px;
          color: var(--color-london-gray);
          padding-left: 10px;
        }

        .search-input {
          padding: 0 24px;
          height: 20px !important;
          margin: 0;
          box-sizing: border-box !important;
          height: 28px !important;
          border: 1px solid var(--color-london-gray) !important;
          border-radius: 3px !important;
          background-color: var(--color-snow) !important;
          font-size: 12px;
        }
      }
    }

    .search-list {
      display: grid;
      max-height: 228px;
      overflow-y: auto;
      overflow-x: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      scrollbar-width: thin;
      scrollbar-color: var(--color-silver) transparent;

      .no-member {
        text-align: center;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        width: 3px;
        border-radius: 2.5px;
        background-color: var(--color-periwinkle);
      }

      .item {
        padding: 0 15px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 7PX;
        min-height: 52px;
        width: 100%;

        &:hover {
          background-color: var(--color-mountain-purple);
          color: var(--color-pure-white);
          cursor: pointer;

          .name_email {

            .email,
            .name {
              color: var(--color-pure-white);
            }
          }
        }

        .avatar {
          width: 28px;
          height: 28px;
          background: var(--color-nightshade);
          display: grid;
          place-content: center;
          border-radius: 100px;
          transform: translateY(-6px);

          .initals {
            height: 20px;
            width: 26.25px;
            color: var(--color-pure-white);
            font-family: "Brandon Text";
            font-size: 14.25px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
          }

          img {
            width: 28px;
            height: 28px;
            border-radius: 100px;
            object-fit: cover;
          }
        }

        .name_email {
          .name {
            height: 22px;
            color: var(--color-nightshade);
            font-family: "Brandon Text";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
          }

          .email {
            height: 17px;
            width: 193px;
            color: var(--color-london-gray);
            font-family: "Brandon Text";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
      }
    }

    .leader-search-loader {
      height: 50%;
      display: grid;
      place-content: center;
    }
  }
}

.county-selection-phone-number-mat-menu {
  @media screen and (min-width : 768px) {
    .close-menu {
      display: none;

      .mat-icon {
        display: none;
      }
    }
  }

  @media screen and (max-width : 767px) {
    top: 0 !important;
    left: 0 !important;
    position: fixed;
    width: 100vw;
    height: 100vh;

    .close-menu {
      display: block;
      height: 40px;
      position: relative;

      .mat-icon {
        margin: 0 !important;
        position: absolute;
        right: 0px;
        top: 0px;
        color: var(--color-nightshade) !important;
      }
    }
  }
}

.sort-mat-menu {
  border-radius: 3px !important;
  background-color: var(--color-pure-white) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
  margin-top: -24px;
  margin-right: 30px;

  .mat-menu-content {
    padding: 20px 0px !important;

    .mat-menu-item {
      line-height: 30px !important;
      height: 30px !important;
      font-size: 14px !important;
      padding: 0 36px 0 18px !important;
      font-family: "Brandon Text Bold" !important;

      .item {
        font-family: "Brandon Text Bold" !important;
      }
    }
  }
}

.share-experience-location-menu {
  max-width: unset !important;

  .mat-menu-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: -40px;
  }

  .mat-menu-item:hover:not([disabled]) {
    background-color: var(--color-pure-white);
  }
}

.apply-filter-menu {
  @media screen and (min-width : 768px) {
    min-height: 240px !important;
    height: fit-content !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: -o-fit-content !important;
    max-height: 371px !important;
    width: 580px !important;
    max-width: 580px !important;
    min-width: 580px !important;
    border-radius: 3px !important;
    background-color: var(--color-pure-white) !important;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5) !important;
    overflow: hidden !important;
    margin-top: -3px !important;
    margin-right: 57px !important;

    .mat-menu-content {
      padding: 0 !important;
      background-color: var(--color-pure-white);

      .mat-menu-item {
        background-color: var(--color-pure-white);
        height: fit-content !important;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: -o-fit-content !important;
        min-height: 240px !important;
        max-height: 371px !important;
        width: 580px !important;
        max-width: 580px !important;
        min-width: 580px !important;
        padding: 0 !important;

        .mat-menu-ripple {
          display: none;
        }

        .filter-content {
          padding: 25px 33px 16px 35px;

          .search-filtering-input.input-field {
            padding: 0 !important;

            .mat-form-field,
            .mat-form-field.mat-focused {
              .mat-form-field-wrapper {
                padding-bottom: 0 !important;

                .mat-form-field-flex {

                  .mat-form-field-outline {
                    color: var(--color-london-gray) !important;
                  }

                  .mat-form-field-prefix {
                    top: -10px !important;

                    .mat-icon {
                      color: var(--color-london-gray) !important;
                      font-size: 24px !important;
                      height: 24px !important;
                      width: 24px !important;
                    }
                  }

                  .mat-form-field-infix {
                    input {
                      padding: 18px 12px !important;
                    }
                  }
                }
              }
            }
          }

          .filter-tags {
            margin-top: 12px;
            padding-top: 6px;
            border-top: 1px solid var(--color-nightshade);

            &-variation {
              margin-top: 0px;
              padding-top: 6px;
              border-top: none;

              &-fixed-height {
                height: 114px;
                overflow-y: auto;
                -ms-overflow-style: -ms-autohiding-scrollbar;
                scrollbar-width: thin;
                scrollbar-color: var(--color-periwinkle) transparent;

                &::-webkit-scrollbar-track {
                  background-color: transparent;
                }

                &::-webkit-scrollbar {
                  width: 7px;
                  background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: var(--color-periwinkle);
                  border-radius: 13px;
                }
              }
            }

            .title {
              color: var(--color-nightshade);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 20px;
            }

            .value {
              color: var(--color-mountain-purple);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 23px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width : 767px) {
    min-height: 240px !important;
    height: fit-content !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: -o-fit-content !important;
    max-height: 371px !important;
    width: calc(100vw - 30px) !important;
    max-width: 500px !important;
    border-radius: 3px !important;
    background-color: var(--color-pure-white) !important;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5) !important;
    overflow: hidden !important;
    margin-top: -8px !important;
    margin-right: 12px !important;

    .mat-menu-content {
      padding: 0 !important;
      background-color: var(--color-pure-white);

      .mat-menu-item {
        background-color: var(--color-pure-white);
        height: fit-content !important;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: -o-fit-content !important;
        min-height: 240px !important;
        max-height: 371px !important;
        width: calc(100vw - 30px) !important;
        max-width: 500px !important;
        padding: 0 !important;

        .mat-menu-ripple {
          display: none;
        }

        .filter-content {
          padding: 16px 25px;

          .search-filtering-input.input-field {
            padding: 0 !important;

            .mat-form-field,
            .mat-form-field.mat-focused {
              .mat-form-field-wrapper {
                padding-bottom: 0 !important;

                .mat-form-field-flex {

                  .mat-form-field-outline {
                    color: var(--color-london-gray) !important;
                  }

                  .mat-form-field-prefix {
                    top: -10px !important;

                    .mat-icon {
                      color: var(--color-london-gray) !important;
                      font-size: 24px !important;
                      height: 24px !important;
                      width: 24px !important;
                    }
                  }

                  .mat-form-field-infix {
                    input {
                      padding: 18px 12px !important;
                    }
                  }
                }
              }
            }
          }

          .filter-tags {
            margin-top: 12px;
            padding-top: 6px;
            border-top: 1px solid var(--color-nightshade);

            &-variation {
              margin-top: 0px;
              padding-top: 6px;
              border-top: none;

              &-fixed-height {
                height: 114px;
                overflow-y: auto;
                -ms-overflow-style: -ms-autohiding-scrollbar;
                scrollbar-width: thin;
                scrollbar-color: var(--color-periwinkle) transparent;

                &::-webkit-scrollbar-track {
                  background-color: transparent;
                }

                &::-webkit-scrollbar {
                  width: 7px;
                  background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: var(--color-periwinkle);
                  border-radius: 13px;
                }
              }
            }

            .title {
              color: var(--color-nightshade);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 20px;
            }

            .value {
              color: var(--color-mountain-purple);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
}

.react-menu {
  min-height: unset !important;

  .reactions {
    box-sizing: border-box;
    height: auto;
    min-height: 41px;
    min-width: 220px;
    max-width: 255px;
    border: 1px solid var(--color-nightshade);
    border-radius: 20px;
    background-color: var(--color-pure-white) !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: grid;
    align-items: center;
    padding: 0 !important;
    line-height: unset !important;

    &__group {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
      row-gap: 10px;
      padding: 7px;
      position: relative;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .hover-effect {
        width: 22px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 6px;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .custom-emojis {
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .mat-icon {
          width: 14px;
          height: 14px;
          font-size: 14px;
          cursor: pointer;
          color: var(--color-nightshade) !important;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }
  }
}

.hotel-location-menu {
  .location-input {
    @media screen and (min-width : 768px) {
      margin-left: -43px !important;
      width: calc(100% + 54px) !important;
      max-width: calc(100% + 54px) !important;
      padding: 17px 20px 17px 54px !important;
    }

    @media screen and (max-width : 767px) {
      margin-left: -43px !important;
      width: calc(100% + 54px) !important;
      max-width: calc(100% + 54px) !important;
      padding: 17px 20px 17px 54px !important;
    }
  }

  .hotel-location-menu-item,
  hotel-location-menu-item:hover {
    @media screen and (min-width : 768px) {
      margin: -8px 0 !important;
      background: var(--color-pure-white) !important;
      cursor: unset !important;
      width: 430px !important;
      height: 275px !important;
      overflow: hidden;
      padding: 16px;

      .input-field {
        padding: 0 0 0 0 !important;
      }

      .location-list::-webkit-scrollbar-track {
        background-color: transparent;
      }

      .location-list::-webkit-scrollbar {
        width: 21px;
        background-color: var(--color-snow);
      }

      .location-list::-webkit-scrollbar-thumb {
        background-color: var(--color-silver);
      }

      .location-list {
        width: 100%;
        height: 205px;
        overflow-x: hidden;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: thin;
        scrollbar-color: var(--color-silver) transparent;

        .list-item {
          .item {
            display: block;
            padding: 5px 0px 5px 0px;
            text-transform: capitalize;
            cursor: pointer;
          }
        }
      }
    }

    @media screen and (max-width : 767px) {
      margin: -8px 0 !important;
      background: var(--color-pure-white) !important;
      cursor: unset !important;
      width: calc(100vw - 88px) !important;
      height: 275px !important;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
      padding: 16px !important;
      border-radius: 5px !important;

      .input-field {
        padding: 0 0 0 0 !important;
      }

      .location-list::-webkit-scrollbar-track {
        background-color: transparent;
      }

      .location-list::-webkit-scrollbar {
        width: 21px;
        background-color: var(--color-snow);
      }

      .location-list::-webkit-scrollbar-thumb {
        background-color: var(--color-silver);
      }

      .location-list {
        width: 100%;
        height: 205px;
        overflow-x: hidden;
        overflow-y: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: thin;
        scrollbar-color: var(--color-silver) transparent;

        .list-item {
          .item {
            display: block;
            padding: 5px 0px 5px 0px;
            text-transform: capitalize;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.gift-card-filter-menu {
  width: 450px;
  max-width: 450px !important;
}

.unset-menu,
.unset-menu:hover {
  width: unset !important;
  height: unset !important;
  max-width: unset !important;
  max-height: unset !important;
  margin-top: -38px;

  .mat-menu-content {
    padding: 0 !important;
  }
}

.receiver-menu {
  background: var(--color-pure-white);
  border: 0.15px solid var(--color-nightshade);
  box-sizing: border-box;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  padding: 10px 20px;
}

.insight-filter-mat-menu {
  max-height: 50vh !important;
  overflow-y: auto !important;
  border-radius: 3px !important;
  background-color: var(--color-pure-white) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
  margin-top: -24px;
  min-width: 175px !important;

  &-sub-menu {
    margin-top: 0 !important;
  }

  .mat-menu-content {
    padding: 10px 0px !important;

    .mat-menu-item {
      line-height: 30px !important;
      height: 30px !important;
      font-size: 14px !important;
      padding: 0px !important;
      font-family: "Brandon Text Bold" !important;
    }
  }
}

.insight-advance-filter-menu {
  @media screen and (min-width : 768px) {
    min-height: 225px !important;
    max-height: 600px !important;
    height: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: -o-fit-content;
    width: 580px !important;
    max-width: 580px !important;
    min-width: 580px !important;
    border-radius: 3px !important;
    background-color: var(--color-pure-white) !important;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5) !important;
    overflow: hidden !important;
    margin-top: -3px !important;
    margin-right: -52px !important;

    .mat-menu-content {
      padding: 0 !important;
      background-color: var(--color-pure-white);


      .mat-menu-item {
        background-color: var(--color-pure-white);
        min-height: 225px !important;
        height: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: -o-fit-content;
        max-height: 600px !important;
        width: 580px !important;
        max-width: 580px !important;
        min-width: 580px !important;
        padding: 0 !important;

        .mat-menu-ripple {
          display: none;
        }

        .filter-content {
          padding: 25px 33px 16px 35px;

          .search-filtering-input.input-field {
            padding: 0 !important;

            .mat-form-field,
            .mat-form-field.mat-focused {
              .mat-form-field-wrapper {
                padding-bottom: 0 !important;

                .mat-form-field-flex {

                  .mat-form-field-outline {
                    color: var(--color-london-gray) !important;
                  }

                  .mat-form-field-prefix {
                    top: -10px !important;

                    .mat-icon {
                      color: var(--color-london-gray) !important;
                      font-size: 24px !important;
                      height: 24px !important;
                      width: 24px !important;
                    }
                  }

                  .mat-form-field-infix {
                    input {
                      padding: 18px 12px !important;
                    }
                  }
                }
              }
            }
          }

          .selected-tag-list {
            padding: 0 0 10px 0px;
          }

          .recognition-tag {
            height: 114px;
            overflow-y: auto;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            scrollbar-width: thin;
            scrollbar-color: var(--color-periwinkle) transparent;

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            &::-webkit-scrollbar {
              width: 7px;
              background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--color-periwinkle);
              border-radius: 13px;
            }

            .title {
              color: var(--color-nightshade);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 20px;
            }

            .value {
              color: var(--color-mountain-purple);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 23px;
            }
          }
        }

        .filter-button {
          padding: 8px 33px 8px 35px;

          button {
            width: 100%;
            height: 36px;
            padding: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width : 767px) {
    height: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: -o-fit-content;
    min-height: 219px !important;
    max-height: 400px !important;
    width: calc(100vw - 45px) !important;
    max-width: 500px !important;
    border-radius: 3px !important;
    background-color: var(--color-pure-white) !important;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5) !important;
    overflow: hidden !important;
    margin-top: -3px !important;
    margin-right: -11px !important;

    .mat-menu-content {
      padding: 0 !important;
      background-color: var(--color-pure-white);


      .mat-menu-item {
        background-color: var(--color-pure-white);
        height: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: -o-fit-content;
        min-height: 219px !important;
        max-height: 400px !important;
        width: calc(100vw - 45px) !important;
        max-width: 500px !important;
        padding: 0 !important;

        .mat-menu-ripple {
          display: none;
        }

        .filter-content {
          padding: 25px 33px 16px 35px;

          .search-filtering-input.input-field {
            padding: 0 !important;

            .mat-form-field {

              .mat-form-field-wrapper {
                padding-bottom: 0 !important;

                .mat-form-field-flex {
                  .mat-form-field-prefix {
                    .mat-icon {
                      color: var(--color-mountain-purple) !important;
                    }
                  }

                  .mat-form-field-infix {
                    input {
                      padding: 18px 12px !important;
                    }
                  }
                }
              }
            }
          }

          .selected-tag-list {
            padding: 0 0 10px 0px;
          }

          .recognition-tag {
            height: 114px;
            overflow-y: auto;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            scrollbar-width: thin;
            scrollbar-color: var(--color-periwinkle) transparent;

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            &::-webkit-scrollbar {
              width: 7px;
              background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--color-periwinkle);
              border-radius: 13px;
            }

            .title {
              color: var(--color-nightshade);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 20px;
            }

            .value {
              color: var(--color-mountain-purple);
              font-family: "Brandon Text Bold";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
}

.mat-menu-fit-content {
  margin: 0;
  padding: 0;
  min-height: unset !important;

  .mat-menu-content {
    padding: 0 !important;
  }

  &.reward-bulk-mat-menu {
    margin: -35px 0 0 0;

    .mat-menu-item {
      width: 140px;
      margin: 0 !important;
    }
  }
}

.wallet-category-mat-menu {
  box-sizing: border-box;
  border-radius: 13px !important;
  background-color: var(--color-pure-white) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
  margin-top: 5px !important;
  min-width: 363px !important;

  .mat-menu-content {
    padding-right: 13px !important;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 5px !important;
    min-width: 345px !important;
  }
}

.wallet-price-mat-menu {
  box-sizing: border-box;
  max-width: unset !important;
  padding: 0 !important;
  border-radius: 13px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
  padding-right: 13px !important;
  padding-left: 10px !important;
  width: 337px !important;
  margin-top: 20px !important;

  .mat-menu-content {
    padding-bottom: 17px !important;
  }
}

.transparent-mat-menu {
  background-color: transparent !important;
  box-shadow: none;
}

.mat-menu-panel {
  &.mat-menu-max-width-unset {
    max-width: unset !important;
  }

  &.mat-menu-with-border {
    border: 0.5px solid var(--color-nightshade);
    border-radius: 10px;

    .mat-menu-item {
      padding: 0 20px;
    }
  }

  &.wallet-order-filter-menu {
    .mat-menu-item {

      .component {
        padding-left: 0 !important;

        .input-field.date-range-picker {
          .selected-date-range {
            .placeholder {
              font-size: inherit !important;
              font-weight: inherit !important;
              font-family: inherit !important;
              color: inherit !important;
            }
          }
        }
      }
    }
  }
}

.prediction-container-menu-list {
  margin-top: -10px;
  overflow-x: auto;
  max-height: 250px;
  max-width: 100%;
  width: 100%;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 20px;
    background-color: var(--color-silver);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-silver);
  }

  .list-item {
    display: block;
    padding: 5px 0px 5px 12px;
    text-transform: capitalize;
    cursor: pointer;
    border-bottom: 1px solid var(--color-periwinkle);
    color: var(--color-london-gray) !important;
    font-family: "Brandon Text" !important;
    font-size: 16px !important;
    line-height: 22px !important;
    padding: 12px 20px;
    cursor: pointer;
    min-width: 100%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;

    &:hover {
      background-color: var(--color-smoke-platinum);
    }
  }
}

.mat-menu-panel {

  .mat-menu-item {
    color: var(--color-nightshade);

    .wl-input-field {
      padding: 0;

      .mat-form-field {
        .mat-form-field-wrapper {
          margin: 0;
        }

        .mat-form-field-subscript-wrapper {
          margin: 0 !important;
        }
      }
    }
  }

  &.new-common-mat-menu {
    border-radius: 4px !important;
    box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 10%) !important;
    min-height: unset !important;

    .mat-menu-content {
      .mat-menu-item {
        line-height: 36px !important;
        height: 36px;
        font-size: 16px;
        color: var(--color-nightshade);

        &[disabled],
        &[disabled="true"] {
          color: rgba(48, 60, 79, 0.38);
        }


      }

      .mat-menu-item:hover,
      .mat-menu-item.cdk-program-focused,
      .mat-menu-item.cdk-keyboard-focused,
      .mat-menu-item-highlighted {
        &:not([disabled]) {
          background: #E6EAF3 !important;
        }
      }
    }
  }
}

.currency-menu{
  margin-top: 10px;
}

.no-padding-content{
  .mat-menu-content{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
}