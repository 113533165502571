.emoji-mart {
  @media screen and (min-width : 768px) {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
    width: 355px !important;

    .emoji-mart-search {
      display: block !important;

      input,
      .emoji-mart-search-1 {
        padding-left: 14px !important;
        border: 1px solid var(--color-london-gray) !important;
        border-radius: 3px !important;
        height: 36px !important;
        font-size: 12px !important;
      }

      button.emoji-mart-search-icon {
        margin: 0 !important;
        top: 10px !important;
        right: 15px !important;
        height: 13px;
        padding: 0 5px !important;
        background-color: var(--color-pure-white) !important;
      }
    }

    .emoji-mart-scroll {
      margin: 0px !important;
      padding: 0 !important;
      overflow-x: hidden;

      .emoji-mart-category {
        .emoji-mart-category-label {
          background: var(--color-pure-white) !important;
          font-size: 14px;
          height: 24px;
        }
      }

      button.emoji-mart-emoji {
        margin: 0 !important;
        outline: none !important;
        font-family: "Brandon Text";
        padding: 8px !important;
        cursor: pointer !important;

        span {
          font-size: 24px !important;
          width: 24px !important;
          height: 24px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          cursor: pointer !important;
        }
      }

      button.emoji-mart-emoji:hover:before {
        background-color: rgba(105, 115, 243, 0.5) !important;
        top: 5px !important;
        left: 5px !important;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        right: 5px;
        bottom: 5px;
      }
    }

    .emoji-mart-scroll::-webkit-scrollbar-track {
      background-color: transparent;
      margin-top: 26px;
    }

    .emoji-mart-scroll::-webkit-scrollbar {
      width: 2.5px;
      background-color: transparent;
    }

    .emoji-mart-scroll::-webkit-scrollbar-thumb {
      background-color: var(--color-periwinkle);
      border-radius: 2.5px;
    }

    .emoji-mart-bar {

      .emoji-mart-anchor {
        color: var(--color-silver) !important;
        cursor: pointer !important;
      }

      .emoji-mart-anchor:hover,
      .emoji-mart-anchor:focus {
        color: var(--color-london-gray) !important;
      }

      .emoji-mart-anchor-selected,
      .emoji-mart-anchor-selected:hover,
      .emoji-mart-anchor-selected:focus {
        color: var(--color-nightshade) !important;
        cursor: default !important;

        .emoji-mart-anchor-bar {
          background-color: var(--color-nightshade) !important;
          border-radius: 4px 4px 0 0 !important;
        }
      }

      .emoji-mart-preview {
        height: 45px !important;

        .emoji-mart-preview-emoji {
          button.emoji-mart-emoji {
            margin: 0 !important;
            outline: none !important;
            font-family: "Brandon Text";
            padding: 8px !important;

            span {
              font-size: 24px !important;
              width: 24px !important;
              height: 24px !important;
              display: flex !important;
              justify-content: center !important;
              align-items: center !important;
            }
          }

          button.emoji-mart-emoji:hover:before {
            background-color: rgba(105, 115, 243, 0.5) !important;
          }
        }

        .emoji-mart-title-label {
          color: var(--color-silver) !important;
          font-size: 14px !important;
        }
      }
    }
  }

  @media screen and (min-width : 550px) and (max-width : 767px) {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
    width: 355px !important;

    .emoji-mart-search {
      display: none !important;

      input,
      .emoji-mart-search-1 {
        padding-left: 14px !important;
        border: 1px solid var(--color-london-gray) !important;
        border-radius: 19px !important;
        height: 36px !important;
        font-size: 12px !important;
      }

      button.emoji-mart-search-icon {
        margin: 0 !important;
        top: 10px !important;
        right: 15px !important;
        height: 13px;
        padding: 0 5px !important;
      }
    }

    .emoji-mart-scroll {
      margin-right: 7px !important;
      margin-bottom: 7px !important;

      .emoji-mart-category {
        .emoji-mart-category-label {
          background: var(--color-pure-white) !important;
          font-size: 14px;
          height: 24px;
        }
      }

      button.emoji-mart-emoji {
        margin: 0 !important;
        outline: none !important;
        font-family: "Brandon Text";
        padding: 8px !important;
        cursor: pointer !important;

        span {
          font-size: 24px !important;
          width: 24px !important;
          height: 24px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          cursor: pointer !important;
        }
      }

      button.emoji-mart-emoji:hover:before {
        background-color: rgba(105, 115, 243, 0.5) !important;
        top: 5px !important;
        left: 5px !important;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        right: 5px;
        bottom: 5px;
      }
    }

    .emoji-mart-scroll::-webkit-scrollbar-track {
      background-color: transparent;
      margin-top: 26px;
    }

    .emoji-mart-scroll::-webkit-scrollbar {
      width: 2.5px;
      background-color: transparent;
    }

    .emoji-mart-scroll::-webkit-scrollbar-thumb {
      background-color: var(--color-periwinkle);
      border-radius: 2.5px;
    }

    .emoji-mart-bar {

      .emoji-mart-anchor {
        color: var(--color-silver) !important;
        cursor: pointer !important;
      }

      .emoji-mart-anchor:hover,
      .emoji-mart-anchor:focus {
        color: var(--color-london-gray) !important;
      }

      .emoji-mart-anchor-selected,
      .emoji-mart-anchor-selected:hover,
      .emoji-mart-anchor-selected:focus {
        color: var(--color-nightshade) !important;
        cursor: default !important;

        .emoji-mart-anchor-bar {
          background-color: var(--color-nightshade) !important;
          border-radius: 4px 4px 0 0 !important;
        }
      }

      .emoji-mart-preview {
        height: 45px !important;

        .emoji-mart-preview-emoji {
          button.emoji-mart-emoji {
            margin: 0 !important;
            outline: none !important;
            font-family: "Brandon Text";
            padding: 8px !important;

            span {
              font-size: 24px !important;
              width: 24px !important;
              height: 24px !important;
              display: flex !important;
              justify-content: center !important;
              align-items: center !important;
            }
          }

          button.emoji-mart-emoji:hover:before {
            background-color: rgba(105, 115, 243, 0.5) !important;
          }
        }

        .emoji-mart-title-label {
          color: var(--color-silver) !important;
          font-size: 14px !important;
        }
      }
    }
  }

  @media screen and (max-width : 549px) {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
    width: 250px !important;

    .emoji-mart-search {
      display: none !important;

      input,
      .emoji-mart-search-1 {
        padding-left: 14px !important;
        border: 1px solid var(--color-london-gray) !important;
        border-radius: 19px !important;
        height: 36px !important;
        font-size: 12px !important;
      }

      button.emoji-mart-search-icon {
        margin: 0 !important;
        top: 7px !important;
        right: 15px !important;
      }
    }

    .emoji-mart-scroll {
      margin: 0px !important;
      padding: 0 !important;
      overflow-x: hidden;

      .emoji-mart-category {
        .emoji-mart-category-label {
          background: var(--color-pure-white) !important;
          font-size: 14px;
          height: 24px;
        }
      }

      button.emoji-mart-emoji {
        margin: 0 !important;
        outline: none !important;
        font-family: "Brandon Text";
        padding: 8px !important;
        cursor: pointer !important;

        span {
          font-size: 24px !important;
          width: 24px !important;
          height: 24px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          cursor: pointer !important;
        }
      }

      button.emoji-mart-emoji:hover:before {
        background-color: rgba(105, 115, 243, 0.5) !important;
        top: 5px !important;
        left: 5px !important;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        right: 5px;
        bottom: 5px;
      }
    }

    .emoji-mart-scroll::-webkit-scrollbar-track {
      background-color: transparent;
      margin-top: 26px;
    }

    .emoji-mart-scroll::-webkit-scrollbar {
      width: 2.5px;
      background-color: transparent;
    }

    .emoji-mart-scroll::-webkit-scrollbar-thumb {
      background-color: var(--color-periwinkle);
      border-radius: 2.5px;
    }

    .emoji-mart-bar {

      .emoji-mart-anchor {
        color: var(--color-silver) !important;
        cursor: pointer !important;
      }

      .emoji-mart-anchor:hover,
      .emoji-mart-anchor:focus {
        color: var(--color-london-gray) !important;
      }

      .emoji-mart-anchor-selected,
      .emoji-mart-anchor-selected:hover,
      .emoji-mart-anchor-selected:focus {
        color: var(--color-nightshade) !important;
        cursor: default !important;

        .emoji-mart-anchor-bar {
          background-color: var(--color-nightshade) !important;
          border-radius: 4px 4px 0 0 !important;
        }
      }

      .emoji-mart-preview {
        height: 45px !important;

        .emoji-mart-preview-emoji {
          button.emoji-mart-emoji {
            margin: 0 !important;
            outline: none !important;
            font-family: "Brandon Text";
            padding: 8px !important;

            span {
              font-size: 24px !important;
              width: 24px !important;
              height: 24px !important;
              display: flex !important;
              justify-content: center !important;
              align-items: center !important;
            }
          }

          button.emoji-mart-emoji:hover:before {
            background-color: rgba(105, 115, 243, 0.5) !important;
          }
        }

        .emoji-mart-title-label {
          color: var(--color-silver) !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

.emojis-mat-menu {
  margin-right: -60px !important;

  &.emojis-mat-menu-1 {
    margin-right: -100px !important;
    margin-top: -36px;
  }

  &.emojis-mat-menu-0 {
    margin-right: 0px !important;
  }

  .mat-menu-content {
    background-color: transparent !important;

    .add-emojis-menu-item,
    .add-emojis-menu-item:hover {

      @media screen and (min-width : 768px) {
        height: 360px !important;
        width: 355px !important;
        white-space: unset !important;
        border: none !important;
        border-radius: 15px !important;
        background-color: var(--color-pure-white);
        cursor: default !important;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
        padding: 0 !important;
      }

      @media screen and (min-width : 550px) and (max-width : 767px) {
        height: 325px !important;
        width: 355px !important;
        white-space: unset !important;
        border: none !important;
        border-radius: 15px !important;
        background-color: var(--color-pure-white);
        cursor: default !important;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
        padding: 0 !important;
      }

      @media screen and (min-width : 350px) and (max-width : 449px) {
        height: 319px !important;
        width: 250px !important;
        min-width: 250px;
        max-width: 355px;
        white-space: unset !important;
        border: none !important;
        border-radius: 15px !important;
        background-color: var(--color-pure-white);
        cursor: default !important;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
        padding: 0 !important;
        overflow-x: auto !important;
      }

      @media screen and (max-width : 349px) {
        height: 319px !important;
        width: 250px !important;
        white-space: unset !important;
        border: none !important;
        border-radius: 15px !important;
        background-color: var(--color-pure-white);
        cursor: default !important;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5) !important;
        padding: 0 !important;
        overflow-x: auto !important;

      }
    }
  }
}
