.owl-dt-popup {
  @media screen and (max-width : 420px) {
    right: 0 !important;
  }

  .owl-dt-container.owl-dt-popup-container {
    .owl-dt-control-button {
      padding: 0 !important;
    }

    .owl-dt-control,
    .owl-dt-control:focus,
    .owl-dt-control:active {
      font-size: 14px !important;
      height: 36px !important;
      padding: 0 !important;
      background: var(--color-pure-white) !important;
    }

    .owl-dt-calendar-table {
      .owl-dt-calendar-header {
        color: var(--color-nightshade) !important;
        font-size: 16px;
      }

      .owl-dt-calendar-body {
        tr {
          border-radius: 5px;

          .owl-dt-calendar-cell-active {
            .owl-dt-calendar-cell-selected {
              background-color: var(--color-mountain-purple) !important;
            }
          }

          .owl-dt-calendar-cell-range-from,
          .owl-dt-calendar-cell-range-to {
            .owl-dt-calendar-cell-selected {
              background-color: var(--color-mountain-purple) !important;
            }
          }

          .owl-dt-calendar-cell-in-range {
            background: rgba(105, 115, 243, 0.2);
          }

          .owl-dt-calendar-cell-in-range:first-child {
            border-radius: 5px 0 0 5px;
          }

          .owl-dt-calendar-cell-in-range:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }

    .owl-dt-timer {
      height: unset !important;

      .owl-dt-timer-box {
        .owl-dt-control-button {
          display: none;
        }

        .owl-dt-timer-content {
          input.owl-dt-timer-input {
            width: 3em !important;
            border: 1px solid var(--color-nightshade) !important;
            color: var(--color-nightshade) !important;
          }
        }

        .owl-dt-timer-divider {
          height: 44px;
          top: -3px;
          color: var(--color-nightshade) !important;
        }

        .owl-dt-timer-divider:after,
        .owl-dt-timer-divider:before {
          background-color: var(--color-nightshade) !important;
        }
      }

      .owl-dt-timer-divider:after,
      .owl-dt-timer-divider:before {
        width: 0.25em;
        height: 0.25em;
      }
    }

    .owl-dt-container-buttons {
      padding: 20px 0 40px 0;

      .owl-dt-container-control-button:first-child {
        color: var(--color-london-gray) !important;
      }

      .owl-dt-container-control-button:last-child {
        color: var(--color-mountain-purple) !important;
      }
    }

    .owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
      border: 1px solid currentColor;
      border-radius: 2px;
      -webkit-transition: background 0.2s ease;
      transition: background 0.2s ease;
      height: 32px;
      padding: 0;
    }

    .owl-dt-timer-hour12:hover .owl-dt-timer-hour12-box {
      .owl-dt-control-button-content {
        background: var(--color-mountain-purple) !important;
        color: var(--color-pure-white);
      }
    }

    .owl-dt-container-info {
      padding: 0px 0.5em !important;

      .owl-dt-container-range {
        .owl-dt-container-range-content {
          padding: 10px 0 !important;

          .owl-dt-container-info-label {
            font-size: 18px;
            color: var(--color-nightshade) !important;
          }

          .owl-dt-container-info-value {
            font-size: 18px;
            color: var(--color-mountain-purple) !important;
          }
        }
      }
    }
  }
}

.cdk-overlay-container {
  .ngx-mat-drp-overlay-backdrop {
    background-color: transparent !important;
    background: transparent !important;
  }

  .ngx-mat-drp-overlay-2 {
    border-radius: 13px !important;
    background-color: var(--color-pure-white);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: unset;
  }

  .ngx-mat-drp-overlay,
  .ngx-mat-drp-overlay-2 {
    @media screen and (min-width : 768px) {
      border-radius: 14px;
      padding: 0px;

      .ngx-mat-drp-calendar-container {
        min-height: 325px !important;
        max-height: 325px !important;
        border-radius: 14px !important;
        justify-content: space-around;
        min-width: 550px !important;
        width: 600px !important;
        position: relative;

        &.ngx-mat-drp-calendar-container-without-preset {
          min-width: 440px !important;
          width: 440px !important;

          .ngx-mat-drp-calendar-item {
            width: 198px;
            min-width: 198px;
          }

          .ngx-mat-drp-controls {
            right: 5px;
            bottom: 5px;
          }
        }

        .ngx-mat-drp-calendar-item {
          width: 165px;
          padding: 30px 10px 30px 10px !important;
          min-width: 165px;

          &.presets-item {
            border-left: 1px solid var(--color-smoke-platinum);
            padding: 0 !important;
            max-height: 268px;
            overflow: auto;
            margin: 5px;

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            &::-webkit-scrollbar {
              width: 2px;
              background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
              background-color: var(--color-periwinkle);
              border-radius: 4px;
            }
          }

          .ngx-mat-drp-date-label {
            background: transparent !important;
            margin: 0 0px;

            label {
              font-family: 'Brandon Text' !important;
              font-size: 14px !important;
              text-transform: capitalize;
              letter-spacing: 0.09em;
            }

            .ngx-mat-drp-selected-date-label {
              font-family: 'Brandon Text' !important;
              font-size: 14px !important;
              color: rgba(0, 0, 0, .8) !important;
            }
          }

          .mat-calendar {
            .mat-calendar-header {
              .mat-calendar-controls {
                .mat-calendar-arrow {
                  width: 14px;
                  height: 14px;
                  margin-top: -3px !important;
                  border-left: unset !important;
                  border-left: unset !important;
                  border-left: unset !important;
                  border-right: unset !important;
                  border-top: unset !important;
                  background: url(/assets/images/arrow-down.svg);
                  background-position: center center;
                  background-size: 10px 10px;
                  background-repeat: no-repeat;
                  fill: var(--color-nightshade);
                }

                .mat-calendar-period-button {
                  color: var(--color-nightshade) !important;
                  padding: 0 !important;
                  height: 30px;
                }

                .mat-calendar-previous-button,
                .mat-calendar-next-button {
                  color: var(--color-nightshade) !important;
                  padding: 0 !important;
                  margin: 0 !important;
                  width: 30px !important;
                  height: 30px !important;
                }

                .mat-calendar-next-button::after,
                .mat-calendar-previous-button::after {
                  display: none;
                }

                .mat-calendar-previous-button {
                  background-color: transparent !important;
                  background: url(/assets/images/arrow-left.svg);
                  background-size: 12px 12px;
                  background-repeat: no-repeat;
                  background-position: center center;
                }

                .mat-calendar-next-button {
                  background-color: transparent !important;
                  background: url(/assets/images/arrow-right.svg);
                  background-size: 12px 12px;
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              }
            }

            .mat-calendar-content {
              .mat-calendar-table {
                .mat-calendar-body {
                  .mat-calendar-body-cell-content.mat-calendar-body-selected {
                    background-color: var(--color-mountain-purple) !important;
                    color: var(--color-pure-white);
                  }
                }
              }
            }
          }

          .ngx-mat-drp-menu {
            padding: 1em;
            box-sizing: border-box;

            .ngx-mat-drp-presets-list {
              li {
                color: var(--color-nightshade);
                background: transparent;
                border-radius: 4px;
              }

              li:hover {
                background-color: var(--color-mountain-purple);
                color: var(--color-pure-white);
              }

              li.active {
                background-color: var(--color-mountain-purple);
                color: var(--color-pure-white);
                cursor: default;
              }
            }
          }
        }

        .ngx-mat-drp-controls {
          right: 16px;
          bottom: 16px;
        }


      }
    }

    @media screen and (max-width : 767px) {
      left: 0 !important;
      border-radius: 5px !important;
      padding: 0px;
      width: 100vw;
      top: 0 !important;
      height: 100vh;
      right: 0 !important;
      transform: translate(0px, 0px) !important;


      .ngx-mat-drp-calendar-container {
        justify-content: space-around;
        width: 100vw !important;
        min-width: 100vw !important;
        max-width: 100vw !important;
        min-height: 100vh !important;
        max-height: 100vh !important;
        height: 100vh !important;
        display: block !important;
        overflow-x: hidden;
        overflow-y: auto;


        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 2.5px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-periwinkle);
          border-radius: 2.5px;
        }

        .ngx-mat-drp-calendar-item {
          flex-basis: 100%;
          display: block;
          padding: 4px 15px;
          font-family: "Brandon Text" !important;

          .ngx-mat-drp-date-label {
            background: transparent !important;
            margin: 0 5px;

            label {
              font-family: 'Brandon Text' !important;
              font-size: 12px !important;
              text-transform: capitalize;
              letter-spacing: 0.09em;
            }

            .ngx-mat-drp-selected-date-label {
              font-family: 'Brandon Text' !important;
              font-size: 12px !important;
              color: rgba(0, 0, 0, .8) !important;
            }
          }

          .mat-calendar {
            .mat-calendar-header {
              padding: 4px 0px 4px 8px !important;

              .mat-calendar-controls {
                margin: 0 !important;

                .mat-calendar-arrow {
                  width: 14px;
                  height: 14px;
                  margin-top: -3px !important;
                  border-left: unset !important;
                  border-left: unset !important;
                  border-left: unset !important;
                  border-right: unset !important;
                  border-top: unset !important;
                  background: url(/assets/images/arrow-down.svg);
                  background-position: center center;
                  background-size: 10px 10px;
                  background-repeat: no-repeat;
                }

                .mat-calendar-period-button {
                  color: var(--color-nightshade) !important;
                  padding: 0 !important;
                  height: 18px;
                }

                .mat-calendar-previous-button,
                .mat-calendar-next-button {
                  color: var(--color-nightshade) !important;
                  padding: 0 !important;
                  margin: 0 !important;
                  width: 40px !important;
                  height: 18px !important;
                }

                .mat-calendar-next-button::after,
                .mat-calendar-previous-button::after {
                  display: none;
                }

                .mat-calendar-previous-button {
                  background: url(/assets/images/arrow-left.svg);
                  background-size: 12px 12px;
                  background-repeat: no-repeat;
                  background-position: center center;
                }

                .mat-calendar-next-button {
                  background: url(/assets/images/arrow-right.svg);
                  background-size: 12px 12px;
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              }
            }

            .mat-calendar-content {
              padding: 4px 0 !important;
              border-bottom: 1px solid rgba(0, 0, 0, 0.12);

              .mat-calendar-table {
                .mat-calendar-table-header {
                  tr {
                    th {
                      padding: 0 0 4px 0 !important;
                    }
                  }

                  tr:last-child {
                    th::after {
                      left: 0px !important;
                      right: 0px !important;
                    }
                  }
                }

                .mat-calendar-body {

                  .mat-calendar-body-label {
                    padding-top: 4px !important;
                    padding-bottom: 4px !important;
                    font-size: 12px !important;
                    font-weight: 500 !important;
                  }

                  .mat-calendar-body-cell {
                    font-size: 10px !important;
                    padding-top: 0.14286% !important;

                    .mat-calendar-body-cell-content {
                      font-size: 10px !important;
                      width: 20px !important;
                      height: 20px !important;
                    }

                    .mat-calendar-body-cell-content.mat-calendar-body-selected {
                      background-color: var(--color-mountain-purple) !important;
                      color: var(--color-pure-white);
                    }
                  }
                }
              }
            }
          }

          .ngx-mat-drp-menu {
            padding: 4px 15px 0 15px;

            .ngx-mat-drp-presets-list {
              display: block;

              li {
                background: transparent;
                border-radius: 4px;
                display: inline-block;
                width: calc(50% - 30px);
                padding: 4px 10px;
                margin: 5px;
              }

              li:hover {
                background-color: var(--color-mountain-purple);
                color: var(--color-pure-white);
              }

              li.active {
                background-color: var(--color-mountain-purple);
                color: var(--color-pure-white);
                cursor: default;
              }
            }
          }
        }

        .ngx-mat-drp-calendar-item:last-child {
          border-left: 1px solid var(--color-smoke-platinum);
          padding: 0 !important;
        }

        .ngx-mat-drp-controls {
          width: 100%;
          display: flex;
          height: 70px;
          align-items: center;
          justify-content: space-around;
          right: 0;
          bottom: 0;

          @media only screen and (max-width: 600px) {
            bottom: 0px !important;
          }

          @media only screen and (min-width: 600px) and (max-width:767px) {
            bottom: 0;
          }
        }
      }
    }
  }
}

.input-field.date-range-picker {
  @media screen and (min-width : 768px) {
    width: 100%;

    .date-range-no-border.none-display {
      .ngx-mat-drp-date-display {
        max-width: 170px !important;
        min-width: 170px !important;

        .mat-form-field-infix {
          padding: 0 !important;

          input {
            color: var(--color-pure-white) !important;
            opacity: 0 !important;
            padding: 0;
            width: 170px !important;
            max-width: 170px !important;
            padding-right: 0 !important;
          }
        }
      }
    }

    .date-range-no-border {
      background: transparent;
      text-align: left;

      div {

        .ngx-mat-drp-date-display {
          max-width: 175px !important;
          min-width: 165px !important;

          .mat-form-field-wrapper {
            padding-bottom: 0 !important;

            .mat-form-field-flex {
              border: none !important;
              border-radius: 0 !important;
              max-width: 175px;

              .mat-form-field-infix {
                margin: 0 !important;
                width: 100%;
                min-width: 150px;
                max-width: 175px;
                padding: 0 0 0 13px;

                input {
                  color: var(--color-nightshade) !important;
                  font-family: "Brandon Text";
                  font-size: 12px;
                  line-height: 17px;
                  padding-left: 0 !important;
                  width: 165px;
                  max-width: 175px;
                }
              }

              .mat-form-field-suffix {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width : 767px) {
    width: 100%;

    .date-range-no-border.none-display {
      .ngx-mat-drp-date-display {
        max-width: 130px !important;
        min-width: 130px !important;

        .mat-form-field-infix {
          padding: 0 !important;

          input {
            color: var(--color-pure-white) !important;
            opacity: 0 !important;
            padding: 0;
            width: 130px !important;
            max-width: 170px !important;
            padding-right: 0 !important;
          }
        }
      }
    }

    .date-range-no-border {
      background: transparent;
      text-align: left;

      div {

        .ngx-mat-drp-date-display {
          max-width: 130px !important;
          min-width: 130px !important;

          .mat-form-field-wrapper {
            padding-bottom: 0 !important;

            .mat-form-field-flex {
              border: none !important;
              border-radius: 0 !important;
              max-width: 165px;

              .mat-form-field-infix {
                margin: 0 !important;
                width: 100%;
                min-width: 130px;
                max-width: 130px;
                padding: 0 0 0 13px;

                input {
                  color: var(--color-nightshade) !important;
                  font-family: "Brandon Text";
                  font-size: 12px;
                  line-height: 17px;
                  padding-right: 15px;
                  padding-left: 0 !important;
                  width: 130px;
                  max-width: 130px;
                }
              }

              .mat-form-field-suffix {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}


.input-field.date-range-picker-hightlight.calender-icon-prefix {
  position: relative;
  width: 300px !important;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {
        border: 2px solid var(--color-mountain-purple) !important;
        border-radius: 4px !important;
        padding-left: 50px;

        .mat-form-field-infix {
          input {
            color: var(--color-mountain-purple) !important;
            padding-left: 36px !important;
          }
        }

        .mat-form-field-suffix {
          display: none !important;
        }
      }
    }
  }

  .calender-icon {
    position: absolute;
    top: 13.5px;
    left: 18px;
    color: var(--color-mountain-purple);
    width: 18px;
    height: 18px;
    font-size: 20px;

    @supports(-moz-appearance:none) {
      top: 15.5px;
    }
  }
}

/*.input-field.date-range-picker-hightlight.calender-icon-prefix:hover {
  background-color: var(--color-smoke-platinum) !important;
  color: var(--color-smoke-platinum) !important;

  input {
    color: var(--color-mountain-purple) !important;
    background-color: var(--color-smoke-platinum) !important
  }
}*/

.input-field.date-range-picker.center-alignment.calender-icon-prefix {
  position: relative;


  @media screen and (min-width : 768px) {
    mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;

        .mat-form-field-flex {
          .mat-form-field-infix {
            width: 100%;
            padding: 13px 0 !important;

            input {
              text-align: left !important;
              padding-left: calc(50% - 75px) !important;
            }
          }

          .mat-form-field-suffix {
            display: none;
          }
        }
      }
    }

    .calender-icon {
      margin: 0 !important;
      position: absolute;
      top: 25px;
      left: calc(50% - 100px);
      cursor: pointer;
      color: var(--color-london-gray) !important;
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }

  @media screen and (min-width : 500px) and (max-width : 767px) {
    mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;

        .mat-form-field-flex {
          .mat-form-field-infix {
            width: 100%;
            padding: 13px 0 !important;

            input {
              text-align: left !important;
              padding-left: calc(50% - 75px) !important;
            }
          }

          .mat-form-field-suffix {
            display: none;
          }
        }
      }
    }

    .calender-icon {
      margin: 0 !important;
      position: absolute;
      top: 25px;
      left: calc(50% - 100px);
      cursor: pointer;
      color: var(--color-london-gray) !important;
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }

  @media screen and (min-width : 400px) and (max-width : 499px) {
    mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;

        .mat-form-field-flex {
          .mat-form-field-infix {
            width: 100%;
            padding: 13px 0 !important;

            input {
              text-align: left !important;
              padding-left: calc(50% - 75px) !important;
            }
          }

          .mat-form-field-suffix {
            display: none;
          }
        }
      }
    }

    .calender-icon {
      margin: 0 !important;
      position: absolute;
      top: 25px;
      left: calc(50% - 100px);
      cursor: pointer;
      color: var(--color-london-gray) !important;
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }

  @media screen and (max-width : 399px) {
    mat-form-field {
      display: none !important;

      .mat-form-field-wrapper {
        padding-bottom: 0 !important;

        .mat-form-field-flex {
          .mat-form-field-infix {
            width: 100%;
            padding: 13px 0 !important;

            input {
              text-align: left !important;
              padding-left: calc(50% - 40px) !important;
            }
          }

          .mat-form-field-suffix {
            display: none;
          }
        }
      }
    }

    .calender-icon {
      display: none !important;
      margin: 0 !important;
      position: absolute;
      top: 25px;
      left: calc(50% - 65px);
      cursor: pointer;
      color: var(--color-london-gray) !important;
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }
}

.input-field.date-range-picker.center-alignment.calender-icon-prefix-mobile-device {
  position: relative;

  mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-flex {

        .mat-form-field-infix {
          width: 100%;

          input {
            text-align: center;
          }
        }

        .mat-form-field-suffix {
          display: none;
        }
      }
    }
  }

  .calender-icon {
    margin: 0 !important;
    position: absolute;
    top: 20px;
    left: 15px;
    cursor: pointer;
    color: var(--color-london-gray) !important;
  }
}

.input-field.date-range-picker.mobile-device.align-center {

  @media screen and (min-width : 768px) {
    .mat-form-field-prefix {
      margin-left: -44px !important;
      top: 13px !important;
      left: calc(50% - 90px) !important;
      position: absolute;


      @supports(-moz-appearance:none) {
        top: 15px !important;
      }
    }

    .mat-form-field-infix {
      input {
        width: 100% !important;
        padding-left: calc(50% - 30px) !important;
        text-align: left !important;
      }
    }
  }

  @media screen and (max-width : 767px) and (min-width : 500px) {
    .mat-form-field-prefix {
      margin-left: -44px !important;
      top: 13px !important;
      left: calc(50% - 90px) !important;
      position: absolute;


      @supports(-moz-appearance:none) {
        top: 15px !important;
      }
    }

    .mat-form-field-infix {
      input {
        width: 100% !important;
        text-align: left;
        padding-left: calc(50% - 90px) !important;
      }
    }
  }

  @media screen and (max-width : 499px) and (min-width : 400px) {
    .mat-form-field-prefix {
      margin-left: -44px !important;
      top: 13px !important;
      left: calc(50% - 90px) !important;
      position: absolute;


      @supports(-moz-appearance:none) {
        top: 15px !important;
      }
    }

    .mat-form-field-infix {
      input {
        width: 100% !important;
        text-align: left;
        padding-left: calc(50% - 90px) !important;
      }
    }
  }

  @media screen and (max-width : 399px) and (min-width : 300px) {
    .mat-form-field-prefix {
      margin-left: -40px !important;
      top: 13px !important;
      left: calc(50% - 90px) !important;
      position: absolute;


      @supports(-moz-appearance:none) {
        top: 15px !important;
      }
    }

    .mat-form-field-infix {
      input {
        width: 100% !important;
        text-align: left;
        padding-left: calc(50% - 65px) !important;
      }
    }
  }

  @media screen and (max-width : 299px) {
    .mat-form-field-prefix {
      margin-left: -36px !important;
      top: 13px !important;
      left: calc(50% - 90px) !important;
      position: absolute;


      @supports(-moz-appearance:none) {
        top: 15px !important;
      }
    }

    .mat-form-field-infix {
      input {
        width: 100% !important;
        text-align: left;
        padding-left: calc(50% - 65px) !important;
      }
    }
  }
}

.date-range-no-display {
  .ngx-mat-drp-date-display {
    max-width: 170px !important;
    min-width: 170px !important;

    .mat-form-field-infix {
      padding: 0 !important;

      input {
        color: var(--color-pure-white) !important;
        opacity: 0 !important;
        padding: 0;
        width: 170px !important;
        max-width: 170px !important;
        padding-right: 0 !important;
      }
    }
  }
}
