button {
  font-family: "Brandon Text Bold";
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 0 12px;
  height: 36px;
  box-shadow: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -o-fit-content;
  width: fit-content;

  &.mat-button,
  &.mat-icon-button,
  &.mat-raised-button,
  &.mat-stroked-button,
  &.mat-flat-button,
  &.mat-fab,
  &.mat-mini-fab {
    font-family: "Brandon Text Bold" !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    letter-spacing: 0.03em !important;
    box-shadow: none !important;
    border-radius: 4px;

    .mat-button-wrapper {
      font-family: inherit !important;
      font-weight: inherit !important;
      font-size: inherit !important;
      font-style: inherit !important;
    }

    .mat-button-focus-overlay {
      background: inherit !important;
    }

    &.download-button {
      .mat-button-wrapper {
        display: flex;
      }
    }
  }

  &.disabled-btn,
  &[disabled],
  &[disabled="true"] {
    background-color: var(--color-disabled) !important;
    border-color: var(--color-disabled) !important;
    cursor: default !important;
    color: var(--color-pure-white) !important;

    .material-icons {
      color: var(--color-pure-white) !important;
    }

    &:hover {
      background-color: var(--color-disabled);
      border-color: var(--color-disabled) !important;
      cursor: default !important;
      color: var(--color-pure-white);

      .material-icons {
        color: var(--color-pure-white) !important;
      }
    }
  }

  &.capitalize {
    text-transform: capitalize !important;

    &:hover,
    &:focus,
    &:focus-within,
    &:visited {
      text-transform: capitalize !important;
    }
  }

  &.lowercase {
    text-transform: lowercase !important;

    &:hover,
    &:focus,
    &:focus-within,
    &:visited {
      text-transform: lowercase !important;
    }
  }

  &.transform-none {
    text-transform: none !important;

    &:hover,
    &:focus,
    &:focus-within,
    &:visited {
      text-transform: none !important;
    }
  }

  &.primary-btn {
    background-color: var(--color-mountain-purple);
    border: 1px solid var(--color-mountain-purple);
    color: var(--color-pure-white);
    cursor: pointer;

    .mat-button-wrapper {
      color: var(--color-pure-white);
    }

    .material-icons {
      color: var(--color-pure-white);
    }

    &:hover {
      background-color: var(--color-mountain-purple-hover);
      border: 1px solid var(--color-mountain-purple-hover);
      color: var(--color-pure-white);
    }

    &:focus,
    &:focus-within,
    &:visited {
      background-color: var(--color-mountain-purple);
      border: 1px solid var(--color-mountain-purple);
      color: var(--color-pure-white);
    }

    &.download-button {
      .download-icon {
        background-image: url("https://static.enjoywishlist.com/assets/static/download_transparent.svg");
      }
    }
  }

  &.secondary-btn {
    border: 1px solid var(--color-mountain-purple);
    color: var(--color-mountain-purple);
    background: var(--color-pure-white);
    cursor: pointer;

    .material-icons {
      color: var(--color-mountain-purple);
    }

    &:hover {
      border: 1px solid var(--color-mountain-purple-hover);
      color: var(--color-mountain-purple-hover);
      background: var(--color-pure-white);
    }

    &:focus,
    &:focus-within,
    &:visited {
      border: 1px solid var(--color-mountain-purple);
      color: var(--color-mountain-purple);
      background: var(--color-pure-white);
    }

    &.download-button {
      .download-icon {
        background-image: url("https://static.enjoywishlist.com/assets/static/download_primary.svg");
      }
    }
  }

  &.tertiary-btn {
    border: 1px solid transparent;
    color: var(--color-mountain-purple);
    background: transparent;
    cursor: pointer;

    .material-icons {
      color: inherit !important;
    }

    &:hover {
      border: 1px solid transparent;
      color: var(--color-mountain-purple-hover);
      background: var(--color-action-secondary);
    }

    &:focus-within,
    &:visited {
      border: 1px solid transparent;
      color: var(--color-mountain-purple);
      background: transparent;
    }

    &:focus {
      border: 1px solid transparent;
      color: var(--color-mountain-purple-hover);
      background: var(--color-action-secondary);
    }

    &.download-button {
      .download-icon {
        background-image: url("https://static.enjoywishlist.com/assets/static/download_primary.svg");
      }
    }

    &.disabled-btn,
    &[disabled],
    &[disabled="true"] {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--color-disabled) !important;
      cursor: default !important;

      &:hover {
        background-color: transparent !important;
        border-color: transparent !important;
        color: var(--color-disabled) !important;
        cursor: default !important;
      }
    }
  }

  &.upgrade-btn {
    background-color: var(--color-success-green);
    border: 1px solid var(--color-success-green);
    color: var(--color-pure-white);
    cursor: pointer;

    .mat-button-wrapper {
      color: var(--color-pure-white);
    }

    .material-icons {
      color: var(--color-pure-white);
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:visited {
      background-color: var(--color-see-green);
      border-color: var(--color-see-green);
    }

    &.download-button {
      .download-icon {
        background-image: url("https://static.enjoywishlist.com/assets/static/download_transparent.svg");
      }
    }
  }

  &.vertical-edit-btn {
    min-width: 35px !important;
    max-width: 35px !important;
    cursor: pointer;
    padding: 0 0 !important;

    .material-icons {
      margin: -2.5px 0 0 0 !important;
    }

    .material-icons {
      color: currentColor;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:visited {
      background-color: inherit;
      border-color: currentColor;
    }
  }

  .material-icons:not(.default) {
    font-size: 18px;
    height: 18px;
    width: 18px;
    margin: -2.5px 5px 0 0;
    color: currentColor;
  }

  .download-icon {
    background-image: url("https://static.enjoywishlist.com/assets/static/download_transparent.svg");
    width: 14px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 2.5px 10px 0 0;
    display: inline-block;
  }
}