.design-custom-table.basic-table {
  padding: 14px 0 0px 0 !important;
  background: var(--color-snow);

  .table-pagination {
    border-top: 1px solid var(--color-disabled);
    background-color: var(--color-snow);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    height: 65px;
  }
}

.design-custom-table,
.select-row-table {
  overflow: hidden;
  background-color: var(--color-pure-white);
  border-radius: 13px;
  padding: 0px 0 0px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: var(--color-silver) transparent;

  .table-actions {
    border-radius: 13px 13px 0px 0px;

    button:not(.default) {
      height: 40px !important;
      margin: 0 6px !important;
      width: unset;
    }

    .table-regular-action.hidden {
      display: none;
    }

    .table-regular-action {
      border-bottom: 0.25px solid var(--color-silver);
      padding: 28px 20px 5px 20px;
      display: flex;
      min-height: 45px;

      .tables-action-grid.width-left {
        width: 70%;
      }

      .tables-action-grid.width-right {
        width: 30%;
      }

      .tables-action-grid {
        width: 50%;

        .input-field {
          padding: 0 !important;
        }

        .table-filter-applied {
          padding: 0px 0px 15px 0;
          height: 35px;
          margin-top: 10px;
          width: max-content;

          .table-filter-tag {
            text-align: center;
            width: 100%;
            padding: 8px 20px;
            border-radius: 3px;
            background-color: var(--color-pond-blue);
            position: relative;
            padding-right: 50px;

            .filter-name {
              color: var(--color-london-gray);
              font-size: 14px;
              font-weight: bold;
              margin-right: 10px;
            }

            .remove-filter {
              background-color: var(--color-london-gray);
              border-radius: 50%;
              height: 20px;
              width: 20px;
              display: inline-block;
              position: absolute;
              top: 10px;
              right: 15px;

              .mat-icon {
                font-size: 14px;
                width: 20px;
                height: 20px;
                color: var(--color-pure-white);
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }

        .table-filter-action {
          text-align: right;
          margin: 10px 15px 0 0;
          width: 100%;

          buttton {
            height: 40px !important;
            margin: 0 5px !important;
          }
        }
      }
    }

    .row-select-action {
      border-bottom: 0.25px solid var(--color-silver);
      padding: 21px 20px;
      display: flex;

      .row-selection-details {
        width: 90%;
        display: flex;

        .count-rows-selected {
          color: var(--color-nightshade);
          font-family: "Brandon Text";
          font-size: 16px;
          line-height: 22px;
          padding: 20px;
        }

        .selected-row-action {
          border-left: 2px solid var(--color-nightshade);
          color: var(--color-mountain-purple);
          font-family: "Brandon Text";
          font-size: 16px;
          line-height: 22px;
          padding: 20px;

          span,
          div {
            cursor: pointer;
          }
        }
      }

      .deselect-all-row {
        width: 10%;
        text-align: right;
        margin-right: 5px;
        align-items: center;
        -ms-transform: translateY(-50%);
        transform: translateY(38%);

        .close-circle {
          top: 0 !important;
          right: 0 !important;
          display: grid;
          place-content: center;

          .close-icon {
            &.mat-icon {
              font-size: 16px;
              display: grid;
              place-content: center;
              cursor: pointer;
              color: var(--color-pure-white);
              background-color: var(--color-mountain-purple);
              border-radius: 50%;

              &:hover {
                background-color: var(--color-mountain-purple-hover);
              }
            }
          }
        }
      }
    }
  }

  .table-actions.selected-row-background {
    background-color: var(--color-action-secondary);
  }

  .table {
    width: 100%;
    max-height: 500px;
    height: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: -o-fit-content;
    min-height: 500px;
    overflow-y: auto;
    background-color: var(--color-snow);
    position: relative;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: var(--color-silver) transparent;

    &::-webkit-scrollbar-track {
      margin-top: 64px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: var(--color-pure-white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-silver);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track:horizontal {
      background-color: transparent;
    }

    &::-webkit-scrollbar:horizontal {
      width: 6px;
      background-color: var(--color-pure-white);
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: var(--color-silver);
      border-radius: 5px;
    }

    thead {
      background-color: inherit;

      th {
        color: var(--color-london-gray);
        font-family: "Brandon Text";
        font-size: 16px;
        line-height: 22px;
        border-bottom-color: var(--color-disabled);
        border-bottom-width: 1px;
        border-bottom-style: solid;

        .mat-sort-header-container {
          border-bottom: none;

          .mat-sort-header-button {
            color: var(--color-london-gray) !important;
            font-family: "Brandon Text";
            font-size: 16px !important;
            line-height: 22px !important;
            font-weight: 500 !important;
            margin: 0 !important;
            text-transform: capitalize !important;
            letter-spacing: unset !important;
          }
        }

        .mat-sort-header-container.mat-sort-header-sorted {
          .mat-sort-header-button {
            font-weight: bold !important;
            color: var(--color-nightshade) !important;
          }

          .mat-sort-header-arrow {
            color: var(--color-nightshade) !important;
          }
        }
      }
    }

    tbody {
      background-color: var(--color-pure-white);

      tr.bg-highlight {
        background-color: var(--color-pond-blue) !important;
      }

      tr {
        background: inherit;

        td.bg-highlight {
          background-color: var(--color-pond-blue) !important;

          button {
            background-color: var(--color-pond-blue) !important;
          }
        }

        td.mat-column-expendedRow {
          padding: 0 !important;
        }

        td {
          color: var(--color-nightshade);
          font-family: "Brandon Text";
          font-size: 16px;
          line-height: 22px;
          background: inherit;

          .edit-row-button {
            text-transform: capitalize !important;
            width: 100px !important;
          }

          .vertical-edit-btn {}

          .relative {
            .icon {
              position: absolute;
              left: -30px;
              top: -2px;

              .mat-icon {
                margin: 0 !important;
              }
            }
          }
        }

        td.mat-column-Select {
          span {
            display: block;
          }
        }

        td.highlight {
          color: var(--color-mountain-purple);
          cursor: pointer;
        }
      }
    }

    .no-data-message {
      color: var(--color-nightshade);
      font-size: 16px;
      line-height: 24px;
      height: 100px;
      display: grid;
      place-content: center;
    }
  }

  .table-pagination {
    border-top: 1px solid rgba(0, 0, 0, .12);
    background-color: var(--color-snow);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;

    .mat-paginator {
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
      background-color: inherit;

      .mat-paginator-container {
        .mat-paginator-page-size {
          .mat-paginator-page-size-select {
            .mat-form-field-wrapper {
              .mat-form-field-flex {
                .mat-form-field-infix {
                  .mat-select-trigger {
                    .mat-select-arrow-wrapper {
                      width: 14px;
                      height: 14px;

                      .mat-select-arrow {
                        width: 14px;
                        height: 14px;
                        margin-top: -3px !important;
                        border-left: unset !important;
                        border-left: unset !important;
                        border-left: unset !important;
                        border-right: unset !important;
                        border-top: unset !important;
                        background: url(/assets/images/arrow-down.svg);
                        background-position: center center;
                        background-size: 12px 12px;
                        background-repeat: no-repeat;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .mat-paginator-page-size-label {
          font-size: 14px;
          color: var(--color-nightshade);
        }

        .mat-paginator-range-actions {
          color: var(--color-london-gray) !important;

          .mat-paginator-navigation-previous {
            background: url(/assets/images/arrow-left.svg);
            background-position: center;
            background-size: 12px 12px;
            background-repeat: no-repeat;

            .mat-button-wrapper {
              .mat-paginator-icon {
                display: none;
              }
            }
          }

          .mat-paginator-navigation-next {
            background: url(/assets/images/arrow-right.svg);
            background-position: center center;
            background-size: 12px 12px;
            background-repeat: no-repeat;

            .mat-button-wrapper {
              .mat-paginator-icon {
                display: none;
              }
            }
          }

          .mat-paginator-range-label {
            color: var(--color-nightshade);
            font-size: 14px;
          }
        }
      }
    }
  }


  .table-data-loader {
    position: relative;
    height: 6px;
  }
}

.mat-table {
  width: 100% !important;

  tr.mat-header-row {
    height: 65px;
  }

  tr.mat-row {
    height: 65px;
  }
}

.mat-paginator-container {
  justify-content: center !important;
}

.expanded-row-table {
  .mat-header-row {
    .mat-header-cell:first-child {
      width: 55px;
    }

    .mat-header-cell:nth-child(2) {
      width: 80px;
    }

    .mat-header-cell:last-child {
      width: 60px;
    }
  }
}

.select-row-table {
  .mat-header-row {
    .mat-header-cell:first-child {
      width: 55px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .mat-header-cell:last-child {
      width: 60px;
    }
  }

  .mat-row {
    .mat-column-Select:first-child {
      width: 55px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.mat-column-expendedRow {
  background: var(--color-snow) !important;
}

.input-field.search-table {
  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 15px !important;

      .mat-form-field-flex {
        .mat-form-field-infix {
          input {
            padding: 15px 12px 15px 2px !important;
          }
        }

        .mat-form-field-prefix {
          .material-icons-round {
            margin-left: 17px;
            margin-right: 11px;
            width: 16px;
            height: 16px;
            font-size: 16px;
          }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          background: var(--color-snow) !important;
        }
      }
    }
  }
}

.market-table {
  .design-custom-table {
    .table {
      thead {
        tr {
          th:not(:first-child):not(:nth-child(1)):not(:last-child) {
            width: 14%;
          }
        }
      }
    }
  }
}

.mat-paginator {
  .mat-paginator-container {
    .mat-button-base {
      color: var(--color-nightshade) !important;
      background-color: transparent !important;

      &:hover,
      &:focus,
      &:focus-within,
      &:visited {
        background-color: transparent !important;
      }

      .mat-ripple {
        display: none !important;
      }
    }
  }
}

// Best-Buy Starts
.best-buy-table-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

}

.best-buy-section {
  .best-buy-section-label {
    font-family: "Brandon Text Medium";
    font-size: 16px;
    padding-bottom: 12px;
    text-transform: capitalize;
    color: #323C4D;
  }
}

.best-buy-table {
  width: 100%;
  border: 2px solid var(--color-disabled);;
  border-spacing: 0;
  border-bottom: 0;
}

.best-buy-table th {
  padding: 12px;
  text-align: left;
  width: 250px;
  font-family: "Brandon Text";
  font-weight: lighter;
}

.best-buy-table th:not(:last-child) {
  border-bottom: 2px solid var(--color-disabled);
}

.best-buy-table td {
  text-align: left;
  padding: 12px;
  border-left: 2px solid var(--color-disabled);;
  border-bottom: 2px solid var(--color-disabled);;
}

.best-buy-section:not(:first-child){
  margin-top: 24px;
}
// Best-Buy Ends