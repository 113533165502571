.mat-chip:not(.default),
.mat-chip:hover:not(.default) {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  padding: 0px 11px !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  cursor: default !important;
  min-height: 40px !important;
  height: 40px !important;
  border-radius: 20.5px !important;
  font-family: "Brandon Text" !important;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 20px !important;
  border: 1px solid !important;
  outline: none !important;
  background-color: transparent !important;
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  border: 1px solid !important;

  .mat-chip-ripple {
    display: none;
  }
}

.mat-chip:active {
  box-shadow: none !important;
  outline: none !important;
}

.mat-chip::after {
  background-color: transparent !important;
}

.primary-chip {
  border-color: var(--color-nightshade) !important;
  color: var(--color-nightshade) !important;
}


.secondary-chip {
  border-color: var(--color-london-gray) !important;
  color: var(--color-london-gray) !important;
}

.secondary-chip.mat-chip-with-trailing-icon {
  border-color: var(--color-london-gray) !important;
  color: var(--color-london-gray) !important;
  position: relative;
  padding: 0 40px 0 24px !important;

  .mat-chip-remove {
    margin: 0 !important;
    position: absolute !important;
    top: 7.5px !important;
    right: 5px !important;
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 3px !important;
    color: var(--color-london-gray) !important;
    opacity: 1 !important;
    display: -webkit-box !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
  }
}

.success-chip {
  border-color: var(--color-success-green) !important;
  color: var(--color-success-green) !important;
}

.warning-chip {
  border-color: var(--color-warning) !important;
  color: var(--color-warning) !important;
}

.error-chip {
  border-color: var(--color-notification) !important;
  color: var(--color-notification) !important;
}

.highlight-chip {
  border-color: var(--color-mountain-purple) !important;
  color: var(--color-mountain-purple) !important;
}

.disabled-chip,
.disabled-chip:hover {
  border-color: var(--color-silver) !important;
  background-color: var(--color-silver) !important;
  color: var(--color-pure-white) !important;
  cursor: default !important;
}

.disabled-chip.mat-chip-with-trailing-icon {
  border-color: var(--color-silver) !important;
  color: var(--color-pure-white) !important;
  cursor: default !important;
  position: relative;
  padding: 0 40px 0 24px !important;

  .mat-chip-remove {
    margin: 0 !important;
    position: absolute !important;
    top: 7.5px !important;
    right: 5px !important;
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 3px !important;
    color: var(--color-pure-white) !important;
    opacity: 1 !important;
    display: -webkit-box !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
  }
}


.hover-chip {
  border-color: var(--color-mountain-purple-hover) !important;
  color: var(--color-mountain-purple-hover) !important;
}

.see-green-chip.mat-chip-with-trailing-icon {
  border-color: var(--color-see-green) !important;
  color: var(--color-see-green) !important;
  position: relative;
  padding: 0 40px 0 24px !important;

  .mat-chip-remove {
    margin: 0 !important;
    position: absolute !important;
    top: 7.5px !important;
    right: 5px !important;
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 3px !important;
    color: var(--color-see-green) !important;
    opacity: 1 !important;
    display: -webkit-box !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important
  }
}

.mat-chip-list-margin-0 {
  .mat-chip-list-wrapper {
    margin: 0 !important;
  }
}

.preference-chip-list{
    .mat-chip-list-wrapper{
      column-gap: 12px;
      row-gap: 16px;
      padding: 0 4px;
      box-sizing: border-box;
    }
}