.mat-tooltip {
  opacity: 0;
  display: none;

  &.wl-tooltip {
    background: var(--color-nightshade) !important;
    font-size: 12px !important;
    font-family: "Brandon Text" !important;
    color: var(--color-pure-white) !important;
    display: initial !important;
    opacity: 1.0 !important;
    box-sizing: border-box !important;
    margin: 5px !important;
    min-height: 25px !important;
    display: flex !important;
    justify-content: center !important;
    padding: 4px !important;
    font-size: 12px !important;
    letter-spacing: 0 !important;
    line-height: 17px !important;
    text-transform: none !important;
    text-align: center !important;
    background: var(--color-background) !important;
    color: var(--color-nightshade) !important;
    overflow: visible !important;
    border: 1px solid var(--color-disabled);
    max-width: 300px !important;

    .wl-tooltip-login {
      margin-right: 0px !important;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      top: 99%;
      left: 50%;
      margin-left: -4px;
    }

    &::before {
      border-top: 6px solid var(--color-disabled);
      margin-top: 1px;
    }

    &::after {
      border-top: 6px solid var(--color-smoke-platinum);
      margin-top: -1px;
      z-index: 1;
    }
  }
}