.people-autocomplete {
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  background-color: var(--color-pure-white);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: var(--color-silver) transparent;

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-periwinkle);

    @media screen and (max-width:767px) {
      border-radius: 2.5px;
    }

    @media screen and (min-width:767px) {
      border-radius: 13px;
    }
  }

  &::-webkit-scrollbar {
    @media screen and (max-width:767px) {
      width: 2.5px;
    }

    @media screen and (min-width:767px) {
      width: 7px;
    }

    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .mat-option.focus,
  .mat-option:hover {
    background: #E6EAF3;
  }

  .mat-option {

    &:hover,
    &.mat-active {
      background: #E6EAF3;
    }


    .options {
      .flex {
        padding: 5px 0;

        .people-profile {
          width: 28px;

          .profile-image {
            height: 28px;
            width: 28px;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 14px;
          }

          .profile-name {
            height: 26px;
            width: 26px;
            color: var(--color-pure-white);
            background-color: var(--color-nightshade);
            border-radius: 14px;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            display: -webkit-box;
            display: flex;
            border: 1px solid var(--color-nightshade);
            font-size: 15px;
            overflow: hidden;
          }
        }

        .people-details {
          margin-left: 8px;

          .name {
            color: var(--color-nightshade);
            font-family: "Brandon Text";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
          }

          .email {
            color: var(--color-london-gray);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
      }
    }
  }
}

.input-field-dotted.autocomplete.multi-select-4 {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;

  .input-label {
    margin-bottom: 13px;
  }

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;

      .mat-form-field-flex {
        height: unset !important;
        min-height: 36px !important;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: 1px !important;
          border-color: var(--color-nightshade) !important;
        }

        .mat-form-field-outline-start {
          border-radius: 4px 0 0 4px !important;
        }

        .mat-form-field-outline-gap {
          border-radius: 0 !important;
        }

        .mat-form-field-outline-end {
          border-radius: 0 4px 4px 0 !important;
        }

        .mat-form-field-infix {
          height: unset !important;
          padding: 0px 0px 1px 35px !important;

          .mat-chip-list {
            .mat-chip-list-wrapper {
              margin: 0 !important;
              cursor: text;

              .mat-chip {
                border-color: var(--color-pure-white) !important;
                background-color: var(--color-pure-white) !important;
                color: var(--color-nightshade) !important;
                font-size: 14px !important;
                letter-spacing: 0 !important;
                line-height: 17px !important;
                font-weight: 500 !important;
                padding: 0px 3px !important;
                min-height: 20px !important;
                height: 36px !important;
                margin: 2px !important;

                .mat-icon {
                  color: inherit !important;
                  opacity: 1 !important;
                  font-size: 14px !important;
                  width: 14px !important;
                  height: 14px !important;
                  top: 4.5px !important;
                  right: 5px !important;
                  display: grid !important;
                  place-content: center !important;
                  position: unset !important;
                  margin: 0 0 0 8px !important;
                  color: var(--color-mountain-purple) !important;
                }
              }

              .primary-chip.mat-chip {
                color: var(--color-dark-nightshade) !important;
                font-family: "Brandon Text Medium" !important;
              }

              .disabled-chip.mat-chip {
                color: rgb(118, 120, 127) !important;
              }
            }
          }

          input {
            padding: 5px 12px !important;
            color: var(--color-nightshade) !important;
            font-family: "Brandon Text" !important;
            font-size: 16px !important;
            line-height: 22px !important;
            width: 100% !important;

            &.minimize-input {
              width: 0px !important;
              padding: 5px 0 !important;
              flex: unset;

              &:focus {
                flex: 1 0 100px;
              }

              &:focus-within {
                flex: 1 0 100px;
              }

              &:focus-visible {
                flex: 1 0 100px;
              }

              &:-moz-focusring {
                flex: 1 0 100px;
              }

              &::-moz-focus-inner {
                flex: 1 0 100px;
              }
            }

            &[ng-reflect-readonly="true"] {
              cursor: pointer !important;
            }

            &::placeholder {
              color: var(--color-silver) !important;
              opacity: 1;
              /* Firefox */
            }

            &::-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: var(--color-silver) !important;
            }
          }
        }

        .mat-form-field-prefix {
          min-height: 24px !important;
          border-radius: 4px 0 0 4px;
          position: absolute;
          top: 11px !important;
          left: 1px !important;
          bottom: 1px;
          height: inherit !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          .material-icons-round {
            color: var(--color-silver) !important;
            margin: 0 13px !important;
          }
        }

        .mat-form-field-suffix {
          min-height: 36px !important;
          border-radius: 0;
          position: absolute;
          top: 1px !important;
          right: 1px !important;
          bottom: 1px;
          height: inherit !important;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          padding-left: 0 !important;
          padding-right: 0 !important;
          border-left: 1px solid var(--color-nightshade);

          .material-icons-round:not(.custom-select-input) {
            color: var(--color-nightshade);
            margin: 0 13px !important;
            font-size: 24px !important;
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }
  }
}

.wl-input-field.autocomplete.multi-select-4 {
  border: none !important;
  font-family: inherit;
  background: transparent;
  overflow: inherit;
  z-index: inherit;
  padding: 25px 0 15px 0;

  .input-label {
    margin-bottom: 13px;
  }

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0.34375em !important;

      .mat-form-field-flex {
        height: unset !important;
        min-height: 36px !important;

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: 1px !important;
          border-color: var(--color-nightshade) !important;
        }

        .mat-form-field-outline-start {
          border-radius: 4px 0 0 4px !important;
        }

        .mat-form-field-outline-gap {
          border-radius: 0 !important;
        }

        .mat-form-field-outline-end {
          border-radius: 0 4px 4px 0 !important;
        }

        .mat-form-field-infix {
          height: unset !important;
          padding: 0px 0px 1px 35px !important;

          .mat-chip-list {
            .mat-chip-list-wrapper {
              margin: 0 !important;
              cursor: text;

              .mat-chip {
                border-color: var(--color-pure-white) !important;
                background-color: var(--color-pure-white) !important;
                color: var(--color-nightshade) !important;
                font-size: 14px !important;
                letter-spacing: 0 !important;
                line-height: 17px !important;
                font-weight: 500 !important;
                padding: 0px 3px !important;
                min-height: 20px !important;
                height: 36px !important;
                margin: 2px !important;

                .mat-icon {
                  color: inherit !important;
                  opacity: 1 !important;
                  font-size: 14px !important;
                  width: 14px !important;
                  height: 14px !important;
                  top: 4.5px !important;
                  right: 5px !important;
                  display: grid !important;
                  place-content: center !important;
                  position: unset !important;
                  margin: 0 0 0 8px !important;
                }
              }

              .primary-chip.mat-chip {
                color: var(--color-nightshade) !important;
              }

              .disabled-chip.mat-chip {
                color: rgb(118, 120, 127) !important;
              }
            }
          }

          input {
            padding: 7px 12px !important;
            color: var(--color-nightshade) !important;
            font-family: "Brandon Text" !important;
            font-size: 16px !important;
            line-height: 22px !important;
            width: 100% !important;

            &.minimize-input {
              width: 0px !important;
              padding: 5px 0 !important;
              flex: unset;

              &:focus {
                flex: 1 0 100px;
              }

              &:focus-within {
                flex: 1 0 100px;
              }

              &:focus-visible {
                flex: 1 0 100px;
              }

              &:-moz-focusring {
                flex: 1 0 100px;
              }

              &::-moz-focus-inner {
                flex: 1 0 100px;
              }
            }

            &[ng-reflect-readonly="true"] {
              cursor: pointer !important;
            }

            &::placeholder {
              color: var(--color-silver) !important;
              opacity: 1;
              /* Firefox */
            }

            &::-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: var(--color-silver) !important;
            }
          }
        }

        .mat-form-field-prefix {
          min-height: 24px !important;
          border-radius: 4px 0 0 4px;
          position: absolute;
          top: 5px !important;
          left: 1px !important;
          bottom: 1px;
          height: inherit !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          .material-icons-round {
            color: var(--color-silver) !important;
            margin: 0 13px !important;
          }
        }

        .mat-form-field-suffix {
          min-height: 36px !important;
          border-radius: 0;
          position: absolute;
          top: 1px !important;
          right: 1px !important;
          bottom: 1px;
          height: inherit !important;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          padding-left: 0 !important;
          padding-right: 0 !important;
          border-left: 1px solid var(--color-nightshade);

          .material-icons-round {
            color: var(--color-nightshade) !important;
            margin: 0 13px !important;
            font-size: 24px !important;
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }
  }
}

.people-autocomplete-with-phone-number{
  .mat-option{
    height: unset !important;
    padding: 0 4px 0 12px !important;
  }
}

.mat-autocomplete-panel{
  box-shadow: 0px 2px 4px 0px #00000040 !important;
}