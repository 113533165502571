input.country-search {
  width: 100%;
  height: 34px;
  padding: 20px;
  border: none;
  border-bottom: 1px solid var(--color-disabled);
  font-size: 14px;
  padding-bottom: 24px;
}

.icon-wrapper {
  padding-right: 24px;
}

.flag {
  background-image: url(https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png);
  background-size: 100% auto;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .54));
  height: 14px;
  width: 24px;
}

.icon-wrapper, .label-wrapper {
  display: table-cell;
  vertical-align: top;
}

.country-selector {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/arrow_drop_down_grey600_18dp.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 18px auto;
  border-radius: 0;
  color: rgba(0, 0, 0, .87);
  flex-shrink: 0;
  height: initial;
  line-height: unset;
  width: 90px;
  opacity: 0;
  transition: opacity 200ms;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 1px;
  right: auto;
  left: 0;
  font-size: inherit;
  font-weight: inherit;
  z-index: 1;
}

:host.ngx-floating .country-selector {
  opacity: 1 !important;
}

.country-selector-flag {
  display: inline-block;
  margin-right: 0.5ex;
}

.country-list-button {
  color: rgba(0, 0, 0, .87);
  direction: ltr;
  font-size: 16px;
  font-weight: 400;
  height: initial;
  line-height: normal;
  min-height: 48px;
  padding: 14px 24px;
  text-align: left;
  text-transform: none;
  width: 100%;
}

.flag.KY {
  background-position: 0 0
}

.flag.AC {
  background-position: 0 -14px
}

.flag.AE {
  background-position: 0 -28px
}

.flag.AF {
  background-position: 0 -42px
}

.flag.AG {
  background-position: 0 -56px
}

.flag.AI {
  background-position: 0 -70px
}

.flag.AL {
  background-position: 0 -84px
}

.flag.AM {
  background-position: 0 -98px
}

.flag.AO {
  background-position: 0 -112px
}

.flag.AQ {
  background-position: 0 -126px
}

.flag.AR {
  background-position: 0 -140px
}

.flag.AS {
  background-position: 0 -154px
}

.flag.AT {
  background-position: 0 -168px
}

.flag.AU {
  background-position: 0 -182px
}

.flag.AW {
  background-position: 0 -196px
}

.flag.AX {
  background-position: 0 -210px
}

.flag.AZ {
  background-position: 0 -224px
}

.flag.BA {
  background-position: 0 -238px
}

.flag.BB {
  background-position: 0 -252px
}

.flag.BD {
  background-position: 0 -266px
}

.flag.BE {
  background-position: 0 -280px
}

.flag.BF {
  background-position: 0 -294px
}

.flag.BG {
  background-position: 0 -308px
}

.flag.BH {
  background-position: 0 -322px
}

.flag.BI {
  background-position: 0 -336px
}

.flag.BJ {
  background-position: 0 -350px
}

.flag.BL {
  background-position: 0 -364px
}

.flag.BM {
  background-position: 0 -378px
}

.flag.BN {
  background-position: 0 -392px
}

.flag.BO {
  background-position: 0 -406px
}

.flag.BQ {
  background-position: 0 -420px
}

.flag.BR {
  background-position: 0 -434px
}

.flag.BS {
  background-position: 0 -448px
}

.flag.BT {
  background-position: 0 -462px
}

.flag.BV {
  background-position: 0 -476px
}

.flag.BW {
  background-position: 0 -490px
}

.flag.BY {
  background-position: 0 -504px
}

.flag.BZ {
  background-position: 0 -518px
}

.flag.CA {
  background-position: 0 -532px
}

.flag.CC {
  background-position: 0 -546px
}

.flag.CD {
  background-position: 0 -560px
}

.flag.CF {
  background-position: 0 -574px
}

.flag.CG {
  background-position: 0 -588px
}

.flag.CH {
  background-position: 0 -602px
}

.flag.CI {
  background-position: 0 -616px
}

.flag.CK {
  background-position: 0 -630px
}

.flag.CL {
  background-position: 0 -644px
}

.flag.CM {
  background-position: 0 -658px
}

.flag.CN {
  background-position: 0 -672px
}

.flag.CO {
  background-position: 0 -686px
}

.flag.CP {
  background-position: 0 -700px
}

.flag.CR {
  background-position: 0 -714px
}

.flag.CU {
  background-position: 0 -728px
}

.flag.CV {
  background-position: 0 -742px
}

.flag.CW {
  background-position: 0 -756px
}

.flag.CX {
  background-position: 0 -770px
}

.flag.CY {
  background-position: 0 -784px
}

.flag.CZ {
  background-position: 0 -798px
}

.flag.DE {
  background-position: 0 -812px
}

.flag.DG {
  background-position: 0 -826px
}

.flag.DJ {
  background-position: 0 -840px
}

.flag.DK {
  background-position: 0 -854px
}

.flag.DM {
  background-position: 0 -868px
}

.flag.DO {
  background-position: 0 -882px
}

.flag.DZ {
  background-position: 0 -896px
}

.flag.EA {
  background-position: 0 -910px
}

.flag.EC {
  background-position: 0 -924px
}

.flag.EE {
  background-position: 0 -938px
}

.flag.EG {
  background-position: 0 -952px
}

.flag.EH {
  background-position: 0 -966px
}

.flag.ER {
  background-position: 0 -980px
}

.flag.ES {
  background-position: 0 -994px
}

.flag.ET {
  background-position: 0 -1008px
}

.flag.EU {
  background-position: 0 -1022px
}

.flag.FI {
  background-position: 0 -1036px
}

.flag.FJ {
  background-position: 0 -1050px
}

.flag.FK {
  background-position: 0 -1064px
}

.flag.FM {
  background-position: 0 -1078px
}

.flag.FO {
  background-position: 0 -1092px
}

.flag.FR {
  background-position: 0 -1106px
}

.flag.GA {
  background-position: 0 -1120px
}

.flag.GB {
  background-position: 0 -1134px
}

.flag.GD {
  background-position: 0 -1148px
}

.flag.GE {
  background-position: 0 -1162px
}

.flag.GF {
  background-position: 0 -1176px
}

.flag.GG {
  background-position: 0 -1190px
}

.flag.GH {
  background-position: 0 -1204px
}

.flag.GI {
  background-position: 0 -1218px
}

.flag.GL {
  background-position: 0 -1232px
}

.flag.GM {
  background-position: 0 -1246px
}

.flag.GN {
  background-position: 0 -1260px
}

.flag.GP {
  background-position: 0 -1274px
}

.flag.GQ {
  background-position: 0 -1288px
}

.flag.GR {
  background-position: 0 -1302px
}

.flag.GS {
  background-position: 0 -1316px
}

.flag.GT {
  background-position: 0 -1330px
}

.flag.GU {
  background-position: 0 -1344px
}

.flag.GW {
  background-position: 0 -1358px
}

.flag.GY {
  background-position: 0 -1372px
}

.flag.HK {
  background-position: 0 -1386px
}

.flag.HM {
  background-position: 0 -1400px
}

.flag.HN {
  background-position: 0 -1414px
}

.flag.HR {
  background-position: 0 -1428px
}

.flag.HT {
  background-position: 0 -1442px
}

.flag.HU {
  background-position: 0 -1456px
}

.flag.IC {
  background-position: 0 -1470px
}

.flag.ID {
  background-position: 0 -1484px
}

.flag.IE {
  background-position: 0 -1498px
}

.flag.IL {
  background-position: 0 -1512px
}

.flag.IM {
  background-position: 0 -1526px
}

.flag.IN {
  background-position: 0 -1540px
}

.flag.IO {
  background-position: 0 -1554px
}

.flag.IQ {
  background-position: 0 -1568px
}

.flag.IR {
  background-position: 0 -1582px
}

.flag.IS {
  background-position: 0 -1596px
}

.flag.IT {
  background-position: 0 -1610px
}

.flag.JE {
  background-position: 0 -1624px
}

.flag.JM {
  background-position: 0 -1638px
}

.flag.JO {
  background-position: 0 -1652px
}

.flag.JP {
  background-position: 0 -1666px
}

.flag.KE {
  background-position: 0 -1680px
}

.flag.KG {
  background-position: 0 -1694px
}

.flag.KH {
  background-position: 0 -1708px
}

.flag.KI {
  background-position: 0 -1722px
}

.flag.KM {
  background-position: 0 -1736px
}

.flag.KN {
  background-position: 0 -1750px
}

.flag.KP {
  background-position: 0 -1764px
}

.flag.KR {
  background-position: 0 -1778px
}

.flag.KW {
  background-position: 0 -1792px
}

.flag.AD {
  background-position: 0 -1806px
}

.flag.KZ {
  background-position: 0 -1820px
}

.flag.LA {
  background-position: 0 -1834px
}

.flag.LB {
  background-position: 0 -1848px
}

.flag.LC {
  background-position: 0 -1862px
}

.flag.LI {
  background-position: 0 -1876px
}

.flag.LK {
  background-position: 0 -1890px
}

.flag.LR {
  background-position: 0 -1904px
}

.flag.LS {
  background-position: 0 -1918px
}

.flag.LT {
  background-position: 0 -1932px
}

.flag.LU {
  background-position: 0 -1946px
}

.flag.LV {
  background-position: 0 -1960px
}

.flag.LY {
  background-position: 0 -1974px
}

.flag.MA {
  background-position: 0 -1988px
}

.flag.MC {
  background-position: 0 -2002px
}

.flag.MD {
  background-position: 0 -2016px
}

.flag.ME {
  background-position: 0 -2030px
}

.flag.MF {
  background-position: 0 -2044px
}

.flag.MG {
  background-position: 0 -2058px
}

.flag.MH {
  background-position: 0 -2072px
}

.flag.MK {
  background-position: 0 -2086px
}

.flag.ML {
  background-position: 0 -2100px
}

.flag.MM {
  background-position: 0 -2114px
}

.flag.MN {
  background-position: 0 -2128px
}

.flag.MO {
  background-position: 0 -2142px
}

.flag.MP {
  background-position: 0 -2156px
}

.flag.MQ {
  background-position: 0 -2170px
}

.flag.MR {
  background-position: 0 -2184px
}

.flag.MS {
  background-position: 0 -2198px
}

.flag.MT {
  background-position: 0 -2212px
}

.flag.MU {
  background-position: 0 -2226px
}

.flag.MV {
  background-position: 0 -2240px
}

.flag.MW {
  background-position: 0 -2254px
}

.flag.MX {
  background-position: 0 -2268px
}

.flag.MY {
  background-position: 0 -2282px
}

.flag.MZ {
  background-position: 0 -2296px
}

.flag.NA {
  background-position: 0 -2310px
}

.flag.NC {
  background-position: 0 -2324px
}

.flag.NE {
  background-position: 0 -2338px
}

.flag.NF {
  background-position: 0 -2352px
}

.flag.NG {
  background-position: 0 -2366px
}

.flag.NI {
  background-position: 0 -2380px
}

.flag.NL {
  background-position: 0 -2394px
}

.flag.NO {
  background-position: 0 -2408px
}

.flag.NP {
  background-position: 0 -2422px
}

.flag.NR {
  background-position: 0 -2436px
}

.flag.NU {
  background-position: 0 -2450px
}

.flag.NZ {
  background-position: 0 -2464px
}

.flag.OM {
  background-position: 0 -2478px
}

.flag.PA {
  background-position: 0 -2492px
}

.flag.PE {
  background-position: 0 -2506px
}

.flag.PF {
  background-position: 0 -2520px
}

.flag.PG {
  background-position: 0 -2534px
}

.flag.PH {
  background-position: 0 -2548px
}

.flag.PK {
  background-position: 0 -2562px
}

.flag.PL {
  background-position: 0 -2576px
}

.flag.PM {
  background-position: 0 -2590px
}

.flag.PN {
  background-position: 0 -2604px
}

.flag.PR {
  background-position: 0 -2618px
}

.flag.PS {
  background-position: 0 -2632px
}

.flag.PT {
  background-position: 0 -2646px
}

.flag.PW {
  background-position: 0 -2660px
}

.flag.PY {
  background-position: 0 -2674px
}

.flag.QA {
  background-position: 0 -2688px
}

.flag.RE {
  background-position: 0 -2702px
}

.flag.RO {
  background-position: 0 -2716px
}

.flag.RS {
  background-position: 0 -2730px
}

.flag.RU {
  background-position: 0 -2744px
}

.flag.RW {
  background-position: 0 -2758px
}

.flag.SA {
  background-position: 0 -2772px
}

.flag.SB {
  background-position: 0 -2786px
}

.flag.SC {
  background-position: 0 -2800px
}

.flag.SD {
  background-position: 0 -2814px
}

.flag.SE {
  background-position: 0 -2828px
}

.flag.SG {
  background-position: 0 -2842px
}

.flag.SH {
  background-position: 0 -2856px
}

.flag.SI {
  background-position: 0 -2870px
}

.flag.SJ {
  background-position: 0 -2884px
}

.flag.SK {
  background-position: 0 -2898px
}

.flag.SL {
  background-position: 0 -2912px
}

.flag.SM {
  background-position: 0 -2926px
}

.flag.SN {
  background-position: 0 -2940px
}

.flag.SO {
  background-position: 0 -2954px
}

.flag.SR {
  background-position: 0 -2968px
}

.flag.SS {
  background-position: 0 -2982px
}

.flag.ST {
  background-position: 0 -2996px
}

.flag.SV {
  background-position: 0 -3010px
}

.flag.SX {
  background-position: 0 -3024px
}

.flag.SY {
  background-position: 0 -3038px
}

.flag.SZ {
  background-position: 0 -3052px
}

.flag.TA {
  background-position: 0 -3066px
}

.flag.TC {
  background-position: 0 -3080px
}

.flag.TD {
  background-position: 0 -3094px
}

.flag.TF {
  background-position: 0 -3108px
}

.flag.TG {
  background-position: 0 -3122px
}

.flag.TH {
  background-position: 0 -3136px
}

.flag.TJ {
  background-position: 0 -3150px
}

.flag.TK {
  background-position: 0 -3164px
}

.flag.TL {
  background-position: 0 -3178px
}

.flag.TM {
  background-position: 0 -3192px
}

.flag.TN {
  background-position: 0 -3206px
}

.flag.TO {
  background-position: 0 -3220px
}

.flag.TR {
  background-position: 0 -3234px
}

.flag.TT {
  background-position: 0 -3248px
}

.flag.TV {
  background-position: 0 -3262px
}

.flag.TW {
  background-position: 0 -3276px
}

.flag.TZ {
  background-position: 0 -3290px
}

.flag.UA {
  background-position: 0 -3304px
}

.flag.UG {
  background-position: 0 -3318px
}

.flag.UM {
  background-position: 0 -3332px
}

.flag.UN {
  background-position: 0 -3346px
}

.flag.US {
  background-position: 0 -3360px
}

.flag.UY {
  background-position: 0 -3374px
}

.flag.UZ {
  background-position: 0 -3388px
}

.flag.VA {
  background-position: 0 -3402px
}

.flag.VC {
  background-position: 0 -3416px
}

.flag.VE {
  background-position: 0 -3430px
}

.flag.VG {
  background-position: 0 -3444px
}

.flag.VI {
  background-position: 0 -3458px
}

.flag.VN {
  background-position: 0 -3472px
}

.flag.VU {
  background-position: 0 -3486px
}

.flag.WF {
  background-position: 0 -3500px
}

.flag.WS {
  background-position: 0 -3514px
}

.flag.XK {
  background-position: 0 -3528px
}

.flag.YE {
  background-position: 0 -3542px
}

.flag.YT {
  background-position: 0 -3556px
}

.flag.ZA {
  background-position: 0 -3570px
}

.flag.ZM {
  background-position: 0 -3584px
}

.flag.ZW {
  background-position: 0 -3598px
}





li.country:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.selected-flag.dropdown-toggle:after {
  content: none;
}

.flag-container.disabled {
  cursor: default !important;
}

.intl-tel-input.allow-dropdown .flag-container.disabled:hover .selected-flag {
  background: none;
}

.country-dropdown {
  border: 1px solid var(--color-smoke-platinum);
  width: fit-content;
  padding: 1px;
  border-collapse: collapse;
}

.search-container {
  position: relative;
}

.search-container input {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-smoke-platinum);
  padding-left: 10px;
}

.search-icon {
  position: absolute;
  z-index: 2;
  width: 25px;
  margin: 1px 10px;
}

.country-list {
  position: relative;
  border: none;
}

.intl-tel-input input#country-search-box {
  padding-left: 6px;
}

.intl-tel-input.separate-dial-code .selected-flag,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
  width: 93px;
}


.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input {
  padding-left: 98px;
}
