.map-box {
  border-radius: 13px;
  width: 100%;
  height: 500px;

  .map {
    height: 500px;
    border-radius: 13px;

    .agm-map-container-inner {
      border-radius: 13px;

      button {
        margin: 0 !important;
      }
    }
  }
}

@media screen and (max-width : 767px) {
  .pac-container {
    overflow-y: auto !important;
    height: 230px !important;
    color: var(--color-london-gray) !important;
    margin-left: 5px;
    box-shadow: none !important;
    margin-top: 4px;

    .pac-item {
      color: var(--color-london-gray) !important;
      font-family: "Brandon Text" !important;
      font-size: 16px !important;
      line-height: 22px !important;
      padding: 15px;
      cursor: pointer;

      .pac-icon {
        display: none;
      }

      .pac-item-query,
      span {
        color: var(--color-london-gray) !important;
        font-family: "Brandon Text" !important;
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: normal !important;
      }
    }
  }

  .pac-container::after {
    display: none;
  }

  .pac-container::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .pac-container::-webkit-scrollbar {
    width: 7px;
    background-color: var(--color-snow);
  }

  .pac-container::-webkit-scrollbar-thumb {
    background-color: var(--color-silver);
    border-radius: 13px;
  }
}


@media screen and (min-width : 768px) {
  .pac-container {
    overflow-y: auto !important;
    height: 235px !important;
    color: var(--color-london-gray) !important;
    margin-left: 5px;
    box-shadow: none !important;
    margin-top: 4px;

    .pac-item {
      color: var(--color-london-gray) !important;
      font-family: "Brandon Text" !important;
      font-size: 16px !important;
      line-height: 22px !important;
      padding: 15px;
      cursor: pointer;

      .pac-icon {
        display: none;
      }

      .pac-item-query,
      span {
        color: var(--color-london-gray) !important;
        font-family: "Brandon Text" !important;
        font-size: 16px !important;
        line-height: 22px !important;
        font-weight: normal !important;
      }
    }
  }

  .pac-container::after {
    display: none;
  }

  .pac-container::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .pac-container::-webkit-scrollbar {
    width: 19px;
    background-color: var(--color-snow);
  }

  .pac-container::-webkit-scrollbar-thumb {
    background-color: var(--color-silver);
  }
}

.hamburger-icon {
  display: inline-block;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
  }
}

.modified-pac-container {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  margin-left: 2px !important;
}

.no-product-available {
  color: var(--color-warning);
  font-family: "Brandon Text" !important;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
  padding-top: 20px;
  height: calc(100vh - 529px);
  min-height: 250px;
}

.inclusions-exclusions {
  column-gap: 24px;

  .inclusions {
    ul {
      list-style-image: url('../../assets/images/icon/reward/right_check.svg');

      li {
        margin-bottom: 8px;
      }
    }
  }

  .exclusions {
    ul {
      list-style-image: url('../../assets/images/icon/reward/close_black_icon.svg');

      li {
        margin-bottom: 8px;
      }
    }
  }
}

.meetup,
.pickup {
  .title {
    font-family: "Brandon Text Bold";
  }
}

.meetup-pickup-container {
  .meetup-pickup {
    display: flex;
    column-gap: 12px;
  }

  .highlight {
    &:hover {
      color: var(--color-mountain-purple-hover);
      text-decoration: underline;
    }
  }
}

.itinerary-steps {

  span {
    .itinerary-steps__list {
      margin-top: 24px;

      .itinarary-step {
        display: flex;
      }

      .itinarary-step:not(:last-of-type) {
        background: linear-gradient(90deg, white 0%, white 12px, black 12px, black 13px, white 14px);
        padding-bottom: 24px;
      }

      .itinarary-step {

        .bullet-container {
          padding: 0 !important;
          margin-right: 12px;
          width: 24px !important;
          height: 24px !important;
          background-color: var(--color-nightshade);
          border-radius: 50%;
          color: var(--color-pure-white);
          border: none;
          outline: none;
          min-width: 24px;
          display: grid;
          place-content: center;
        }

        .pass-by-container {
          min-width: 12px !important;
          width: 12px !important;
          height: 12px !important;
          transform: translate(7px, 0px);
          background: black;
          border-radius: 50%;
          margin-right: 24px;
          background-color: var(--color-nightshade);
        }

        .itinarary-details {
          .location-name {
            padding-bottom: 12px;
            font-family: 'Brandon Text Bold';
            font-size: 16px;
            line-height: 22px;
            color: var(--color-nightshade);
          }

          .location-description {
            padding-bottom: 12px;
            font-family: 'Brandon Text';
            font-size: 16px;
            line-height: 22px;
            color: var(--color-nightshade);
          }

          .duration-flex {
            display: flex;
            align-items: center;
            column-gap: 5px;
            height: 25px;
            font-size: 12px;
            font-family: 'Brandon Text';

            .timing-flex {
              align-items: center;
              column-gap: 2px;
            }

            .dot {
              width: 5px;
              height: 5px;
              background-color: var(--color-disabled);
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}