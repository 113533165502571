.ngx-json-viewer {
  .segment {
    .segment-key {
      color: var(--color-nightshade) !important;
      background: transparent !important;
    }

    .segment-separator {
      color: var(--color-london-gray) !important;
      background: transparent !important;
    }

    .segment-value {
      background: transparent !important;
      color: var(--color-success-green) !important;
    }
  }
}
