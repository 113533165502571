@media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) {
  .mobile-device-dialog-2 {
    min-height: 100vh !important;
    max-height: 100vh !important;
    height: 100vh !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;

    .mat-dialog-container {
      background: var(--color-pure-white) !important;

      ::i-block-chrome, .component {
        height: calc(100vh - 122px);
        padding-top: 122px;
        width: 100vw;
        position: relative;

        .dialog::-webkit-scrollbar-track {
          background-color: transparent;
        }

        .dialog::-webkit-scrollbar {
          width: 7px;
          background-color: var(--color-snow);
        }

        .dialog::-webkit-scrollbar-thumb {
          background-color: var(--color-silver);
          border-radius: 13px;
        }

        ::i-block-chrome, .dialog {
          min-height: calc(100vh - 144px);
          max-height: calc(100vh - 144px);
          height: calc(100vh - 144px);
          max-width: 100vw;
          min-width: 100vw;
          width: 100vw;
          padding: 0 !important;
          overflow-y: auto;
          overflow-x: hidden;

          .close-dialog {
            position: fixed;
            top: 0px !important;
            z-index: 1002;
            display: block !important;
            width: 100%;
            height: 80px;
            text-align: right;
            box-shadow: 0 1px 5px 0 rgba(0,0,0,0.5);

            .mat-icon {
              margin: 0 !important;
              width: 32px !important;
              height: 32px !important;
              font-size: 32px !important;
              margin-top: 24px !important;
              margin-right: 20px !important;
              cursor: pointer;
            }
          }

          .container {
            width: 100vw;
            background: var(--color-pure-white);

            ::i-block-chrome, .container-content {
              min-height: calc(100vh - 216px);
              padding: 24px;
            }

            .container-button {
              box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
              padding: 24px 40px;
              margin: 0 !important;

              .close-dialog-button {
                width: 100%;
                margin: 0 !important;
                height: 40px;
                padding: 0 !important;
              }
            }
          }
        }
      }
    }
  }


  ::i-block-chrome, .mat-form-field-appearance-outline {

    .mat-form-field-prefix, .mat-form-field-suffix {
      top: -17px !important;
    }
  }

  ::i-block-chrome, .input-field.date-picker-input.prefix.center-text {
    .mat-form-field-appearance-outline {

      ::i-block-chrome, .mat-form-field-prefix {
        left: calc(50% - 30px) !important;
        top: -17px !important;
        margin-left: -26px;

        .mat-datepicker-toggle {
          color: var(--color-london-gray);
          width: 18px;
          height: 18px;
          font-size: 18px;

          .mat-icon-button {
            color: var(--color-london-gray);
            width: 18px;
            height: 18px;
            font-size: 18px;

            .mat-button-wrapper {
              .mat-datepicker-toggle-default-icon {
                color: var(--color-london-gray);
                width: 18px;
                height: 18px;
                font-size: 18px;
              }
            }
          }
        }
      }

      input {
        text-align: left !important;
        width: 100% !important;
        padding-left: calc(50% - 25px) !important;
      }
    }
  }
}
