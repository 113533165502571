.ng-otp-input-wrapper {
  .otp-input {
    text-align: center !important;
    font-size: 28px !important;
    border: solid 1px #B9BABA !important;
    font-family: "Brandon Text";
    color: var(--color-nightshade);

    &:not(:last-child) {
      margin-right: 12px !important;
    }

    &:focus-visible {
      border-color: #6876EA !important;
      outline: none !important;
    }
  }
}
