.mat-radio-button {
  display: block;

  .mat-radio-label-content {
    word-break: break-word !important;
    white-space: break-spaces !important;
    color: var(--color-nightshade) !important;
  }

  &.mat-radio-checked {
    .mat-radio-container {
      &:hover {
        .mat-radio-outer-circle {
          background-color: transparent !important;
        }
      }
    }

    .mat-radio-outer-circle {
      border-color: var(--color-mountain-purple) !important;
    }

    .mat-radio-inner-circle {
      background-color: var(--color-mountain-purple) !important;
    }
  }

  .mat-radio-container {
    .mat-radio-ripple {
      display: none !important;
    }

    .mat-radio-outer-circle {
      border-color: var(--color-mountain-purple) !important;
    }

    &:hover {
      .mat-radio-outer-circle {
        background-color: var(--color-mountain-purple) !important;
      }
    }
  }
}
