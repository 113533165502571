.mat-checkbox {
  padding: 0;

  &.mat-checkbox-indeterminate,
  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background: var(--color-mountain-purple) !important;
    }
  }

  .mat-checkbox-inner-container {
    .mat-checkbox-ripple {
      display: none !important;
    }

    .mat-checkbox-frame {
      border-radius: 4px !important;
      border-width: 2px !important;
      border-color: var(--color-mountain-purple) !important;
    }

    .mat-checkbox-background {
      border-radius: 4px !important;
    }
  }

  .mat-checkbox-label {
    font-size: 14px;
    line-height: 19px;
    color: var(--color-nightshade);
  }
}

.mat-checkbox-dark-skin {
  .mat-checkbox {
    padding: 0;

    &.mat-checkbox-indeterminate,
    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background: var(--color-nightshade) !important;
      }
    }

    .mat-checkbox-inner-container {
      .mat-checkbox-frame {
        border-width: 1.6px !important;
        border-color: var(--color-nightshade) !important;
      }
    }
  }
}

.product-Input {
  .mat-checkbox-layout {
    .mat-checkbox-label {
      margin-left: 4px !important;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}