:root {
  --color-notification: #EB2138;
  --color-warning: #FF9900;
  --color-disabled: #B9B9B9;
  --color-silver: #C0C2C8;
  --color-gold-star: #F6BD5A;
  --color-success-green: #47968B;
  --color-see-green: #1B988B;
  --color-parrot-green: #79C9A6;
  --color-nightshade: #303C4F;
  --color-london-gray: #5F7280;
  --color-denver-sky: #8BDBF4;
  --color-pure-white: #FFFFFF;
  --color-smoke: #ECEDEF;
  --color-mountain-purple: #6876EB;
  --color-mountain-purple-hover: #505FD7;
  --color-action-secondary: #FCF4FD;
  --color-sapphire-blue: #356F9E;
  --color-pond-blue: #E9F3FE;
  --color-periwinkle: #DBCEF2;
  --color-smoke-platinum: #E5E5E5;
  --color-smoke-white: #F7F7F7;
  --color-snow: #FBFBFE;
  --color-background: #ECEDEf;
  --color-pure-black: #000000;
  --color-apple-green: #97BF0D;
  --color-floral-white: #FDFAF1;
  --color-sso-login-btn-border: rgba(50, 60, 77, 0.3);
  --color-london-light-gray: #858484;
  --color-london-dark-gray: #555454;
  --color-pond-dark-gray: #C1C1C1;
  --color-dark-nightshade: #323C4D;
  --color-light-silver: #A6A6A6;
  --star-size: 20px;
  --star-color: #fff;
  --star-background: #fc0;
  --color-border-dark: #B9BABA;
  --color-pond-blue: #EBF3FD;
  --color-hover-grey: #000000CC;
  --color-wl-url: #6876EA;
  --color-ngx-loader: var(--color-silver);
  --color-sso-login-btn-border-hover: #323C4D4D;
  --color-ngx-loader: var(--color-silver);
  --color-semi-transparent-dark-grey: #A9A9A999;
}

.background {
  background: var(--color-background) !important;
}

.bg-transparent {
  background: transparent !important;
}

.white-background {
  background: var(---color-pure-white);
}

.bg-primary {
  background-color: var(--color-nightshade) !important;
}

.primary {
  color: var(--color-nightshade) !important;
}

.bg-secondary {
  background-color: var(--color-london-gray) !important;
}

.secondary {
  color: var(--color-london-gray) !important;
}

.bg-highlight {
  background-color: var(--color-mountain-purple) !important;
}

.highlight {
  color: var(--color-mountain-purple) !important;
}

.required,
.error {
  color: var(--color-notification) !important;
}

.bg-error {
  background-color: var(--color-notification) !important;
}

.success {
  color: var(--color-success-green) !important;
}

.bg-success {
  background-color: var(--color-success-green) !important;
}

.warning {
  color: var(--color-warning) !important;
}

.bg-warning {
  background-color: var(--color-warning) !important;
}

.hover {
  color: var(--color-mountain-purple-hover) !important;
}

.bg-hover {
  background-color: var(--color-mountain-purple-hover) !important;
}

.disabled {
  color: var(--color-disabled) !important;
  cursor: default !important;
}

.bg-disabled {
  background-color: var(--color-disabled) !important;
}

.flamingo {
  color: var(--color-smoke) !important;
}

.bg-flamingo {
  background-color: var(--color-smoke) !important;
}

.pond {
  color: var(--color-pond-blue) !important;
}

.bg-pond {
  background-color: var(--color-pond-blue) !important;
}

.smoke {
  color: var(--color-smoke-platinum) !important;
}

.bg-smoke {
  background-color: var(--color-smoke-platinum) !important;
}

.white {
  color: var(--color-pure-white) !important;
}

.bg-white {
  background-color: var(--color-pure-white) !important;
}

.yellow {
  color: var(--color-warning) !important;
}

.bg-yellow {
  background-color: var(--color-warning) !important;
}

.storm {
  color: var(--color-disabled) !important;
}

.bg-storm {
  background-color: var(--color-disabled) !important;
}

.sea-green {
  color: var(--color-see-green) !important;
}

.bg-sea-green {
  background-color: var(--color-see-green) !important;
}

.cold-mountain {
  color: var(--color-sapphire-blue) !important;
}

.bg-cold-mountain {
  background-color: var(--color-sapphire-blue) !important;
}

.denvor-sky {
  color: var(--color-denver-sky) !important;
}

.bg-denvor-sky {
  background-color: var(--color-denver-sky) !important;
}

.disable {
  color: var(--color-silver) !important;
}

.bg-disable {
  background-color: var(--color-silver) !important;
}

.churchill {
  color: var(--color-smoke-platinum) !important;
}

.bg-churchill {
  background-color: var(--color-smoke-platinum) !important;
}

.snow {
  color: var(---color-snow) !important;
}

.bg--color-snow {
  background-color: var(---color-snow) !important;
}


.squall {
  color: var(--color-silver) !important;
}

.bg-squall {
  background-color: var(--color-silver) !important;
}

.gold-star {
  color: var(--color-gold-star) !important;
}

.bg-gold-star {
  background-color: var(--color-gold-star) !important;
}

.lavender-wave {
  color: var(--color-periwinkle) !important;
}

.bg-lavender-wave {
  background-color: var(--color-periwinkle) !important;
}