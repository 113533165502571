.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-stepper-horizontal {
  height: 100%;

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover {
    background-color: transparent;
  }

  .mat-horizontal-stepper-header-container {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5) !important;
    border-radius: 3px;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 1001;
    position: absolute;
    width: 100%;
    background: var(--color-pure-white);
    top: 0 !important;
    left: 0 !important;

    .mat-stepper-horizontal-line {
      display: none !important;
    }

    .mat-step-header::before,
    .mat-step-header::after {
      display: none !important;
    }

    .mat-step-header:first-child {
      .mat-step-icon::after {
        display: none !important;
      }
    }

    .mat-step-header:last-child {
      .mat-step-icon::before {
        display: none !important;
      }
    }

    .mat-step-header {
      padding: 10px 0 0 0 !important;
      width: 104px;
      height: 126px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;

      .mat-step-label {
        padding: 2px 0 0 0 !important;
      }

      .mat-step-icon {
        width: 36px;
        height: 36px;
      }

      .mat-step-icon::after {
        border-top-color: rgba(0, 0, 0, .12);
        border-top-width: 1px;
        border-top-style: solid;
        content: '';
        display: inline-block;
        height: 0;
        position: absolute;
        top: 18px;
        width: 100%;
        right: 38px;
      }

      .mat-step-icon::before {
        border-top-color: rgba(0, 0, 0, .12);
        border-top-width: 1px;
        border-top-style: solid;
        content: '';
        display: inline-block;
        height: 0;
        position: absolute;
        top: 18px;
        width: 100%;
        left: 38px;
      }
    }

    .mat-step-header[aria-selected="false"][tabindex="-1"] {
      .mat-step-icon-state-number.mat-step-icon::before {
        border-top-color: rgba(0, 0, 0, .12) !important;
      }

      .mat-step-icon-state-number.mat-step-icon::after {
        border-top-color: rgba(0, 0, 0, .12) !important;
      }

      .mat-step-icon-state-number {
        background-color: var(--color-pure-white);
        border: 2px solid var(--color-silver);

        .mat-step-icon-content {
          color: var(--color-silver);

          span {
            color: var(--color-silver);
          }
        }
      }

      .mat-step-label {
        color: var(--color-silver);
        font-size: 14px;
      }
    }

    .mat-step-header[aria-selected="true"][tabindex="0"] {

      .mat-step-icon-state-number.mat-step-icon::before {
        border-top-color: rgba(0, 0, 0, .12) !important;
      }

      .mat-step-icon-state-number.mat-step-icon::after {
        border-top-color: var(--color-nightshade) !important;
      }

      .mat-step-icon-state-number {
        background-color: transparent !important;
        border: 2px solid var(--color-mountain-purple);

        .mat-step-icon-content {
          color: var(--color-mountain-purple) !important;

          span {
            color: var(--color-mountain-purple) !important;
          }
        }
      }

      .mat-step-label {
        color: var(--color-mountain-purple) !important;
        font-size: 14px;
      }
    }

    .mat-step-header[aria-selected="false"][tabindex="-1"] {

      .mat-step-icon-state-edit.mat-step-icon::before {
        border-top-color: var(--color-nightshade);
      }

      .mat-step-icon-state-edit.mat-step-icon::after {
        border-top-color: var(--color-nightshade);
      }

      .mat-step-icon-state-edit {
        background-color: transparent !important;
        border: 2px solid var(--color-nightshade) !important;
        position: relative;

        .mat-step-icon-content {

          .material-icons {
            color: var(--color-nightshade) !important;
            margin: 0;
          }
        }
      }

      .mat-step-label.mat-step-label-active {
        color: var(--color-nightshade);
        font-size: 14px;
      }
    }
  }

  .mat-horizontal-content-container {
    height: calc(100vh - 126px) !important;
    padding: 126px 0 0 0 !important;
    overflow-y: auto;
  }
}

.mat-stepper-label-position-bottom,
[dir=rtl] .mat-stepper-label-position-bottom {

  .mat-horizontal-stepper-header:not(:first-child)::before,
  .mat-horizontal-stepper-header:not(:last-child)::after {
    width: calc(50% - 19px) !important;
    top: 44px !important;
  }
}

.mobile-stepper.stepper {
  position: relative;

  .close-circle {
    right: 20px;
    top: 13px;
    z-index: 1002;
    background: var(--color-pure-white) !important;
    position: absolute;

    .mat-icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
      display: grid;
      place-content: center;
      cursor: pointer;
      color: var(--color-mountain-purple);

      &:hover {
        color: var(--color-mountain-purple-hover);
      }
    }
  }

  .mat-stepper-horizontal {
    height: 100%;

    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header.cdk-program-focused,
    .mat-step-header:hover {
      background-color: transparent;
    }

    .mat-horizontal-stepper-header-container {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5) !important;
      border-radius: 3px;
      text-align: center;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      padding-top: 0px;
      padding-bottom: 0px;
      z-index: 1001;
      position: absolute;
      width: 100%;
      background: var(--color-pure-white);
      top: 0 !important;
      left: 0 !important;

      .mat-stepper-horizontal-line {
        display: none !important;
      }

      .mat-step-header::before,
      .mat-step-header::after {
        display: none !important;
      }

      .mat-step-header {
        padding: 10px 0 0 0 !important;
        width: 30px;
        height: 50px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;



        .mat-step-icon {
          width: 16px !important;
          height: 16px !important;

          .mat-step-icon-content {
            display: none !important;

            span {
              display: none !important;
            }

            .mat-icon {
              display: none !important;
            }
          }
        }

        .mat-step-icon::before,
        .mat-step-icon::after {
          display: none !important;
        }

        .mat-step-label {
          display: none !important;
          padding: 0 !important;
          margin: 0 !important;
        }
      }

      .mat-step-header[aria-selected="false"][tabindex="-1"] {

        .mat-step-icon-state-number {
          background-color: var(--color-silver) !important;
          border: 2px solid var(--color-silver) !important;
        }
      }

      .mat-step-header[aria-selected="true"][tabindex="0"] {

        .mat-step-icon-state-number {
          background-color: var(--color-denver-sky) !important;
          border: 2px solid var(--color-denver-sky) !important;
        }
      }

      .mat-step-header[aria-selected="false"][tabindex="-1"] {

        .mat-step-icon-state-edit {
          background-color: var(--color-nightshade) !important;
          border: 2px solid var(--color-nightshade) !important;
        }
      }
    }

    .mat-horizontal-content-container {
      padding: 51px 0 0 0 !important;
      height: calc(100vh - 131px) !important;
      overflow-y: auto;
      ms-overflow-style: -ms-autohiding-scrollbar;
      scrollbar-width: thin;
      scrollbar-color: var(--color-silver) transparent;
      margin-top: 0 !important;

      .mat-horizontal-stepper-content {
        .stepper-content::-webkit-scrollbar {
          width: 7px;
          background-color: transparent;
        }

        .stepper-content::-webkit-scrollbar-thumb {
          background-color: var(--color-silver);
          border-radius: 13px;
        }

        .stepper-content {
          height: calc(100vh - 131px) !important;
          overflow-y: auto;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          scrollbar-width: thin;
          scrollbar-color: var(--color-silver) transparent;

          .form {
            min-height: calc(100vh - 131px) !important;
          }
        }
      }
    }
  }
}

.stepper {
  .close-circle {
    right: 50px;
    top: 50px;
    z-index: 1002;
    background: var(--color-pure-white) !important;
    position: absolute;

    .close-icon {
      color: var(--color-nightshade) !important;
      font-size: 26px;
    }
  }

  .stepper-form {

    .stepper-buttons {
      text-align: right;
      margin-right: 25px;

      .stepper-btn {
        margin-left: 15px;
        width: 110px;
        text-align: center;
      }
    }
  }
}

.stepper.bulk-upload {
  overflow: hidden;

  .mat-horizontal-content-container::-webkit-scrollbar {
    width: 21px;
    background-color: transparent;
  }

  .mat-horizontal-content-container::-webkit-scrollbar-thumb {
    background-color: var(--color-silver);
  }

  .mat-horizontal-content-container {
    height: calc(100vh - 150px);
    padding: 0 !important;
    overflow-y: auto;
    margin-top: 126px !important;


    .stepper-content {
      height: 100% !important;
      padding-bottom: 20px;
      padding-top: 36px;
      padding-left: 50px;
    }
  }
}

.product-booking-stepper.stepper {
  .mat-horizontal-content-container {
    padding: 126px 0 0 0 !important;
    height: calc(100vh - 126px) !important;
    margin-top: 0px !important;
    background: var(--color-snow) !important;
    overflow-y: hidden;
  }
}


.two-factor-authentication-active-stepper.stepper {

  @media screen and (min-width : 768px) {

    .close-circle {
      right: 50px;
      top: 50px;
      z-index: 1002;
      background: var(--color-pure-white) !important;
    }

    .mat-horizontal-stepper-header-container {
      height: 126px !important;
      padding-top: 0 !important;

      .mat-horizontal-stepper-header {
        display: none;
      }
    }

    .mat-horizontal-content-container {
      padding: 0 !important;
      height: calc(100vh - 126px) !important;
      margin-top: 126px !important;
    }
  }

  @media screen and (max-width : 767px) {
    .close-circle {
      right: 16px;
      top: 8px;
      z-index: 1002;
      background: var(--color-pure-white) !important;
    }

    .mat-horizontal-stepper-header-container {
      height: 46px !important;
      padding-top: 0 !important;

      .mat-horizontal-stepper-header {
        display: none;
      }
    }

    .mat-horizontal-content-container {
      padding: 0 !important;
      height: calc(100vh - 46px) !important;
      margin-top: 46px !important;
    }
  }
}


.progress-bar-stepper.stepper {
  @media only screen and (min-width: 481px) {
    .mat-horizontal-stepper-header-container {
      height: 0px !important;
      padding-top: 0 !important;

      .mat-horizontal-stepper-header {
        display: none;
      }
    }

    .mat-horizontal-content-container {
      padding: 0 !important;
      height: calc(100vh - 16px) !important;
      margin-top: 0px !important;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      scrollbar-width: thin;
      scrollbar-color: var(--color-silver) transparent;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-silver);
        border-radius: 2.5px;
      }
    }
  }

  @media only screen and (max-width: 481px) {
    .mat-horizontal-stepper-header-container {
      height: 0px !important;
      padding-top: 0 !important;

      .mat-horizontal-stepper-header {
        display: none;
      }
    }

    .mat-horizontal-content-container {
      padding: 0 !important;
      height: calc(100vh - 16px) !important;
      margin-top: 0px !important;
    }
  }
}

.user-budget-activate-stepper.stepper {
  .mat-horizontal-stepper-header-container {
    height: 112px !important;
    padding-top: 0 !important;

    .mat-horizontal-stepper-header {
      display: none;
    }
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
    height: calc(100vh - 126px) !important;
    margin-top: 112px !important;
  }
}

.stepper-2 {
  @media screen and (min-width : 768px) {
    position: relative !important;
    width: 460px !important;
    height: unset !important;
    min-height: 442px !important;
    max-height: calc(100vh - 120px) !important;
    border-radius: 3px !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);

    .close-circle {
      right: 15px !important;
      top: 14px !important;
      z-index: 1002 !important;
      background: var(--color-pure-white) !important;
      position: absolute !important;

      .close-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor !important;
        width: 24px;
        height: 24px;
        margin: 0 !important;
        transform: unset !important;
        font-size: 24px;
      }
    }

    .back-step {
      left: 5px !important;
      top: 14px !important;
      z-index: 1002 !important;
      background: var(--color-pure-white) !important;
      position: absolute !important;

      .mat-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor !important;
        width: 24px;
        height: 24px;
        margin: 0 !important;
        transform: unset !important;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .mat-stepper-horizontal {
      width: 460px !important;
      height: unset !important;
      min-height: 442px !important;
      max-height: calc(100vh - 120px) !important;
      border-radius: 3px !important;

      .mat-step-header.cdk-keyboard-focused,
      .mat-step-header.cdk-program-focused,
      .mat-step-header:hover {
        background-color: transparent;
      }

      .mat-horizontal-stepper-header-container {
        box-shadow: unset !important;
        border-radius: 3px 3px 0 0 !important;
        border: 1px solid var(--color-nightshade);
        text-align: center;
        margin: 0 auto;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding-top: 0px;
        padding-bottom: 0px;
        z-index: 1001;
        position: absolute;
        width: calc(100% - 2px);
        background: var(--color-pure-white);
        top: 0 !important;
        left: 0 !important;

        .mat-stepper-horizontal-line {
          display: none !important;
        }

        .mat-step-header::before,
        .mat-step-header::after {
          display: none !important;
        }

        .mat-step-header {
          padding: 3px 0 0 0 !important;
          width: 30px;
          height: 50px;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;



          .mat-step-icon {
            width: 12px !important;
            height: 12px !important;

            .mat-step-icon-content {
              display: none !important;

              span {
                display: none !important;
              }

              .mat-icon {
                display: none !important;
              }
            }
          }

          .mat-step-icon::before,
          .mat-step-icon::after {
            display: none !important;
          }

          .mat-step-label {
            display: none !important;
            padding: 0 !important;
            margin: 0 !important;
          }
        }

        .mat-step-header[aria-selected="false"][tabindex="-1"] {

          .mat-step-icon-state-number {
            background-color: var(--color-silver) !important;
            border: 2px solid var(--color-silver) !important;
          }
        }

        .mat-step-header[aria-selected="true"][tabindex="0"] {

          .mat-step-icon-state-number {
            background-color: var(--color-denver-sky) !important;
            border: 2px solid var(--color-denver-sky) !important;
          }
        }

        .mat-step-header[aria-selected="false"][tabindex="-1"] {

          .mat-step-icon-state-edit {
            background-color: var(--color-nightshade) !important;
            border: 2px solid var(--color-nightshade) !important;
          }
        }
      }

      .mat-horizontal-content-container {
        height: unset !important;
        min-height: 390px !important;
        max-height: 425px !important;
        margin-top: 0px !important;
        padding: 50px 0 0 0 !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        border: 1px solid var(--color-nightshade);
        border-radius: 0 0 3px 3px !important;

        .mat-horizontal-stepper-content {

          .form {
            .form-content::-webkit-scrollbar {
              width: 7px;
              background-color: transparent;
            }

            .form-content::-webkit-scrollbar-thumb {
              background-color: var(--color-silver);
              border-radius: 13px;
            }

            .form-content {
              padding: 0 !important;
              height: 320px !important;
              overflow-y: auto !important;
              overflow-x: hidden !important;
            }

            .form-buttons {
              height: 70px;
              border-radius: 0 0 3px 3px;
              background-color: var(--color-pure-white);
              box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);

              .flex {
                .width-100 {
                  height: 70px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  button {
                    height: 36px !important;
                    padding: 0px !important;
                    margin: 0 !important;
                    width: 256px !important;
                  }

                  button[disabled="true"] {
                    height: 36px !important;
                    padding: 0 80px !important;
                    margin: 0 !important;
                    background-color: var(--color-silver) !important;
                    cursor: default !important;
                  }
                }

                .width-50 {
                  height: 70px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  button {
                    height: 36px !important;
                    padding: 0 32px !important;
                    margin: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width : 767px) {
    position: relative !important;
    width: 100vw !important;
    height: calc(100vh - 80px) !important;
    min-height: calc(100vh - 80px) !important;
    max-height: calc(100vh - 80px) !important;
    border-radius: 3px !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);

    .close-circle {
      right: 15px !important;
      top: 14px !important;
      z-index: 1002 !important;
      background: var(--color-pure-white) !important;
      position: absolute !important;

      .close-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor !important;
        width: 24px;
        height: 24px;
        margin: 0 !important;
        transform: unset !important;
        font-size: 24px;
      }
    }

    .back-step {
      left: 5px !important;
      top: 14px !important;
      z-index: 1002 !important;
      background: var(--color-pure-white) !important;
      position: absolute !important;

      .mat-icon {
        background-repeat: no-repeat;
        display: inline-block;
        fill: currentColor !important;
        width: 24px;
        height: 24px;
        margin: 0 !important;
        transform: unset !important;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .mat-stepper-horizontal {
      width: calc(100vw - 2px) !important;
      height: calc(100vh - 80px) !important;
      min-height: calc(100vh - 80px) !important;
      max-height: calc(100vh - 80px) !important;
      border-radius: 0px !important;

      .mat-step-header.cdk-keyboard-focused,
      .mat-step-header.cdk-program-focused,
      .mat-step-header:hover {
        background-color: transparent;
      }

      .mat-horizontal-stepper-header-container {
        box-shadow: unset !important;
        border-radius: 3px 3px 0 0 !important;
        border: 1px solid var(--color-nightshade);
        text-align: center;
        margin: 0 auto;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding-top: 0px;
        padding-bottom: 0px;
        z-index: 1001;
        position: absolute;
        width: calc(100% - 4px);
        background: var(--color-pure-white);
        top: 0 !important;
        left: 0 !important;

        .mat-stepper-horizontal-line {
          display: none !important;
        }

        .mat-step-header::before,
        .mat-step-header::after {
          display: none !important;
        }

        .mat-step-header {
          padding: 3px 0 0 0 !important;
          width: 30px;
          height: 50px;
          display: -webkit-box;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;

          .mat-step-icon {
            width: 12px !important;
            height: 12px !important;

            .mat-step-icon-content {
              display: none !important;

              span {
                display: none !important;
              }

              .mat-icon {
                display: none !important;
              }
            }
          }

          .mat-step-icon::before,
          .mat-step-icon::after {
            display: none !important;
          }

          .mat-step-label {
            display: none !important;
            padding: 0 !important;
            margin: 0 !important;
          }
        }

        .mat-step-header[aria-selected="false"][tabindex="-1"] {

          .mat-step-icon-state-number {
            background-color: var(--color-silver) !important;
            border: 2px solid var(--color-silver) !important;
          }
        }

        .mat-step-header[aria-selected="true"][tabindex="0"] {

          .mat-step-icon-state-number {
            background-color: var(--color-denver-sky) !important;
            border: 2px solid var(--color-denver-sky) !important;
          }
        }

        .mat-step-header[aria-selected="false"][tabindex="-1"] {

          .mat-step-icon-state-edit {
            background-color: var(--color-nightshade) !important;
            border: 2px solid var(--color-nightshade) !important;
          }
        }
      }

      .mat-horizontal-content-container {
        height: calc(100vh - 132px) !important;
        min-height: calc(100vh - 132px) !important;
        max-height: calc(100vh - 132px) !important;
        margin-top: 0px !important;
        padding: 50px 0 0 0 !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        border: 1px solid var(--color-nightshade);
        border-radius: 0 0 3px 3px !important;

        .mat-horizontal-stepper-content {

          .form {
            .form-content::-webkit-scrollbar {
              width: 2.5px;
              background-color: transparent;
            }

            .form-content::-webkit-scrollbar-thumb {
              background-color: var(--color-silver);
              border-radius: 2.5px;
            }

            .form-content {
              padding: 0 !important;
              height: calc(100vh - 243px) !important;
              overflow-y: auto !important;
              overflow-x: hidden !important;
            }

            .form-buttons {
              height: 110px;
              border-radius: 0 0 3px 3px;
              background-color: var(--color-pure-white);
              box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);

              .flex {
                .width-100 {
                  height: 110px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  button {
                    height: 36px !important;
                    padding: 0px !important;
                    margin: 0 !important;
                    width: 200px !important;
                  }

                  button[disabled="true"] {
                    height: 36px !important;
                    padding: 0 80px !important;
                    margin: 0 !important;
                    background-color: var(--color-silver) !important;
                    cursor: default !important;
                  }
                }

                .width-50 {
                  height: 70px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  button {
                    height: 36px !important;
                    padding: 0 32px !important;
                    margin: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.stepper-3 {
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;

  .mat-stepper-horizontal {
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    background-color: transparent !important;

    .mat-horizontal-stepper-header-container {
      display: none !important;
      visibility: hidden;
      opacity: 0;
      height: 0;
    }

    .mat-horizontal-content-container {
      height: 100% !important;
      min-height: 100% !important;
      max-height: 100% !important;
      margin-top: 0px !important;
      padding: 0px 0 0 0 !important;
      overflow: hidden !important;
    }
  }
}