.mat-grid-list {
  .mat-grid-tile {
    .mat-figure {
      justify-content: flex-start;
      align-items: baseline;
    }
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.mat-menu-item-submenu-trigger::after {
  display: none !important;
}

.mat-menu-item-submenu-trigger {
  padding-right: 16px !important;
}

.mat-menu-item {
  .mat-icon {
    margin-right: 0 !important;
  }

  .mat-icon.arrow-right {
    position: absolute;
    right: 5px;
    top: 13px;
    margin-right: 0 !important;
  }

  .mat-icon.arrow-left {
    position: absolute;
    left: 5px;
    top: 13px;
    margin-left: 0 !important;
  }
}

.select-option-width {
  max-width: unset !important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background-color: var(--color-silver);
  border-radius: 2.5px;
}   

.mat-dialog-container::before {
  filter: blur(10px);
}

.mat-progress-spinner.mat-warn circle,
.mat-spinner.mat-warn circle {
  stroke: var(--color-mountain-purple) !important;
}

.mat-menu-item-highlighted::after {
  color: var(--color-pure-white);
}

.mat-menu-item-submenu-trigger[aria-expanded="true"] {
  background: var(--color-smoke-platinum);
  color: var(--color-nightshade);
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-menu-panel.people-roster-dialog-usage-data {
  border-radius: 3px;
  background-color: var(--color-pure-white);
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5);
  height: 350px;
  max-height: 350px;
  width: 340px !important;
  max-width: 340px;
}

.usage-data-admin-menu.autocomplete-input-menu {
  .mat-form-field-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.hide-complete-opacity {
  opacity: 0 !important;
}

.hide-input-field {
  opacity: 0 !important;
  pointer-events: none;

  .mat-form-field {
    .mat-form-field-wrapper {
      padding: 0 !important;
      margin: 0 !important;

      .mat-form-field-infix {
        padding: 0 !important;
        margin: 0 !important;

        .mat-form-field-label-wrapper {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
}

.file-upload-box {
  background: var(--color-snow) !important;
  height: 345px !important;
  border: 1px dashed var(--color-nightshade) !important;

  .material-icons-round {
    color: var(--color-nightshade);
    font-size: 34px;
    height: 34px;
    width: 34px;
  }

  .drag-drop {
    margin-top: 17px;
    color: var(--color-nightshade);
    font-family: "Brandon Text";
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }

  .select-from-computer {
    margin-top: 25px;
    color: var(--color-mountain-purple);
    font-family: "Brandon Text";
    font-size: 16px;
    line-height: 22px;
  }

  .upload-file-preview {
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    background: var(--color-snow) !important;

    .file-name {
      color: var(--color-nightshade) !important;
      font-family: "Brandon Text" !important;
    }
  }

  .upload-file-preview:hover {
    background: var(--color-snow) !important;
  }
}

.mat-menu-panel.account-notification-mat-menu {
  border-radius: 3px;
  background-color: var(--color-pure-white);
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5);
  min-height: 500px;
  max-height: 500px;
  width: 340px !important;
  max-width: 340px;
}

.warning-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin: 0 5px;

  .img {
    height: 100%;
    width: 100%;
    margin-bottom: -2px;
  }
}

.reward-gif-paginator {
  .mat-paginator-page-size {
    display: none !important;
  }
}

.people-roster-search {
  width: 280px;
  min-width: 280px !important;

  .mat-select-search-inside-mat-option {
    .mat-select-search-inner {
      background-color: var(--color-pure-white);
    }
  }
}

.people-roster-autocomplete {
  width: 280px !important;

  .mat-select-search-inner {
    width: 280px !important;
  }
}

@media only screen and (max-width: 480px) {
  .login-select-panel {
    width: calc(100% - -30px) !important;
    min-width: calc(100% - -30px) !important;
    min-width: calc(100% - -30px) !important;
  }
}

@media only screen and (min-width: 481px) {
  .login-select-panel {
    width: 422px !important;
    min-width: 422px !important;
    min-width: 422px !important;
  }
}

.notification-icon {
  font-size: 36px;
  cursor: pointer;
  margin: 0 !important;
  width: 36px;
  height: 36px;

  .mat-badge-content {
    right: 6px !important;
    top: 2px !important;
    width: 12px;
    height: 12px;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-notification) !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@media screen and (max-width: 400px) {
  .img-adjust {
    width: 100%;

    .text {
      width: 100%;

      p {
        a {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.product-image-card {
  height: 342px;
  width: 400px;
  border-radius: 13px;
  background-color: var(--color-pure-white);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);

  .product-description {
    background: var(--color-pure-white);
    text-align: left;
    padding: 14px 20px 0 20px;

    .product-preview-title {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 34px;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
    }

    .product-preview-meta-copy {
      height: 105px;
      color: var(--color-nightshade);
      font-family: "Brandon Text";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
      overflow: hidden;
      margin-top: 5px;
      text-align: justify;
    }
  }

  .image-crop {
    .size-adjust {
      height: 147px !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 11px 11px 0 0;

      .overlay {
        height: 1471px !important;
        outline: transparent;
      }

      .source-image {
        height: 147px !important;
        left: 0;
      }
    }
  }
}

/****** booking and reward page customization ******/

.card-grid {
  ul {
    margin: 0 !important;
  }

  ul>li,
  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
  }
}

.coworker-list-panel.mat-autocomplete-panel {
  box-sizing: border-box;
  min-height: 174px;
  border: 1px solid var(--color-nightshade);
  border-radius: 3px;
  background-color: var(--color-pure-white);
  width: calc(100% - 1px);
  margin-top: 3px;

  .mat-option {
    min-height: 41px;
    min-width: 228px;
    margin-bottom: 0;
    margin-left: 0 !important;
    line-height: unset !important;
    height: unset !important;
    padding: 6px 0 12px 14px;

    .mat-option-text {
      .opt-wrapper-parent {
        height: 41px;
        width: 228px;

        .opt-wrapper {
          display: flex;
          gap: 7px;
          align-items: center;
          justify-content: flex-start;

          .avatar {
            height: 28px;
            width: 28px;
            min-width: 28px;
            min-height: 28px;
            background-color: var(--color-nightshade);
            align-self: center;
            border-radius: 100px;
            position: relative;

            span {
              color: var(--color-pure-white);
              font-size: 14.25px !important;
              letter-spacing: 0 !important;
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .name {
              min-height: 22px;
              min-width: 98px;
              color: var(--color-nightshade);
              font-family: "Brandon Text";
              font-size: 16px;
              letter-spacing: 0;
              line-height: 22px;
              text-transform: none;
            }
          }
        }

        .email-container {
          padding-left: 35px;
          margin-top: -6px;

          .email {
            height: 17px;
            width: 193px;
            color: var(--color-london-gray);
            font-family: "Brandon Text";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
      }
    }
  }

  .mat-option:first-child {
    margin-top: 6px !important;
  }

  .mat-option:last-child {
    margin-bottom: 6px !important;
  }
}

.close-content {
  position: absolute;
  right: 30px;
  top: 28px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 100;

  mat-icon {
    font-size: 32px;
    width: 32px;
    height: 32px;
    color: var(--color-nightshade) i !important;
  }
}
