@import "../style/mixins";

.wl-snackbar {
  padding: 0 !important;
  height: unset !important;
  min-height: 0px !important;
  width: unset !important;
  min-width: unset !important;
  max-width: calc(100vw - 10px) !important;
  border-radius: 0px !important;
  background-color: transparent;
  box-shadow: unset !important;
  margin: 0px 4px 24px 4px !important;
  
  @include breakpoint(sm) {
    padding: 0 !important;
    height: unset !important;
    min-height: 0px !important;
    width: unset !important;
    min-width: unset !important;
    max-width: calc(100vw - 72px) !important;
    border-radius: 0px !important;
    background-color: transparent;
    box-shadow: unset !important;
    margin: 12px 24px 48px 48px !important;
  }
}

.toaster-component {
  @media screen and (min-width : 1280px) {
    min-height: 93px !important;
    height: unset !important;
    width: calc(100vw - 235px) !important;
    border-radius: 13px 13px 0 0 !important;
    background-color: var(--color-gold-star) !important;
    max-width: calc(100vw - 235px) !important;
    min-width: calc(100vw - 235px) !important;
    margin: 0 !important;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  @media screen and (min-width : 1024px) and (max-width : 1279px) {
    min-height: 93px !important;
    height: unset !important;
    width: calc(100vw - 235px) !important;
    border-radius: 13px 13px 0 0 !important;
    background-color: var(--color-gold-star) !important;
    max-width: calc(100vw - 235px) !important;
    min-width: calc(100vw - 235px) !important;
    margin: 0 !important;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  @media screen and (min-width : 768px) and (max-width : 1023px) {
    min-height: 93px !important;
    height: unset !important;
    width: calc(100vw) !important;
    border-radius: 13px 13px 0 0 !important;
    background-color: var(--color-gold-star) !important;
    max-width: calc(100vw) !important;
    min-width: calc(100vw) !important;
    margin: 0 !important;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  @media screen and (min-width : 500px) and (max-width : 767px) {
    min-height: 65px !important;
    height: unset !important;
    width: 100vw !important;
    border-radius: 13px 13px 0 0 !important;
    background-color: var(--color-gold-star) !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    margin: 0 !important;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  @media screen and (max-width : 499px) {
    min-height: 65px !important;
    height: unset !important;
    width: 100vw !important;
    border-radius: 13px 13px 0 0 !important;
    background-color: var(--color-gold-star) !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    margin: 0 !important;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }
}

.snackbar-component {

  @media screen and (min-width : 1024px) {
    background: var(--color-nightshade);
    height: unset !important;
    min-height: 63px !important;
    min-width: unset !important;
    max-width: calc(100vw - 72px) !important;
    width: unset !important;
    border-radius: 8px !important;
    background-color: var(--color-nightshade);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    margin: 12px 24px 48px 48px !important;

    .mat-simple-snackbar {
      color: var(--color-pure-white) !important;
    }
  }

  @media screen and (min-width : 768px) and (max-width : 1023px) {
    background: var(--color-nightshade);
    height: unset !important;
    min-height: 63px !important;
    min-width: unset !important;
    max-width: calc(100vw - 48px) !important;
    width: unset !important;
    border-radius: 8px !important;
    background-color: var(--color-nightshade);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    margin: 12px 24px 48px 24px !important;
  }

  @media screen and (max-width : 767px) {
    background: var(--color-nightshade);
    height: unset !important;
    min-height: 63px !important;
    background: var(--color-nightshade);
    max-width: calc(100vw - 24px) !important;
    width: unset !important;
    border-radius: 8px !important;
    background-color: var(--color-nightshade);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    margin: 12px !important;
  }
}

.snackbar-comment-component {

  @media screen and (min-width : 1024px) {
    background: var(--color-nightshade);
    height: unset !important;
    min-height: 48px !important;
    min-width: unset !important;
    max-width: calc(100vw - 72px) !important;
    width: unset !important;
    border-radius: 3px !important;
    background-color: var(--color-nightshade);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    margin: 12px 24px 48px 48px !important;

    .mat-simple-snackbar {
      color: var(--color-pure-white) !important;
    }

    .mat-simple-snackbar-action {
      color: var(--color-pure-white) !important;
    }
  }

  @media screen and (min-width : 768px) and (max-width : 1023px) {
    background: var(--color-nightshade);
    height: unset !important;
    min-height: 48px !important;
    min-width: unset !important;
    max-width: calc(100vw - 48px) !important;
    width: unset !important;
    border-radius: 3px !important;
    background-color: var(--color-nightshade);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    margin: 12px 24px 48px 24px !important;

    .mat-simple-snackbar {
      color: var(--color-pure-white) !important;
    }
  }

  @media screen and (max-width : 767px) {
    background: var(--color-nightshade);
    height: unset !important;
    min-height: 48px !important;
    background: var(--color-nightshade);
    max-width: calc(100vw - 24px) !important;
    width: unset !important;
    border-radius: 3px !important;
    background-color: var(--color-nightshade);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    margin: 12px !important;

    .mat-simple-snackbar {
      color: var(--color-pure-white) !important;
    }
  }
}
