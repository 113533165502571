.backdrop-target {
  border: 1px solid var(--color-nightshade);
}

.joyride-step__holder {
  .joyride-step__container {
    border-radius: 13px !important;

    .joyride-step__body {
      padding: 10px 8px !important;
      text-align: left !important;
      color: var(--color-nightshade) !important;
      font-size: 16px !important;
      line-height: 22px !important;

      p {
        margin: 0;
      }
    }

    .joyride-step__footer {
      justify-content: flex-end !important;

      .joyride-step__buttons-container {
        .joy-ride-button {
          font-family: "Brandon Text";
          font-size: 14px;
          line-height: 20px;
          text-transform: capitalize;
          letter-spacing: 0.106em;
          border-radius: 3px;
          cursor: pointer;
          transform: none !important;
          border: 0;
          outline: none;
          padding: 3px 15px;
          box-shadow: none !important;
          border: 2px solid var(--color-mountain-purple);
          color: var(--color-pure-white);
          background: var(--color-mountain-purple);
          cursor: pointer;
        }
      }
    }
  }
}
