.grecaptcha-badge {
  z-index: 2000 !important;
}

.hide-captcha-badge {
  .grecaptcha-badge {
    visibility: hidden;
  }
}

.add-captcha-badge {
  .grecaptcha-badge {
    visibility: visible;
  }
}
